import React, { useState, useReducer, useEffect } from "react";
import { strings as translate } from "locale";
import { withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Email from "@material-ui/icons/Email";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

// services
import AuthServices from "services/AuthServices";
import { Auth } from "aws-amplify";
import { UserContext } from "providers/UserProvider";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import routes from "../../routes";

function emailValidator(email) {
  const re = /\S+@\S+\.\S+/;
  if (!email) return translate.email_emtpy;
  if (!re.test(email)) return translate.email_error;
  return true;
}

const useStyles = makeStyles(styles);

const NewPasswordRequiredForm = (props) => {
  const { user } = props;
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [inputValues, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { new_password: "", email: "" }
  );
  const [apiError, setApiError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({});
  const [emailError, setEmailError] = useState(false);
  const [emailMessageError, setEmailMessageError] = useState("");
  const useUserContext = React.useContext(UserContext);

  const classes = useStyles();

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  useEffect(() => {
    setUserData(user);
  }, [user]);

  const setInput = (e) => {
    const { name, value } = e.target;
    setInputValues({ [name]: value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = async (e) => {
    e.preventDefault();
    setEmailError(false);
    setEmailMessageError("");

    let fullpath = "admin/dashboard";
    // obtenemos la primer ruta configurada en routes.js
    const { layout, path } = routes[0];
    if (path !== "/login") {
      fullpath = `${layout}${path}`;
    }

    if (!inputValues.new_password.trim() || !inputValues.email.trim()) {
      setApiError(translate.all_fields_required);
      return;
    }
    const emailValid = emailValidator(inputValues.email.trim());
    if (emailValid !== true) {
      setEmailError(true);
      setEmailMessageError(emailValid);
    }

    setApiError("");
    try {
      const user = await Auth.completeNewPassword(
        userData,
        inputValues.new_password,
        {
          email: "dteruya@yopmail.com",
        }
      );
      if (user) {
        AuthServices.saveUser(user);
        useUserContext.updateUser();
        setTimeout(() => {
          props.history.push(fullpath);
        }, 500);
      } else {
        setApiError("Error de inicio de sesión");
      }
    } catch (error) {
      console.log(error);
      // {code: "LimitExceededException", name: "LimitExceededException", message: "Attempt limit exceeded, please try after some time."}
      setApiError(error.message);
    }
  };

  return (
    <form onSubmit={changePassword}>
      <Card login className={classes[cardAnimaton]}>
        <CardHeader
          className={`${classes.cardHeader} ${classes.textCenter}`}
          color="primary"
        >
          <h4 className={classes.cardTitle}>{translate.change_password}</h4>
        </CardHeader>
        <CardBody>
          <CustomInput
            labelText={translate.new_password}
            id="new_password"
            formControlProps={{
              fullWidth: true,
            }}
            value={inputValues.new_password}
            inputProps={{
              onChange: setInput,
              name: "new_password",
              type: showPassword ? "text" : "password",
              autoComplete: "off",
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <CustomInput
            labelText={translate.email}
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            value={inputValues.email}
            inputProps={{
              onChange: setInput,
              name: "email",
              autoComplete: "off",
            }}
            endAdornment={
              <InputAdornment position="end">
                <Email className={classes.inputAdornmentIcon} />
              </InputAdornment>
            }
            error={emailError}
            helperText={emailMessageError}
          />
          {apiError && <FormHelperText error>{apiError}</FormHelperText>}
        </CardBody>
        <CardFooter className={classes.justifyContentCenter}>
          <Button color="primary" size="lg" block type="submit">
            {translate.change_password}
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
};

export default withRouter(NewPasswordRequiredForm);
