import React, { useContext, useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { strings as translate } from "../../locale";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Button from "../../components/CustomButtons/Button";
import useMainNotification from "../../hooks/useMainNotification";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Formik, setIn } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputForm from "components/CustomForms/InputForm";
import { getYupSchemaFromMetaData } from "../../components/CustomForms/yupSchemaCreator";

import _ from "lodash";

// services
import MainServices from "../../services/MainServices";

// contexts
import { GenericPageContext } from "../../providers/GenericPageProvider";

// styles
import styles from "./styles";
import TournamentServices from "services/TournamentServices";

const useStyles = makeStyles(styles);

function ZonesForm(props) {
  const { onSave, onSaveError, onCancel, editForm } = props;
  const classes = useStyles();
  const [data, setData] = useState(() => []);
  const [loaded, setLoaded] = useState(false);
  const [requiredFields, setRequiredFields] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [numberOfZones, setNumberOfZones] = useState(1);
  const [hasLocalData, setHasLocalData] = useState(false);
  const { addSuccess, addError } = useMainNotification();
  const [tournamentId, setTournamentId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState({
    content: "",
  });

  const defaultZonesNames = "ABCDEFGHIJKLMN";

  const [columns, setColumns] = useState({
    allTeams: {
      alias: "allTeams",
      teams: [],
    },
    teams1: {
      alias: "Teams1",
      teams: [],
    },
  });

  useEffect(() => {
    if (
      props.data !== null &&
      props.data !== undefined &&
      props.data.teams !== undefined &&
      props.data.id_tournament !== undefined
    ) {
      const zones = {};
      Object.keys(props.data.values.zones).forEach((i) => {
        if (i !== "columns") {
          Object.assign(zones, { [i]: props.data.values.zones[i] });
        }
      });
      const newData = {
        data: {
          number_of_zones: props.data.values.zones.number_of_zones,
          zone_name_1: props.data.values.zones.zone_name_1
            ? props.data.values.zones.zone_name_1
            : "Grupo A",
          ...zones,
        },
        metadata: [
          {
            accessor: "id",
            header: "Id",
            editable: false,
            type: "number",
          },
          {
            accessor: "number_of_zones",
            header: "Cantidad de zonas",
            editable: true,
            required: true,
            type: "number",
            validationType: "string",
            validations: [
              {
                type: "required",
                params: ["Campo requerido"],
              },
            ],
          },
        ],
      };

      let columns = JSON.parse(JSON.stringify(props.data.values.zones.columns));
      if (!columns.teams1) {
        columns = {
          ...columns,
          teams1: {
            alias: "Teams1",
            teams: [],
          },
        };
      }

      setTournamentId(props.data.id_tournament);
      setNumberOfZones(props.data.values.zones.number_of_zones);
      setColumns(columns);
      setData(newData);
      // establecemos las validaciones
      setValidationSchema(getYupSchemaFromMetaData(newData.metadata, [], []));
      setInitialValues(newData.data);
    }

    setLoaded(true);
  }, [addError, props.data]);

  const getTeamsByPhase = (phases) => {
    let newPhases = [];
    phases.map((phase, k) => {
      const newCols = JSON.parse(JSON.stringify(columns));
      delete newCols.allTeams;
      let col = null;

      Object.keys(newCols).forEach((i, kk) => {
        if (k === kk) {
          col = newCols[i];
        }
      });

      // buscamos en las columnas los equipos
      const ids = col.teams.map((l) => ({
        id: l.id,
        id_ptt: l.id_ptt,
        name: l.name,
        avatar: l.avatar,
      }));
      const newPhase = { id_phase: phase.id, teams: ids };
      newPhases.push(newPhase);
    });

    return newPhases;
  };

  const deleteZone = async () => {
    const _remainingPhases = { ...props.data.values.zones.columns };
    Object.keys(columns).map((i) => {
      delete _remainingPhases[i];
    });

    const data = {
      tournament_id: tournamentId,
      phases: [],
      type: "phase",
      third: null,
      remainingPhases: Object.keys(_remainingPhases).map((i) => ({
        id: _remainingPhases[i].id_phase,
      })),
    };
    try {
      const res = await TournamentServices.updatePhasesByTournament(data);
    } catch (e) {
      console.log(e);
      const data = e.response ? e.response.data : {};
      let errors = {};

      Object.keys(data).forEach((item) => {
        errors = setIn(errors, item, data[item][0]);
      });

      handleSentError(e.response);
      if (e.response && e.response.data && e.response.data.error) {
        addError(e.response.data.error_msg, null, { messageLength: null });
      } else if (e.message) {
        addError(e.message, null, { messageLength: null });
      }
    }
  };

  const handleFormSubmit = async (values, actions) => {
    try {
      let res = null;
      const newCols = { ...columns };

      const _phases = [];
      const _remainingPhases = { ...props.data.values.zones.columns };

      Object.keys(columns).map((i) => {
        if (i !== "allTeams") {
          const phaseId = columns[i] ? columns[i].id_phase : null;
          _phases.push({
            ...(phaseId && { id: phaseId }),
            name: values[`zone_name_${i.substring(5)}`],
            key: "phase",
          });
        }
        delete _remainingPhases[i];
      });

      const data = {
        tournament_id: tournamentId,
        phases: _phases,
        type: "phase", // ["phase"|"subleague"|"playoff"]
        third: null,
        remainingPhases: Object.keys(_remainingPhases).map((i) => ({
          id: _remainingPhases[i].id_phase,
        })),
      };

      res = await TournamentServices.updatePhasesByTournament(data);

      const dataTeams = {
        phases: getTeamsByPhase(res.data.phases),
        remainingTeams: {
          teams: columns.allTeams.teams,
        },
      };

      res = await TournamentServices.updateTeamsByPhases(dataTeams);

      res.data.phases.map((i, k) => {
        newCols["teams" + (k + 1)] = {
          ...newCols["teams" + (k + 1)],
          id_phase: i.id_phase,
        };
        newCols["teams" + (k + 1)].teams = i.teams;
      });

      setColumns(newCols);

      if (!res.error || !res.data.error) {
        addSuccess(
          `${translate.groups} ${translate.has_been_edited_successfully}`
        );
      }

      if (onSave) {
        onSave({
          ...values,
          columns: newCols,
          status: "edit",
        });
      }
    } catch (e) {
      const data = e.response ? e.response.data : {};
      let errors = {};

      Object.keys(data).forEach((item) => {
        errors = setIn(errors, item, data[item][0]);
      });

      actions.setStatus(errors);

      handleSentError(e.response);
      if (e.response && e.response.data && e.response.data.error) {
        addError(e.response.data.error_msg, null, { messageLength: null });
      } else if (e.message) {
        addError(e.message, null, { messageLength: null });
      }
      MainServices.cancel();
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleSentError = (values) => {
    if (onSaveError) {
      onSaveError(values);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleChangeNumberOfZones = (formik, e) => {
    const { value } = e.target;

    if (value === "") {
      return;
    }

    if (value < numberOfZones) {
      setOpenDialog(true);
      setDialogData({
        title: "¿Realmente quiere borrar el grupo?",
        content: "Se borrarán todos los datos relacionados al grupo.",
        data: {
          formik,
          value,
          numberOfZones,
        },
      });

      return;
    }

    changeNumberOfZones(formik, value);
  };

  const changeNumberOfZones = (formik, value) => {
    setColumns((v) => {
      let cols = v;
      if (numberOfZones > value) {
        const teams = [
          ...cols.allTeams.teams,
          ...cols[`teams${numberOfZones}`].teams,
        ];
        cols.allTeams.teams = teams;
        delete cols[`teams${numberOfZones}`];

        return cols;
      } else {
        return {
          ...cols,
          [`teams${value}`]: { alias: `Teams${value}`, teams: [] },
        };
      }
    });

    if (numberOfZones > value) {
      delete formik[`zone_name_${value}`];
    } else {
      formik.setFieldValue(
        `zone_name_${value}`,
        `Grupo ${defaultZonesNames[value - 1]}`
      );
    }
    setNumberOfZones(value);
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.teams];
      const destItems = [...destColumn.teams];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          teams: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          teams: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.teams];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          teams: copiedItems,
        },
      });
    }
  };

  const handleNext = () => {
    let r = {};
    let newCols = {};
    Object.keys(props.data.values.zones).forEach((i) => {
      if (i !== "columns") {
        r = { ...r, [i]: props.data.values.zones[i] };
      }
    });
    Object.keys(props.data.values.zones.columns).forEach((i) => {
      if (i !== "allTeams") {
        newCols = { ...newCols, [i]: props.data.values.zones.columns[i] };
      }
    });
    if (onSave) {
      onSave({
        ...r,
        columns: newCols,
        status: "edit",
      });
    }
  };

  const onClickOk = () => {
    changeNumberOfZones(dialogData.data.formik, dialogData.data.value);
    deleteZone();
    setOpenDialog(false);
  };

  const onClickCancel = (dialogData) => {
    // changeNumberOfZones(dialogData.data.formik, dialogData.data.numberOfZones);

    dialogData.data.formik.setFieldValue(
      `number_of_zones`,
      dialogData.data.numberOfZones
    );
    setOpenDialog(false);
  };

  return (
    <>
      <LoadingIndicator
        type="TailSpin"
        color="#2e82ef"
        height={50}
        width={50}
      />

      <div className={classes.root}>
        {loaded && (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              trackPromise(handleFormSubmit(values, actions), "save-button");
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <DragDropContext
                  onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                >
                  <GridContainer alignItems="stretch">
                    <GridItem xs={12} sm={4}>
                      <InputForm
                        data={{
                          accessor: "number_of_zones",
                          header: "Cantidad de zonas",
                          editable: true,
                          type: "number",
                        }}
                        editForm={true}
                        fullData={{}}
                        idField={"id"}
                        onChange={(e) => handleChangeNumberOfZones(formik, e)}
                        inputProps={{
                          min: 1,
                          step: 1,
                        }}
                      />
                      <p>{translate.tournament_teams}</p>
                      <div>
                        <Droppable droppableId="allTeams" key="allTeams">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver
                                  ? "lightblue"
                                  : "#DDDDDD",
                              }}
                              className={classes.droppableContainer}
                            >
                              {columns.allTeams &&
                                columns.allTeams.teams &&
                                columns.allTeams.teams.map((item, index) => (
                                  <Draggable
                                    key={item.id.toString()}
                                    draggableId={item.id.toString()}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        key={"div" + item.id.toString()}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          backgroundColor: snapshot.isDragging
                                            ? "#5D83E0"
                                            : "#FFFFFF",
                                          ...provided.draggableProps.style,
                                        }}
                                        className={classes.draggableTeamItem}
                                      >
                                        <div
                                          className={
                                            classes.draggableTeamAvatar
                                          }
                                          style={{
                                            backgroundImage:
                                              "url(" + item.avatar + ")",
                                          }}
                                        ></div>{" "}
                                        <div
                                          className={classes.draggableTeamName}
                                        >
                                          {item.name}
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <GridContainer>
                        {Array.from(
                          { length: numberOfZones },
                          (_, i) => i + 1
                        ).map((i, k) => {
                          return (
                            <GridItem key={k} xs={6}>
                              <InputForm
                                data={{
                                  accessor: "zone_name_" + i,
                                  header: translate.group_name + " " + i,
                                  editable: true,
                                  type: "text",
                                }}
                                required={true}
                                editForm={true}
                                fullData={{}}
                                idField={"id"}
                              />
                              <p>
                                {translate.group_teams} {i}
                              </p>
                              <div>
                                <Droppable
                                  droppableId={`teams${k + 1}`}
                                  key={`teams${k + 1}`}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={{
                                        background: snapshot.isDraggingOver
                                          ? "lightblue"
                                          : "#DDDDDD",
                                        minHeight: 100,
                                      }}
                                      className={classes.droppableContainer}
                                    >
                                      {columns[`teams${k + 1}`] &&
                                        columns[`teams${k + 1}`].teams &&
                                        columns[`teams${k + 1}`].teams.map(
                                          (item, index) => (
                                            <Draggable
                                              key={item.id.toString()}
                                              draggableId={item.id.toString()}
                                              index={index}
                                            >
                                              {(provided, snapshot) => (
                                                <div
                                                  key={
                                                    "div" + item.id.toString()
                                                  }
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={{
                                                    backgroundColor: snapshot.isDragging
                                                      ? "#5D83E0"
                                                      : "#FFFFFF",
                                                    ...provided.draggableProps
                                                      .style,
                                                  }}
                                                  className={
                                                    classes.draggableTeamItem
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      classes.draggableTeamAvatar
                                                    }
                                                    style={{
                                                      backgroundImage:
                                                        "url(" +
                                                        item.avatar +
                                                        ")",
                                                    }}
                                                  ></div>{" "}
                                                  <div
                                                    className={
                                                      classes.draggableTeamName
                                                    }
                                                  >
                                                    {item.name}
                                                  </div>
                                                </div>
                                              )}
                                            </Draggable>
                                          )
                                        )}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </div>
                            </GridItem>
                          );
                        })}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </DragDropContext>
                <GridContainer style={{ marginTop: "30px" }}>
                  {requiredFields && (
                    <GridItem xs={12}>
                      <div className={classes.formCategory}>
                        <small>*</small> {translate.required_fields}
                      </div>
                    </GridItem>
                  )}
                  <GridItem xs={12} container justify="center">
                    {data.metadata ? (
                      <>
                        <div className={classes.formButtonWithLoader}>
                          <LoadingIndicator
                            type="TailSpin"
                            color="#2e82ef"
                            height={30}
                            width={30}
                            area="save-button"
                          />

                          <Button
                            id = "btnBack"
                            type="submit"
                            onClick={handleCancel}
                            style={{ marginRight: "24px" }}
                          >
                            {translate.back}
                          </Button>
                          <Button
                            id = "btnSave"
                            type="submit"
                            color="success"
                            disabled={
                              (!formik.isValid ||
                                (columns.teams1 !== undefined &&
                                  columns.teams1.teams.length === 0) ||
                                formik.isSubmitting) &&
                              !hasLocalData
                            }
                            style={{ marginRight: "24px" }}
                          >
                            {translate.save}
                          </Button>

                          <Button
                            id = "btnNext"
                            type="button"
                            color="primary"
                            onClick={handleNext}
                          >
                            {translate.next}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <FormHelperText
                        className={
                          classes.labelRootError + " " + classes.textCenter
                        }
                      >
                        {translate.formatString(
                          translate.autogenerate_form_error,
                          <br />
                        )}
                      </FormHelperText>
                    )}
                  </GridItem>
                </GridContainer>
              </form>
            )}
          </Formik>
        )}
      </div>
      <Dialog open={openDialog} aria-labelledby="form-dialog-title">
        <DialogTitle>{dialogData.title}</DialogTitle>
        <DialogContent>{dialogData.content}</DialogContent>
        <DialogActions>
          <Button id = "btnOk" onClick={onClickOk} color="success">
            {translate.ok}
          </Button>
          <Button id = "btnCancel" onClick={(e) => onClickCancel(dialogData)} color="primary">
            {translate.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withRouter(ZonesForm);
