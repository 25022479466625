import React, { useState, useReducer, useEffect } from "react";
import { strings as translate } from "locale";
import { withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

// services
import AuthServices from "services/AuthServices";
import MainServices from "services/MainServices";
import { Auth } from "aws-amplify";

import { UserContext } from "providers/UserProvider";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import routes, { getFirstRoute } from "../../routes";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles(styles);

const LoginForm = (props) => {
  const { onNewPasswordRequired, onConfirmSignUp, onForgotPassword } = props;
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [inputValues, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { email: "", password: "" }
  );
  const [apiError, setApiError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const useUserContext = React.useContext(UserContext);

  const classes = useStyles();

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);

    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const setInput = (e) => {
    const { name, value } = e.target;
    setInputValues({ [name]: value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleNewPasswordRequired = (user) => {
    if (onNewPasswordRequired) {
      onNewPasswordRequired(user);
    }
  };

  const handleConfirmSignUp = (user) => {
    if (onConfirmSignUp) {
      onConfirmSignUp(user);
    }
  };

  const handlerShowForgotPassword = () => {
    if (onForgotPassword) {
      onForgotPassword();
    }
  };

  const handlerRegister = () => {
    props.history.push("/auth/register");
  };
  const loadConfig = async () => {
    try {
      const res = await MainServices.config();
      if (res) {
        MainServices.saveConfig(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      let fullpath = "admin/dashboard";
      // obtenemos la primer ruta configurada en routes.js
      const configuration = MainServices.getConfig();
      const newRoute =
        configuration && configuration.menu
          ? getFirstRoute(configuration.menu)
          : routes;
      const { layout, path } = newRoute;
      if (path !== "/login") {
        fullpath = `${layout}${path}`;
      }

      setTimeout(() => {
        props.history.push(fullpath);
      }, 500);
    }
  };

  const login = async (e) => {
    e.preventDefault();

    if (!inputValues.email.trim() && !inputValues.password.trim()) {
      setApiError(translate.incorrect_username_or_password);
      return;
    }
    setApiError("");

    try {
      const user = await Auth.signIn(inputValues.email, inputValues.password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        AuthServices.deleteUser();
        handleNewPasswordRequired(user);
      } else if (user) {
        AuthServices.saveUser(user);
        useUserContext.updateUser();
        loadConfig();
      } else {
        setApiError("Error de inicio de sesión");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setApiError(error.response.data.msg_error);
      } else {
        if (error.message === "User is not confirmed.") {
          handleConfirmSignUp({ user: { username: inputValues.email } });
        } else {
          setApiError(error.message);
        }
      }
    }
  };

  return (
    <>
      <form onSubmit={login}>
        <Card login className={classes[cardAnimaton]}>
          <CardHeader
            className={`${classes.cardHeader} ${classes.textCenter}`}
            color="primary"
          >
            <h4 className={classes.cardTitle}>{translate.login}</h4>
          </CardHeader>
          <CardBody>
            <CustomInput
              labelText={translate.email + "/" + translate.username}
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              value={inputValues.email}
              inputProps={{
                onChange: setInput,
                name: "email",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Person className={classes.inputAdornmentIcon} />
                </InputAdornment>
              }
            />
            <CustomInput
              labelText={translate.password}
              id="password"
              formControlProps={{
                fullWidth: true,
              }}
              value={inputValues.password}
              inputProps={{
                onChange: setInput,
                name: "password",
                type: showPassword ? "text" : "password",
                autoComplete: "off",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {apiError && <FormHelperText error>{apiError}</FormHelperText>}
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button id="btnLogin" color="primary" size="lg" block type="submit">
              {translate.login_button_text}
            </Button>
          </CardFooter>
        </Card>
      </form>

      <GridItem
        container
        xs={12}
        justify="center"
        className={classes.initCardAnimation + " " + classes[cardAnimaton]}
      >
        <Button
          id="btnForgot"
          simple
          size="sm"
          type="button"
          color="info"
          onClick={handlerShowForgotPassword}
        >
          {translate.forgot_your_password}
        </Button>
      </GridItem>

      {/* <GridItem container xs={12} justify="center" alignItems="center" className={classes.initCardAnimation + ' ' + classes[cardAnimaton]}>
      { translate.no_account }
      <Button
        simple
        size="sm"
        type="button"
        color="info"
        onClick={handlerRegister}
      >
        {translate.register_button_text}
      </Button>
    </GridItem> */}
    </>
  );
};

export default withRouter(LoginForm);
