import API, { cognitoStorageId } from './api.js';
import axios from 'axios';
import {Cookies} from 'react-cookie';

let cancelToken;

const AuthServices = {
    register: (data, hash) => {
        cancelToken = axios.CancelToken.source();
        console.log('register', 
        {data: {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            hash
        }})
        return API({
            method: 'POST',
            url: `/register-web`,
            data: {
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                hash
            },
            cancelToken: cancelToken.token
        });
    },
    login: (email, password) => {
        const data = {
            id: 123,
            name: "login_coach",
            session: "",
            args: {
                email: email,
                password: password
            }
        }

        return API({
            method: 'post',
            url: '/front_ws/call/',
            data: data,
            headers: {
                "Accept": "application/json"
            }
        });
    },
    logout: () => {
      AuthServices.deleteUser();
      AuthServices.deleteCookieUser();
    },
    loggined: () => {
        const username = localStorage.getItem('username');
        const found = Object.keys(localStorage).filter(item=>item.match(/accessToken$/));
        const storageName = found ? found[0] : `${cognitoStorageId}.${username}.accessToken`;
        return localStorage.getItem(storageName);
    },
    saveUser: (user) => {
        localStorage.setItem('username', user.username);
        Object.keys(user.pool.storage).forEach( k => localStorage.setItem(k, user.pool.storage[k]))
    },
    getUser: () => {
        const username = localStorage.getItem('username');
        const found = Object.keys(localStorage).filter(item=>item.match(/userData$/));
        const storageName = found ? found[0] : `${cognitoStorageId}.${username}.userData`;
        const session = localStorage.getItem(storageName);
        if (session) {
            return JSON.parse(session);
        }
        return null;
    },
    deleteUser: () => {
        localStorage.removeItem('username');
        localStorage.removeItem('amplify-signin-with-hostedUI');
        Object.keys(localStorage).forEach(item => {
            if(item.match(/CognitoIdentityServiceProvider/)) {
                localStorage.removeItem(item);
            }
        })
    },
    getCookieUser: () => {
        const cookies = new Cookies().getAll();
        const user = cookies.username ? {} : null;
        return user;
    },
    getCookieAccessToken: () => {
        const tkn = new Cookies().get('accessToken');
        return tkn;
    },
    deleteCookieUser: () => {
        const cookies = new Cookies();
        cookies.remove('accessToken');
    }
};

export default AuthServices;
