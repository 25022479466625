/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import _ from "lodash";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";

import UserProvider from "providers/UserProvider";

import MainServices from "./services/MainServices";

import { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

const App = () => {
  const hist = createBrowserHistory();
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    const getConfiguration = async () => {
      try {
        const res = await MainServices.config();
        if (res) {
          const apiAwsConfig = res.data.cognito
            ? {
                region: res.data.cognito.region,
                userPoolId: res.data.cognito.userPoolId,
                userPoolWebClientId: res.data.cognito.userPoolWebClientId,
                Auth: {
                  region: res.data.cognito.region,
                  userPoolId: res.data.cognito.userPoolId,
                  userPoolWebClientId: res.data.cognito.userPoolWebClientId,
                },
              }
            : {};
          const newAwsConfig = Object.assign(awsconfig, apiAwsConfig);
          const oldConfig = MainServices.getConfig();

          Auth.configure(newAwsConfig);

          MainServices.saveConfig(res.data);

          const savedConfig = MainServices.getConfig();

          if (!_.isEqual(oldConfig, savedConfig)) {
            window.location.reload();
          } else {
            setLoaded(true);
          }
        }
      } catch (error) {
        console.warn(
          `Configuration settings could not be received. ${error.message}`
        );
      } finally {
        setLoaded(true);
      }
    };

    getConfiguration();
  }, []);

  return (
    loaded && (
      <UserProvider>
        <Router history={hist}>
          <Switch>
            <Route path="/rtl" component={RtlLayout} />
            <Route path="/auth" component={AuthLayout} />
            <Route path="/admin" component={AdminLayout} />
            <Redirect from="/" to="/auth/login" />
          </Switch>
        </Router>
      </UserProvider>
    )
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
