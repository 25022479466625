import InputForm from "components/CustomForms/InputForm";
import InputTime from "components/CustomInput/InputTime";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isValid } from "date-fns";
import React, { useEffect, useState } from "react";
import { strings as translate, get_language } from "../../locale";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./styles";
import cx from "classnames";
import { Formik, setIn } from "formik";
import { trackPromise } from "react-promise-tracker";
import TournamentServices from "services/TournamentServices";
import MainServices from "services/MainServices";
import useMainNotification from "hooks/useMainNotification";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import { Button } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles(styles);

const MatchForm = ({
  values,
  onChange,
  onSave,
  onSaveError,
  onCancel,
  data,
  editForm,
}) => {
  const [valid, setValid] = useState(null);
  const classes = useStyles();
  const [initialValues, setInitialValues] = useState({});
  const { addSuccess, addError } = useMainNotification();
  const [formValues, setFormValues] = useState({
    ...values,
    time: `${values.hour}:${values.min}`,
  });
  const [teamList, setTeamList] = useState([
    { id: "", name: "Seleccione Fase" },
  ]);
  const [teamList2, setTeamList2] = useState([
    { id: "", name: "Seleccione Fase" },
  ]);

  useEffect(() => {
    if (data) {
      setInitialValues(values);
      isValid();
    }
  }, [data]);

  useEffect(() => {
    isValid();
    if (formValues["idft"]) {
      Object.keys(data.values.zones.columns).forEach((i) => {
        if (data.values.zones.columns[i].id_phase === formValues["idft"]) {
          setTeamList(data.values.zones.columns[i].teams);
        }
        if (
          data.values.zones.columns[i].id_phase === formValues["idft_team_2"]
        ) {
          let teams = [];
          const columns = data.values.zones.columns;
          Object.keys(columns).forEach((k) => {
            if (columns[k].id_phase == formValues["idft_team_2"]) {
              teams = columns[k].teams;
            }
          });
          setTeamList2(teams);
        }
      });
    }
  }, [formValues]);

  const handleChange = (fieldname, value, formik) => {
    if (
      formValues.interzone === true &&
      ((fieldname === "idft_team_2" && formValues.idft == value) ||
        (fieldname === "idft" && formValues.idft_team_2 == value))
    ) {
      addError("No se puede seleccionar la misma fase en ambos campos", null, {
        messageLength: null,
      });
      formik.setFieldValue(fieldname, "");
      if (fieldname === "idft") {
        formik.setFieldValue("team_1", "");
      }
      if (fieldname === "idft_team_2") {
        formik.setFieldValue("team_2", "");
      }
      return;
    }
    setFormValues((v) => ({ ...v, [fieldname]: value }));
    if (fieldname === "idft_team_2") {
      let teams = [];
      const columns = data.values.zones.columns;
      Object.keys(columns).forEach((k) => {
        if (columns[k].id_phase == value) {
          teams = columns[k].teams;
        }
      });
      setTeamList2(teams);
    }
    if (onChange) {
      onChange(fieldname, value);
    }
  };

  const isValid = () => {
    if (
      formValues["idft"] &&
      formValues["round"] &&
      formValues["footballfield"] &&
      formValues["date"] &&
      formValues["time"] &&
      formValues["team_1"] &&
      formValues["team_2"]
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const gridContainer = cx({
    [classes.matchRowError]: !valid,
    [classes.matchRowSuccess]: valid,
  });

  const handleFormSubmit = async (values, actions) => {
    try {
      const date = moment(
        formValues.date,
        get_language === "es" ? "DD/MM/YYYY" : "MM/DD/YYYY"
      ).format("YYYY-MM-DD");
      const fixture = [
        {
          round: {
            number: formValues.round,
            id_ft: formValues.idft,
            name_phase: null,
            type_phase: null,
            matches: [
              {
                ...(values.id !== undefined ? { id: values.id } : null),
                id_ft: formValues.idft,
                datetime: `${date} ${formValues.time}:00`,
                team_1: formValues.team_1,
                team_2: formValues.team_2,
                footballfield: formValues.footballfield,
                tournament: formValues.tournament,
                interzone: formValues.interzone,
              },
            ],
          },
        },
      ];
      let res = null;
      if (values.id) {
        // editar partido
        res = await TournamentServices.updateFixtureMatches({ fixture });
      } else {
        // agregar partido
        res = await TournamentServices.addFixtureMatches({ fixture });
      }

      if (!res.error || !res.data.error) {
        addSuccess(
          `${translate.matches} ${
            values.id
              ? translate.has_been_updated_successfully
              : translate.has_been_added_successfully
          }`
        );
      }

      if (onSave) {
        onSave({ ...values, fixture });
      }
    } catch (e) {
      const data = e.response ? e.response.data : {};
      let errors = {};

      Object.keys(data).forEach((item) => {
        errors = setIn(errors, item, data[item][0]);
      });

      actions.setStatus(errors);

      handleSentError(e.response);
      if (e.response && e.response.data && e.response.data.error) {
        addError(e.response.data.error_msg, null, { messageLength: null });
      } else if (e.message) {
        addError(e.message, null, { messageLength: null });
      }
      MainServices.cancel();
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleSentError = (values) => {
    if (onSaveError) {
      onSaveError(values);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        trackPromise(handleFormSubmit(values, actions), "save-button");
      }}
    >
      {(formik) => (
        <form
          onSubmit={formik.handleSubmit}
          onKeyDown={(keyEvent) => {
            if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
              keyEvent.preventDefault();
            }
          }}
        >
          <GridContainer className={gridContainer}>
            <GridItem xs={3}>
              <InputForm
                data={{
                  accessor: `interzone`,
                  header: translate.interzone,
                  editable: !editForm,
                  type: "checkbox",
                  options: data.phases_tournament.phases,
                }}
                onChange={(e) => {
                  handleChange("interzone", e.target.checked);
                  console.log("interzone", e.target.checked);
                }}
                editForm={editForm}
              />
            </GridItem>
          </GridContainer>
          <GridContainer className={gridContainer}>
            {formik.values.interzone === false && (
              <GridItem xs={3}>
                <InputForm
                  data={{
                    accessor: `idft`,
                    header: translate.phase,
                    editable: true,
                    type: "select",
                    options: data.phases_tournament.phases,
                  }}
                  onChange={(e) => {
                    handleChange("idft", e.target.value);
                  }}
                  required={true}
                  editForm={editForm}
                />
              </GridItem>
            )}
            <GridItem xs={3}>
              <InputForm
                data={{
                  accessor: `round`,
                  header: translate.match_round,
                  editable: true,
                  type: "number",
                }}
                inputProps={{
                  min: 1,
                }}
                onChange={(e) => {
                  handleChange("round", e.target.value);
                }}
                required={true}
                editForm={editForm}
              />
            </GridItem>
            {formik.values.interzone === true && (
              <>
                <GridItem xs={2}></GridItem>
                <GridItem xs={3}>
                  <InputForm
                    data={{
                      accessor: `idft`,
                      header: translate.phase_team_1,
                      editable: true,
                      type: "select",
                      options: data.phases_tournament.phases,
                    }}
                    onChange={(e) => {
                      handleChange("idft", e.target.value, formik);
                    }}
                    required={true}
                    editForm={editForm}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <InputForm
                    data={{
                      accessor: `idft_team_2`,
                      header: translate.phase_team_2,
                      editable: true,
                      type: "select",
                      options: data.phases_tournament.phases,
                    }}
                    onChange={(e) => {
                      handleChange("idft_team_2", e.target.value, formik);
                    }}
                    required={true}
                    editForm={editForm}
                  />
                </GridItem>
              </>
            )}
          </GridContainer>
          <GridContainer
            className={gridContainer}
            style={{ minHeight: "100px" }}
          >
            <GridItem xs={1}>
              <InputForm
                data={{
                  accessor: `id`,
                  type: "hidden",
                }}
              />
              <InputForm
                data={{
                  accessor: `tournament`,
                  type: "hidden",
                }}
              />
              <InputForm
                data={{
                  accessor: `footballfield`,
                  header: translate.football_field,
                  editable: true,
                  type: "number",
                }}
                inputProps={{
                  min: 1,
                }}
                onChange={(e) => {
                  handleChange("footballfield", e.target.value);
                }}
                required={true}
                editForm={editForm}
              />
            </GridItem>
            <GridItem xs={2}>
              <InputForm
                data={{
                  accessor: `date`,
                  header: translate.date,
                  editable: true,
                  type: "date",
                }}
                onChange={(e) => {
                  handleChange("date", e);
                }}
                required={true}
                editForm={editForm}
              />
            </GridItem>
            <GridItem xs={2}>
              <InputTime
                data={{
                  phase: data.phase,
                  phaseNumber: data.phaseNumb,
                  roundNumber: data.roundNumb,
                  matchNumber: data.matchNumb,
                }}
                onChange={(e) => {
                  handleChange("time", e);
                }}
                single={true}
              />
            </GridItem>
            <GridItem xs={3}>
              <InputForm
                data={{
                  accessor: `team_1`,
                  header: translate.team + " 1",
                  editable: true,
                  type: "select",
                  options: teamList,
                }}
                onChange={(e) => {
                  handleChange("team_1", e.target.value);
                }}
                required={true}
                editForm={editForm}
              />
            </GridItem>
            <GridItem xs={3}>
              <InputForm
                data={{
                  accessor: `team_2`,
                  header: translate.team + " 2",
                  editable: true,
                  type: "select",
                  options:
                    formik.values.interzone === false ? teamList : teamList2,
                }}
                onChange={(e) => {
                  handleChange("team_2", e.target.value);
                }}
                required={true}
                editForm={editForm}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} container justify="center">
              <div className={classes.formButtonWithLoader}>
                <LoadingIndicator
                  type="TailSpin"
                  color="#2e82ef"
                  height={30}
                  width={30}
                  area="save-button"
                />
                <Button
                  id="btnNext"
                  type="button"
                  color="primary"
                  onClick={handleCancel}
                >
                  {translate.cancel}
                </Button>
                <Button
                  id="btnNext"
                  type="submit"
                  color="primary"
                  disabled={!valid}
                >
                  {translate.save}
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </form>
      )}
    </Formik>
  );
};

export default MatchForm;
