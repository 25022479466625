import React from 'react';
import { withRouter } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import {strings as translate} from "../../locale";

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CardBody from "../../components/Card/CardBody";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Form from "../../components/CustomForms/Form";
import Button from "../../components/CustomButtons/Button";
import useMainNotification from '../../hooks/useMainNotification';

// services
import MainServices from "../../services/MainServices";

// contexts
import {GenericPageContext} from "../../providers/GenericPageProvider";

// styles
import styles from './styles';

const useStyles = makeStyles(styles);

function Add(props) {
    const config = React.useContext(GenericPageContext)
    const model = config.model;
    const classes = useStyles();
    const [data, setData] = React.useState(() => []);
    const [loaded, setLoaded] = React.useState(false);
    const [columns, setColumns] = React.useState(2);
    const { addError } = useMainNotification();

    React.useEffect(() => {
        async function loadMetaData() {
            try {
              const res = await MainServices.getMetadata(model);
              if(!res.data.error) {
                  const metadata = res.data.metadata;
                  if(res.data.config && res.data.config.form_layout_column) {
                    setColumns(res.data.config.form_layout_column)
                  }
                  return metadata;
              }
            } catch (e) {
              if(e.response && e.response.data && e.response.data.error) {
                addError(e.response.data.error_msg, null, {messageLength:null});
              } else if(e.message) {
                addError(e.message, null, {messageLength:null});
              } else {
                console.log(e);
              }
            }
        }

        trackPromise(loadMetaData())
            .then( res => {
                setData({metadata: res});
                setLoaded(true)
            })

        return () => { MainServices.cancel(); }
    }, [addError, model]);

    return (
      <>
        <LoadingIndicator
          type="TailSpin"
          color="#2e82ef"
          height={50}
          width={50}
        />

        <GridContainer>
          <GridItem xs={12}>
            <Button id="btnBack" color="info" onClick={() => props.history.goBack()}>
              &lt; {translate.back}
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">{translate.add}</CardIcon>
              </CardHeader>
              <CardBody>
                <div className={classes.root}>
                  {loaded && (
                    <Form
                      name={model}
                      data={data}
                      columns={columns}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
}

export default withRouter(Add);
