import { strings as translate, get_language } from "../locale";

import _ from "lodash";
import moment from "moment";

const getHoursValue = (value) => {
  if (get_language === "es") {
    const newTime = value.split(":");
    let h = parseInt(newTime[0]) < 10 ? "0" + parseInt(newTime[0]) : newTime[0];
    let m = newTime[1] ? newTime[1] : "00";
    m = parseInt(m) < 10 ? "0" + parseInt(m) : m;
    let s = newTime[2] ? newTime[2] : "00";
    s = parseInt(s) < 10 ? "0" + parseInt(s) : s;
    value = `${h}:${m}:${s}`;
  } else {
    const newTime = value.split(":");
    const minutes = newTime[1] ? newTime[1].split(" ") : "";
    let h = parseInt(newTime[0]) < 10 ? "0" + parseInt(newTime[0]) : newTime[0];
    let m = minutes[0] ? minutes[0] : "00";
    m =
      parseInt(m) < 10
        ? m.length === 1
          ? parseInt(m) + "0"
          : "0" + parseInt(m)
        : m;
    m = m.length > 2 ? m.substring(0, 2) : m;
    let a = minutes[1] ? minutes[1].toString().toUpperCase() : "PM";
    a = a.length < 2 ? a + "M" : a;
    value = `${h}:${m} ${a}`;
  }
  return value;
};

const scoreSystemFormat = (score) => {
  const parts = score.toString().split("");
  if (parts.length === 3) {
    score = `${translate.win_record_abbr}:${parts[0]} - ${translate.draw_record_abbr}:${parts[1]} - ${translate.loss_record_abbr}:${parts[2]}`;
  }
  return score;
};

const formikValuesToDataJson = (_values, tournamentId) => {
  const fixture = [];
  let f = -1;
  let p = 0;
  const values = JSON.parse(JSON.stringify(_values));
  var ordered = {};
  _(_values)
    .keys()
    .sort()
    .each(function (key) {
      ordered[key] = _values[key];
    });

  Object.keys(ordered).map((k) => {
    if (k.match(/^phase/) && k.match(/footballfield/)) {
      const parts = k.split("_");
      const phase = parts[1];
      const round = parts[2] === "round" ? parts[3] : null;
      const match = round !== null ? parts[5] : parts[3];
      const prefix =
        round !== null
          ? k.substring(0, k.lastIndexOf("_"))
          : k.substring(0, 15);

      const pre = round !== null ? k.substring(0, 16) : k.substring(0, 8);
      const fieldname = k.substring(
        k.lastIndexOf(round !== null ? parts[6] : parts[4])
      );

      if (p !== pre) {
        f++;
      }
      p = pre;

      if (!fixture[f]) {
        fixture[f] = {
          round: {
            number: parseInt(round),
            id_ft: values[`${prefix}_idft`],
            name_phase: null,
            type_phase: null,
            matches: [],
          },
        };
      }
      const newDate = moment(
        values[`${prefix}_date`],
        get_language === "es" ? "DD/MM/YYYY" : "MM/DD/YYYY"
      ).format("YYYY-MM-DD");

      if (!fixture[f].round.matches[match - 1]) {
        fixture[f].round.matches[match - 1] = {
          ...(values[`${prefix}_id`] && {
            id: parseInt(values[`${prefix}_id`]),
          }),
          id_ft: values[`${prefix}_idft`],
          datetime:
            newDate +
            " " +
            values[`${prefix}_hour`] +
            ":" +
            values[`${prefix}_min`] +
            ":00",
          team_1: values[`${prefix}_team_1`],
          team_2: values[`${prefix}_team_2`],
          footballfield: values[`${prefix}_footballfield`],
          tournament: parseInt(tournamentId),
        };
      }
    }
  });

  return fixture;
};

const formikValuesToFixtureMatches = (values) => {
  const phaseMatch = [];
  const fixture = [];

  Object.keys(values).map((k) => {
    if (k.match(/^phase/) && !k.match(/min_obj|team_1_obj|team_2_obj/)) {
      const parts = k.split("_");
      const phase = parts[1];
      const round = parts[2] === "round" ? parts[2] : null;
      const match = round !== null ? parts[5] : parts[3];
      const fieldname = k.substring(
        k.lastIndexOf(round !== null ? parts[6] : parts[4])
      );
      if (!phaseMatch[phase - 1]) {
        phaseMatch[phase - 1] = [];
      }
      if (!phaseMatch[phase - 1][match - 1]) {
        phaseMatch[phase - 1][match - 1] = {};
      }
      phaseMatch[phase - 1][match - 1] = {
        ...phaseMatch[phase - 1][match - 1],
        [fieldname]: values[k],
      };
    }
  });

  phaseMatch.forEach((phase) => {
    const pMatches = phase
      .map((match) => ({
        ...(match.id && { id: match.id }),
        datetime:
          match.date !== undefined
            ? `${moment(match.date, "DD/MM/YYYY").format("YYYY-MM-DD")} ${
                match.hour
              }:${match.min}`
            : "",
        team_1: match.team_1,
        team_2: match.team_2,
        tournament: match.tournament,
        footballfield: match.footballfield,
      }))
      .filter((i) => i.team_1 !== undefined && i.team_2 !== undefined);
    fixture.push({
      round: {
        id_ft: phase[0].idft,
        number: 1,
        matches: pMatches,
      },
    });
  });
  return { fixture };
};

const fixtureToPostPut = (data) => {
  let postMatches = 0;
  let putMatches = 0;
  const fixturePost = {
    fixture: data.fixture
      .map((i) => {
        let roundMatches = [];
        if (i.round !== undefined && i.round.matches !== undefined) {
          roundMatches = i.round.matches.filter((i) => {
            const notId = i.id === undefined;
            if (notId) {
              postMatches++;
            }
            return notId;
          });
        }
        return {
          ...i,
          round: {
            ...i.round,
            matches: roundMatches,
          },
        };
      })
      .filter((i) => i.round.matches.length > 0),
  };
  const fixturePut = {
    fixture: data.fixture
      .map((i) => {
        let roundMatches = [];
        if (i.round !== undefined && i.round.matches !== undefined) {
          roundMatches = i.round.matches.filter((i) => {
            const hasId = i.id !== undefined;
            if (hasId) {
              putMatches++;
            }
            return hasId;
          });
        }
        return {
          ...i,
          round: {
            ...i.round,
            matches: roundMatches,
          },
        };
      })
      .filter((i) => i.round.matches.length > 0),
  };

  return { fixturePost, fixturePut };
};

const dataJsontoPostPut = (fixture) => {
  const dataPost = JSON.parse(JSON.stringify(fixture));
  // console.log("fixture", fixture);
  const filterPost = dataPost.map((phase) => {
    const matches = phase.round.matches.filter((match) => {
      return (
        match.id === undefined && match.team_1 !== null && match.team_2 !== null
      );
    });
    if (matches.length > 0) {
      phase.round.matches = matches;
      return phase;
    }
    return null;
  });

  const fixturePost = filterPost.filter((i) => i !== null);

  const dataPut = JSON.parse(JSON.stringify(fixture));
  const filterPut = dataPut.map((phase, k) => {
    const matches = phase.round.matches.filter((match) => {
      return match.id !== undefined;
    });

    if (matches.length > 0) {
      phase.round.matches = matches;
      return phase;
    }
    return null;
  });

  const fixturePut = filterPut.filter((i) => i !== null);
  console.log({
    fixturePost,
    fixturePut,
  });
  return {
    fixturePost,
    fixturePut,
  };
};

const columnHasFixtureAwayMatches = (data, column_name) => {
  // si la cantidad de equipos en la zona es impar
  const impar = (data.values.zones.columns[column_name].teams.length - 1) % 2;

  // cantidad de partidos de ida
  const totalHomeRounds =
    data.values.zones.columns[column_name].teams.length - (impar ? 1 : 0);

  // buscamos si hay cargado un partido de vuelta
  const awayMatches = data.values.fixture.filter(
    (i) => i.round.number > totalHomeRounds
  );

  return awayMatches.length > 0;
};

const hasFixtureAwayMatches = (data) => {
  // si la cantidad de equipos en la zona es impar
  const impar = (data.values.zones.columns.teams1.teams.length - 1) % 2;

  // cantidad de partidos de ida
  const totalHomeRounds =
    data.values.zones.columns.teams1.teams.length - (impar ? 1 : 0);

  // buscamos si hay cargado un partido de vuelta
  const awayMatches = data.values.fixture.filter(
    (i) => i.round.number > totalHomeRounds
  );

  return awayMatches.length > 0;
};

const dataValuesToFixture = (data) => {
  let pk = 0;
  let rk = 0;
  let dataJson = [];

  // console.log("data.values.zones.columns", data.values.zones.columns);
  Object.keys(data.values.zones.columns).map((colName) => {
    const phase = data.values.zones.columns[colName];
    const homeandaway = false; //columnHasFixtureAwayMatches(data, colName);

    if (colName !== "allTeams") {
      // console.log("phase", pk, phase);
      Array.from(
        {
          length:
            (phase.teams.length + (phase.teams.length % 2 ? 0 : -1)) *
            (homeandaway ? 2 : 1),
        },
        (_, i) => i + 1
      ).map((i, k) => {
        // console.log("-round", k);
        Array.from({ length: phase.teams.length / 2 }, (_, i) => i + 1).map(
          (j, mk) => {
            // console.log("--match", mk);
            if (!dataJson[rk]) {
              dataJson[rk] = {};
            }
            if (!dataJson[rk].round) {
              dataJson[rk] = {
                round: {
                  number: k + 1,
                  id_ft: phase.id_phase,
                  name_phase: null,
                  type_phase: null,
                  matches: [],
                },
              };
            }
            if (!dataJson[rk].round.matches[mk]) {
              dataJson[rk].round.matches[mk] = {
                datetime: null,
                team_1: null,
                team_2: null,
                footballfield: "",
                tournament: data.id_tournament,
              };
            }
            let matchData = {};
            data.values.fixture.forEach((f) => {
              if (
                f.round.id_ft === phase.id_phase &&
                f.round.number === k + 1
              ) {
                // console.log("phase && round = true");
                if (f.round.matches[mk]) {
                  // console.log("matchData", mk, f.round.matches[mk]);
                  matchData = f.round.matches[mk];
                  dataJson[rk].round.matches[mk] = f.round.matches[mk];
                }
              }
            });
            return false;
          }
        );
        rk++;
        return false;
      });
      pk++;
    }
    return false;
  });
  // console.log("dataJson", dataJson);
  return dataJson;
};

const dataValuesToFormikValues = (data, tournament_id) => {
  let newValues = {};
  let phase = null;
  let key = 0;
  let round = null;
  let roundkey = 0;
  const orderFixture = _.orderBy(data.values.fixture, ["round.id_ft"]);

  orderFixture.map((i, k) => {
    i.round.matches.map((j, kk) => {
      // Match
      if (phase != i.round.id_ft) {
        key++;
        phase = i.round.id_ft;
        roundkey = 0;
        round = null;
      }

      if (round !== i.round.number) {
        roundkey++;
        round = i.round.number;
      }

      const teams_id = data.values.zones.columns["teams" + key].teams.map(
        (i) => i.id
      );
      // console.log(`phase_${key}_round_${roundkey}_match_${kk + 1}_idft`);
      const date_time = j.datetime.split(" ");
      const hour_min = date_time[1].split(":");
      const team_1 = teams_id.includes(j.team_1) ? j.team_1 : "";
      const team_2 = teams_id.includes(j.team_2) ? j.team_2 : "";
      const prefix = `phase_${key}_round_${roundkey}_match_${kk + 1}`;
      newValues = {
        ...newValues,
        ...(j.id !== undefined && { [`${prefix}_id`]: j.id }),
        [`${prefix}_idft`]: phase,
        [`${prefix}_tournament`]: tournament_id,
        [`${prefix}_footballfield`]: j.footballfield,
        [`${prefix}_date`]: moment(date_time[0], "YYYY-MM-DD").format(
          get_language === "es" ? "DD/MM/YYYY" : "MM/DD/YYYY"
        ),
        [`${prefix}_hour`]: hour_min[0],
        [`${prefix}_min`]: hour_min[1],
        [`${prefix}_team_1`]: team_1,
        [`${prefix}_team_2`]: team_2,
      };
    });
  });

  // si existen fechas/partidos sin datos llenamos los campos
  // idft y tournament para cada partido
  let pk = 1;
  Object.keys(data.values.zones.columns).forEach((colName) => {
    let rk = 1;
    const phase = data.values.zones.columns[colName];
    const isOdd = phase.teams.length % 2 ? true : false;

    if (colName !== "allTeams") {
      Array.from(
        {
          length: phase.teams.length - (isOdd ? 0 : 1),
        },
        (_, i) => i + 1
      ).forEach((i, k) => {
        Array.from({ length: phase.teams.length / 2 }, (_, i) => i + 1).forEach(
          (j, mk) => {
            const prefix = `phase_${pk}_round_${rk}_match_${mk + 1}`;
            if (newValues[prefix + "_idft"] == undefined) {
              newValues = {
                ...newValues,
                [`${prefix}_idft`]: phase.id_phase,
                [`${prefix}_tournament`]: tournament_id,
              };
            }
          }
        );
        rk++;
      });
      pk++;
    }
  });

  return newValues;
};

export {
  getHoursValue,
  scoreSystemFormat,
  formikValuesToDataJson,
  formikValuesToFixtureMatches,
  fixtureToPostPut,
  dataJsontoPostPut,
  dataValuesToFixture,
  dataValuesToFormikValues,
  hasFixtureAwayMatches,
  columnHasFixtureAwayMatches,
};
