import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// core components
import Button from "components/CustomButtons/Button";

// services
import MainServices from "services/MainServices";

import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const classes = useStyles();
  const { color, brandText } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const configuration = MainServices.getConfig();

  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={classes.flex}>
            { configuration && configuration.logo && 
              <Button href="/" className={classes.logo} color="transparent">
                <img src={configuration.logo} alt="logo" className={classes.img} />
              </Button>
            }
            <Button href="/" className={classes.ml3 + ' ' + classes.logo} simple>
              {configuration && configuration.title ? configuration.title : brandText}
            </Button>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.flex}>
            { configuration && configuration.logo && 
              <Button href="/" className={classes.logo} color="transparent">
                <img src={configuration.logo} alt="logo" className={classes.img} />
              </Button>
            }
            <Button href="/" className={classes.ml3 + ' ' + classes.logo} simple>
              {configuration && configuration.title ? configuration.title : brandText}
            </Button>
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};
