import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { strings as translate } from "../../locale";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Button from "../../components/CustomButtons/Button";
import useMainNotification from "../../hooks/useMainNotification";

import { Formik, setIn } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputForm from "components/CustomForms/InputForm";
import { getYupSchemaFromMetaData } from "../../components/CustomForms/yupSchemaCreator";

// services
import MainServices from "../../services/MainServices";

// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

function TournamentForm(props) {
  const { onSave, onSaveError, editForm } = props;
  const model = "tournament";
  const classes = useStyles();
  const [mounted, setMounted] = useState(true);
  const [data, setData] = useState(() => []);
  const [loaded, setLoaded] = useState(false);
  const [columns, setColumns] = useState(2);
  const [fields, setFields] = useState([]);
  const [requiredFields, setRequiredFields] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [requiredSelectInput, setRequiredSelectInput] = useState([]);
  const { addSuccess, addError } = useMainNotification();

  const [uniqueId, setUniqueId] = useState({});
  const [idField, setIdField] = useState("id");

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    async function loadMetaData() {
      try {
        const res = await MainServices.getMetadata(model);
        if (!res.data.error && mounted) {
          const metadata = res.data.metadata;
          if (res.data.config && res.data.config.form_layout_column) {
            setColumns(res.data.config.form_layout_column);
          }
          setRequiredSelectInput(
            metadata.filter((i) => i.type === "select" && i.required === true)
          );
          setData({ metadata: metadata });
          setLoaded(true);
          return metadata;
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.error) {
          addError(e.response.data.error_msg, null, { messageLength: null });
        } else if (e.message && e.message !== "Request canceled.") {
          addError(e.message, null, { messageLength: null });
        } else {
          console.log(e);
        }
      }
    }
    if (!editForm) {
      loadMetaData();
    }

    return () => {
      MainServices.cancel();
    };
  }, [addError, model, mounted]);

  useEffect(() => {
    if (props.data !== null && props.data !== undefined) {
      setData(props.data);
      setLoaded(true);
    }
  }, [props.data]);

  useEffect(() => {
    if (!data || !data.metadata) return;
    const uniqueId = data.metadata.filter((item) => item.unique).pop();

    setUniqueId(uniqueId);

    const idField = uniqueId ? uniqueId.accessor : "id";
    setIdField(idField);

    // filtarmos los campos utiles
    const fields = data.metadata.filter(
      (item) =>
        ((!editForm && item.accessor !== idField) || editForm) &&
        item.accessor !== "actions"
    );
    setFields(fields);

    // establecemos los valores iniciales, para la edición hay que obtenerlos del data.data
    const getInitialValues = () => {
      const values = {};
      const fData =
        data && data[0]
          ? data[0]
          : data && data.data && data.data[0]
          ? data.data[0]
          : data;

      if (!editForm) {
        fields.forEach((item) => {
          let value = "";
          if (item.default !== undefined) {
            value = item.default.toString();
          } else if (item.type === "checkbox") {
            value = false;
          } else if (fData[item.accessor]) {
            value = fData[item.accessor];
          }
          Object.assign(values, { [item.accessor]: value });
        });
      } else {
        fields.forEach((item) => {
          const selectValue =
            fData[item.accessor] && fData[item.accessor].id
              ? fData[item.accessor].id
              : fData[item.accessor];
          Object.assign(values, {
            [item.accessor]:
              item.type === "select" ? selectValue : fData[item.accessor],
          });
        });
      }

      return values;
    };

    setInitialValues(getInitialValues());

    // establecemos las validaciones
    setValidationSchema(getYupSchemaFromMetaData(data.metadata, [], []));

    // buscamos campos requeridos
    const hasRequiredFields = () => {
      let required = false;
      fields.forEach((item) => {
        if (!item.validations) {
          return;
        }
        item.validations.forEach((v) => {
          if (v.type === "required") {
            required = true;
          }
        });
      });
      return required;
    };
    setRequiredFields(hasRequiredFields());
  }, [editForm, data]);

  const handleFormSubmit = async (values, actions) => {
    try {
      const newValues = Object.assign({}, values);

      // si el input image contiene una url, borramos la clave para que no se actualice
      data.metadata
        .filter((item) => item.type === "image")
        .forEach((item) => {
          if (
            typeof values[item.accessor] === "string" &&
            values[item.accessor].lastIndexOf("https") === 0
          ) {
            delete newValues[item.accessor];
          }
        });

      let res = null;
      if (!editForm) {
        res = await MainServices.add(model, newValues);
        console.log("add", res.data);
        console.log("res", res);
      } else {
        res = await MainServices.edit(model, newValues);
        console.log("edit");
      }

      if (!res.error || !res.data.error) {
        addSuccess(
          !editForm
            ? `${translate.tournament} ${translate.has_been_added_successfully}`
            : `${translate.tournament} ${translate.has_been_edited_successfully}`
        );
      }

      if (onSave) {
        onSave({ ...values, id_tournament: res.data.id_tournament });
      }
    } catch (e) {
      const data = e.response ? e.response.data : {};
      let errors = {};

      Object.keys(data).forEach((item) => {
        errors = setIn(errors, item, data[item][0]);
      });

      actions.setStatus(errors);

      handleSentError(e.response);
      if (e.response && e.response.data && e.response.data.error) {
        addError(e.response.data.error_msg, null, { messageLength: null });
      } else if (e.message) {
        addError(e.message, null, { messageLength: null });
      }
      MainServices.cancel();
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleSentError = (values) => {
    if (onSaveError) {
      onSaveError(values);
    }
  };

  const handleNext = (values) => {
    if (onSave) {
      const id =
        data.data !== undefined && data.data[0] !== undefined
          ? data.data[0].id
          : null;
      onSave({ ...values, id_tournament: id });
    }
  };

  const validateSelect = () => {
    for (let i = 0; i < requiredSelectInput.length; i++) {
      if (
        requiredSelectInput[i].value === undefined ||
        requiredSelectInput[i].value === ""
      ) {
        return false;
      }
    }
    return true;
  };

  const changeSelectValue = (e) => {
    const { name, value } = e.target;
    setRequiredSelectInput((v) =>
      v.map((i) => {
        if (name === i.accessor) {
          return { ...i, value: value };
        }
        return i;
      })
    );
  };

  return (
    <>
      <LoadingIndicator
        type="TailSpin"
        color="#2e82ef"
        height={50}
        width={50}
      />

      <div className={classes.root}>
        {loaded && ((editForm && initialValues[idField]) || !editForm) && (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              trackPromise(handleFormSubmit(values, actions), "save-button");
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <GridContainer alignItems="stretch">
                  {fields.map((item, index) => {
                    if (
                      (!editForm && item.accessor === idField) ||
                      item.type === "link"
                    ) {
                      return null;
                    }

                    if (
                      data.global_action &&
                      (data.global_action.is_confirm ||
                        (!data.global_action.is_confirm &&
                          item.accessor === "id"))
                    ) {
                      return (
                        <input
                          key={index}
                          name={item.accessor}
                          id={item.accessor}
                          type="hidden"
                          value={initialValues[item.accessor] || ""}
                        />
                      );
                    }

                    return (
                      <GridItem xs={12} sm={12 / columns} key={index}>
                        <InputForm
                          data={item}
                          editForm={editForm}
                          fullData={data[0]}
                          idField={idField}
                          onChange={
                            item.type === "select" ? changeSelectValue : null
                          }
                        />
                      </GridItem>
                    );
                  })}
                </GridContainer>
                <GridContainer style={{ marginTop: "30px" }}>
                  {requiredFields && (
                    <GridItem xs={12}>
                      <div className={classes.formCategory}>
                        <small>*</small> {translate.required_fields}
                      </div>
                    </GridItem>
                  )}
                  <GridItem xs={12} container justify="center">
                    {data.metadata ? (
                      <>
                        <div className={classes.formButtonWithLoader}>
                          <LoadingIndicator
                            type="TailSpin"
                            color="#2e82ef"
                            height={30}
                            width={30}
                            area="save-button"
                          />
                          {!editForm && (
                            <Button
                              id="btnNext"
                              type="submit"
                              color="primary"
                              disabled={
                                !(formik.isValid && formik.dirty) ||
                                formik.isSubmitting ||
                                !validateSelect()
                              }
                            >
                              {translate.next}
                            </Button>
                          )}
                          {editForm && (
                            <>
                              <Button
                                id="btnSave"
                                type="submit"
                                color="success"
                                disabled={
                                  !formik.isValid || formik.isSubmitting
                                }
                                style={{ marginRight: "24px" }}
                              >
                                {translate.save}
                              </Button>
                              <Button
                                id="btnNext"
                                type="button"
                                color="primary"
                                onClick={() => handleNext(formik.values)}
                              >
                                {translate.next}
                              </Button>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <FormHelperText
                        className={
                          classes.labelRootError + " " + classes.textCenter
                        }
                      >
                        {translate.formatString(
                          translate.autogenerate_form_error,
                          <br />
                        )}
                      </FormHelperText>
                    )}
                  </GridItem>
                </GridContainer>
              </form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
}

export default withRouter(TournamentForm);
