/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-2",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_WIk2SnfXe",
  aws_user_pools_web_client_id: "3srblfk5vfec69hj6mkpchuh25",
  oauth: {},
  region: "us-east-2",
  identityPoolRegion: "us-east-2",
  userPoolId: "us-east-2_Z52WYAmy3",
  userPoolWebClientId: "5jh1oi793lr88ns2pm67gfi803",
  mandatorySignIn: false,
  authenticationFlowType: "USER_SRP_AUTH",
  Auth: {
    region: "us-east-2",
    identityPoolRegion: "us-east-2",
    userPoolId: "us-east-2_Z52WYAmy3",
    userPoolWebClientId: "5jh1oi793lr88ns2pm67gfi803",
    mandatorySignIn: false,
    authenticationFlowType: "USER_SRP_AUTH",
    oauth: {},
  },
};

export default awsmobile;
