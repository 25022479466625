import React, { useState, useReducer, useEffect } from "react";
import { strings as translate } from "locale";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import FormHelperText from "@material-ui/core/FormHelperText";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

// services
import { Auth } from "aws-amplify";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles(styles);

const SendCodeForm = (props) => {
  const { onBack } = props;
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [inputValues, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { email: "" }
  );
  const [apiError, setApiError] = useState("");

  const classes = useStyles();

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const setInput = (e) => {
    const { name, value } = e.target;
    setInputValues({ [name]: value });
  };

  const handlerBackToLogin = () => {
    if (onBack) {
      onBack();
    }
  };

  const sendCode = async (e) => {
    e.preventDefault();

    if (!inputValues.email.trim() || !validateEmail(inputValues.email)) {
      setApiError(translate.incorrect_email);
      return;
    }
    setApiError("");

    try {
      const res = await Auth.forgotPassword(inputValues.email);
      if (res) {
        console.log(res);

        console.log(validateEmail(inputValues.email));
        if (props.onSend) {
          props.onSend(inputValues.email);
        }
      }
    } catch (error) {
      console.log(error);
      setApiError(error.message);
    }
  };

  const validateEmail = (email) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <form onSubmit={sendCode}>
        <Card login className={classes[cardAnimaton]}>
          <CardHeader
            className={`${classes.cardHeader} ${classes.textCenter}`}
            color="primary"
          >
            <h4 className={classes.cardTitle}>
              {translate.reset_your_password}
            </h4>
          </CardHeader>
          <CardBody>
            <CustomInput
              labelText={translate.email}
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              value={inputValues.email}
              inputProps={{
                onChange: setInput,
                name: "email",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              }
            />
            {apiError && <FormHelperText error>{apiError}</FormHelperText>}
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button color="primary" size="lg" block type="submit">
              {translate.send_code}
            </Button>
          </CardFooter>
        </Card>
      </form>

      <GridItem
        container
        xs={12}
        justify="center"
        alignItems="center"
        className={classes.initCardAnimation + " " + classes[cardAnimaton]}
      >
        <Button
          simple
          size="sm"
          type="button"
          color="info"
          onClick={handlerBackToLogin}
        >
          {translate.back_to_sign_in_button_text}
        </Button>
      </GridItem>
    </>
  );
};

export default SendCodeForm;
