import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes, { buildRoutes } from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import MainNotificationProvider from "../providers/MainNotificationProvider";
import MainNotification from "../components/MainNotification";

// services
import AuthServices from "services/AuthServices";
import MainServices from "services/MainServices";

var ps;
var sidebarWrapperDOM;
const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;

  const configuration = MainServices.getConfig();
  const newRoutes = configuration ? buildRoutes(configuration.menu) : routes;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const image = "";
  const color = "orange";
  const bgColor = "black";
  const logo = require("assets/img/logos/logo-sidebar.png");
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  sidebarWrapperDOM = document.querySelector("#sidebarWrapperAdmin");
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      var cont = mainPanel.current;
      if (!cont) cont = sidebarWrapperDOM;
      if (cont) {
        ps = new PerfectScrollbar(cont, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
      }
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1 && ps) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Backoffice";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        /* localStorage.setItem(
          "api_url",
          prop.api_url !== undefined ? prop.api_url : ""
        ); */
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => {
              // const currentUser = {mail: "admin@admin.com", username: "admin", role: "Admin"};
              const session = AuthServices.loggined();

              if (!session) {
                return (
                  <Redirect
                    to={{
                      pathname: "/auth/login",
                      state: { from: props.location },
                    }}
                  />
                );
              }

              /* if(prop.roles && prop.roles.indexOf(currentUser.role) === -1) {
                  return <Redirect to={{pathname: '/auth/login'}} />
                } */
              // pass the sub-routes down to keep nesting
              return (
                <prop.component {...props} routes={prop.routes} config={prop} />
              );
            }}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={newRoutes}
        logoText={"Backoffice"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div
        id="sidebarWrapperAdmin"
        className={mainPanelClasses}
        ref={mainPanel}
      >
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(newRoutes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <MainNotificationProvider>
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  {getRoutes(newRoutes)}
                  <Redirect from="/admin" to="/auth/login" />
                </Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>
                {getRoutes(newRoutes)}
                <Redirect from="/admin" to="/auth/login" />
              </Switch>
            </div>
          )}
          {getRoute() ? <Footer fluid /> : null}
          <MainNotification />
        </MainNotificationProvider>
      </div>
    </div>
  );
}
