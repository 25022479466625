import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import LoginForm from "components/AuthForms/LoginForm";
import SendCodeForm from "components/AuthForms/SendCodeForm";
import ChangePasswordForm from "components/AuthForms/ChangePasswordForm";
import NewPasswordRequiredForm from "components/AuthForms/NewPasswordRequiredForm";
import ConfirmSignUpForm from "components/AuthForms/ConfirmSignUpForm";

// services
import AuthServices from "services/AuthServices";
import MainServices from "services/MainServices";

import { UserContext } from "providers/UserProvider";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import routes, { buildRoutes, getFirstRoute } from "../../routes";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const [action, setAction] = useState('login');
  const [email, setEmail] = useState('');
  const [user, setUser] = useState({});
  const useUserContext = React.useContext(UserContext);

  useEffect(() => {
    // verificamos si existe el user en el localStorage
    const user = AuthServices.getUser();
    const configuration = MainServices.getConfig();
    const newRoutes = configuration ? buildRoutes(configuration.menu) : routes;
    const {layout, path} = getFirstRoute(newRoutes);
    if (user && layout && path) {
      useUserContext.updateUser();
      props.history.push(`${layout}${path}`);
    }
    
  }, []);

  const classes = useStyles();

  const handlerShowForgotPassword = () => {
    setAction('forgot_password');
  };

  const handleOnSendCode = (email) => {
    setEmail(email)
    setAction('change_password');
  }

  const handleOnChangePassword = () => {
    setAction('login');
  }

  const handleNewPasswordRequired = (user) => {
    setUser(user)
    setAction('new_password_required');
  }

  const handleConfirmSignUp = (user) => {
    setUser(user)
    setAction('confirm_sign_up');
  }

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          { action === 'login' &&
          <LoginForm 
            onNewPasswordRequired={handleNewPasswordRequired} 
            onConfirmSignUp={handleConfirmSignUp}
            onForgotPassword={handlerShowForgotPassword}
          />
          }
          { action === 'forgot_password' && 
          <SendCodeForm onSend={handleOnSendCode} onBack={handleOnChangePassword} />
          }
          { action === 'change_password' &&
          <ChangePasswordForm onSend={handleOnChangePassword} email={email} />
          }
          { action === 'new_password_required' &&
          <NewPasswordRequiredForm user={user} />
          }
          { action === 'confirm_sign_up' &&
          <ConfirmSignUpForm data={user} />
          }
        </GridItem>
      </GridContainer>
    </div>
  );
}
