import React, { useState, useReducer, useEffect } from "react";
import { strings as translate } from "locale";
import { withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import FormHelperText from "@material-ui/core/FormHelperText";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Email from "@material-ui/icons/Email";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import GridItem from "components/Grid/GridItem";

// services
import { Auth } from "aws-amplify";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

function emailValidator(email) {
  const re = /\S+@\S+\.\S+/;
  if (!email) return translate.email_emtpy;
  if (!re.test(email)) return translate.email_error;
  return true;
}

const useStyles = makeStyles(styles);

const ConfirmSignUpForm = (props) => {
  const { data } = props;
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [inputValues, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { confirmation_code: "", email: "" }
  );
  const [apiError, setApiError] = useState("");
  const [apiMessage, setApiMessage] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailMessageError, setEmailMessageError] = useState("");

  const classes = useStyles();

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  useEffect(() => {
    if (data !== null && data !== undefined) {
      console.log(data);
      setInputValues({ email: data.user.username });
    }
  }, [data]);

  const setInput = (e) => {
    const { name, value } = e.target;
    setInputValues({ [name]: value });
  };

  const confirmCode = async (e) => {
    e.preventDefault();
    setEmailError(false);
    setEmailMessageError("");

    if (!inputValues.confirmation_code.trim() || !inputValues.email.trim()) {
      setApiError(translate.all_fields_required);
      return;
    }
    const emailValid = emailValidator(inputValues.email.trim());
    if (emailValid !== true) {
      setEmailError(true);
      setEmailMessageError(emailValid);
    }

    setApiError("");
    setApiMessage("");
    try {
      const res = await Auth.confirmSignUp(
        inputValues.email,
        inputValues.confirmation_code
      );
      console.log(res);
      if (res) {
        setTimeout(() => {
          if (props.location.pathname === "/auth/login") {
            window.location.reload();
          } else {
            props.history.push("/auth/login");
          }
        }, 500);
      } else {
        setApiError("Error de inicio de sesión");
      }
    } catch (error) {
      console.log(error);
      setApiError(error.message);
    }
  };

  const handlerResendCode = async () => {
    try {
      await Auth.resendSignUp(inputValues.email);
      setApiMessage(translate["Code resent successfully"]);
    } catch (err) {
      setApiError("error resending code: ", err);
    }
  };

  return (
    <>
      <form onSubmit={confirmCode}>
        <Card login className={classes[cardAnimaton]}>
          <CardHeader
            className={`${classes.cardHeader} ${classes.textCenter}`}
            color="primary"
          >
            <h4 className={classes.cardTitle}>{translate.confirm_sign_up}</h4>
          </CardHeader>
          <CardBody>
            <p className={classes.cardTextIntro}>
              {translate.formatString(
                translate.title_confirm_code,
                inputValues.email,
                <br />,
                <Button
                  simple
                  size="sm"
                  color="primary"
                  onClick={handlerResendCode}
                >
                  {translate.here}
                </Button>
              )}
            </p>
            <CustomInput
              labelText={translate.email}
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              value={inputValues.email}
              inputProps={{
                onChange: setInput,
                name: "email",
                value: inputValues.email,
                disabled: true,
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              }
              error={emailError}
              helperText={emailMessageError}
            />
            <CustomInput
              labelText={translate.confirmation_code}
              id="confirmation_code"
              formControlProps={{
                fullWidth: true,
              }}
              value={inputValues.confirmation_code}
              inputProps={{
                onChange: setInput,
                name: "confirmation_code",
                autoComplete: "off",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <VerifiedUserIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              }
            />
            {apiError && <FormHelperText error>{apiError}</FormHelperText>}
            {apiMessage && <FormHelperText>{apiMessage}</FormHelperText>}
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button color="primary" size="lg" block type="submit">
              {translate.confirm_button_text}
            </Button>
          </CardFooter>
        </Card>
      </form>

      <GridItem container xs={12} justify="center" alignItems="center">
        {translate.lost_your_code}{" "}
        <Button
          simple
          size="sm"
          type="button"
          color="info"
          onClick={handlerResendCode}
        >
          {translate.resend_code}
        </Button>
      </GridItem>
    </>
  );
};

export default withRouter(ConfirmSignUpForm);
