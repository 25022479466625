import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { strings as translate } from "../../locale";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Button from "../../components/CustomButtons/Button";
import useMainNotification from "../../hooks/useMainNotification";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { setIn } from "formik";

// services
import MainServices from "../../services/MainServices";

// styles
import styles from "./styles";
import TournamentServices from "services/TournamentServices";

const useStyles = makeStyles(styles);

function TeamsForm(props) {
  const { onSave, onSaveError, onCancel, editForm } = props;
  const classes = useStyles();
  const [data, setData] = useState(() => []);
  const [loaded, setLoaded] = useState(false);
  const [tournamentId, setTournamentId] = useState(null);
  const { addSuccess, addError } = useMainNotification();

  const [columns, setColumns] = useState({
    allTeams: {
      alias: "allTeams",
      teams: [],
    },
    addTeams: {
      alias: "addTeams",
      teams: [],
    },
  });

  useEffect(() => {
    const localValues = JSON.parse(localStorage.getItem("values"));
    if (
      localValues !== null &&
      localValues.tournament !== undefined &&
      localValues.tournament.id_tournament !== undefined
    ) {
      setTournamentId(localValues.tournament.id_tournament);
    }
  }, []);

  useEffect(() => {
    setLoaded(true);
    if (
      props.data !== null &&
      props.data.allTeams !== undefined &&
      !props.data.addTeams !== undefined
    ) {
      console.log("props.data", props.data);
      setTournamentId(props.data.id_tournament);
      setColumns((v) => ({
        ...v,
        allTeams: {
          alias: "allTeams",
          teams: props.data.allTeams,
        },
        addTeams: {
          alias: "addTeams",
          teams: props.data.addTeams,
        },
      }));
    }
  }, [props.data]);

  const handleFormSubmit = async () => {
    console.log(columns);
    const localValues = JSON.parse(localStorage.getItem("values"));
    let res = null;
    let data = null;
    let dataValues = null;
    try {
      if (!editForm && localValues.zones?.status === undefined) {
        data = {
          id_tournament: localValues.tournament.id_tournament,
          teams: columns.addTeams.teams.map((i) => i.id),
        };

        dataValues = {
          id_tournament: localValues.tournament.id_tournament,
          teams: columns.addTeams.teams,
        };

        console.log(data);
        res = await TournamentServices.addTournamentTeams(data);
      } else {
        data = {
          id_tournament: tournamentId,
          remainingTeams: columns.allTeams.teams
            .filter((i) => i.id_tt !== undefined)
            .map((i) => ({
              id_tt: i.id_tt,
              id_team: i.id,
              id_tournament: tournamentId,
            })),
          teams: columns.addTeams.teams.map((i) => ({
            ...(i.id_tt && { id_tt: i.id_tt }),
            id_team: i.id,
            id_tournament: tournamentId,
          })),
        };
        dataValues = {
          id_tournament: tournamentId,
          remainingTeams: columns.allTeams.teams.filter(
            (i) => i.id_tt !== undefined
          ),
          teams: columns.addTeams.teams,
        };

        res = await TournamentServices.updateTournamentTeams(data);
      }
      console.log(res);

      if (!res.error || !res.data.error) {
        addSuccess(
          !editForm
            ? `${translate.teams} ${translate.has_been_added_successfully}`
            : `${translate.teams} ${translate.has_been_edited_successfully}`
        );
      }

      const values = {
        ...dataValues,
        ...res.data.teams,
      };

      if (onSave) {
        onSave({
          ...values,
        });
      }
    } catch (e) {
      const data = e.response ? e.response.data : {};
      let errors = {};

      Object.keys(data).forEach((item) => {
        errors = setIn(errors, item, data[item][0]);
      });

      handleSentError(e.response);
      if (e.response && e.response.data && e.response.data.error) {
        addError(e.response.data.error_msg, null, { messageLength: null });
      } else if (e.message) {
        addError(e.message, null, { messageLength: null });
      }
      MainServices.cancel();
    }
  };

  const handleSentError = (values) => {
    if (onSaveError) {
      onSaveError(values);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.teams];
      const destItems = [...destColumn.teams];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          teams: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          teams: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.teams];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          teams: copiedItems,
        },
      });
    }
  };

  const handleNext = () => {
    if (onSave) {
      const id =
        data.id_tournament !== undefined ? data.id_tournament : tournamentId;
      onSave({
        id_tournament: id,
        teams: columns.addTeams.teams,
      });
    }
  };

  return (
    <>
      <LoadingIndicator
        type="TailSpin"
        color="#2e82ef"
        height={50}
        width={50}
      />

      <div className={classes.root}>
        {loaded && (
          <>
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
            >
              <GridContainer alignItems="stretch">
                <GridItem xs={12} sm={4}>
                  <p>{translate.teams_in_the_league}</p>
                  <div>
                    <Droppable droppableId="allTeams" key="allTeams">
                      {(provided, snapshot) => (
                        <div
                          id= "allTeams"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? "lightblue"
                              : "#DDDDDD",
                          }}
                          className={classes.droppableContainer}
                        >
                          {columns.allTeams &&
                            columns.allTeams.teams &&
                            columns.allTeams.teams.map((item, index) => (
                              <Draggable
                                key={item.id.toString()}
                                draggableId={item.id.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    id =  {`team_${item.id}_all`}
                                    key={"div" + item.id.toString()}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      backgroundColor: snapshot.isDragging
                                        ? "#5D83E0"
                                        : "#FFFFFF",
                                      ...provided.draggableProps.style,
                                    }}
                                    className={classes.draggableTeamItem}
                                  >
                                    <div
                                      className={classes.draggableTeamAvatar}
                                      style={{
                                        backgroundImage:
                                          "url(" + item.avatar + ")",
                                      }}
                                    ></div>{" "}
                                    <div className={classes.draggableTeamName}>
                                      {item.name}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={8}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <p>{translate.add_teams_to_the_tournament}</p>
                      <div>
                        <Droppable droppableId="addTeams" key="addTeams">
                          {(provided, snapshot) => (
                            <div
                              id="addTeams"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver
                                  ? "lightblue"
                                  : "#DDDDDD",
                              }}
                              className={classes.droppableContainer}
                            >
                              {columns.addTeams &&
                                columns.addTeams.teams &&
                                columns.addTeams.teams.map((item, index) => (
                                  <Draggable
                                    key={item.id.toString()}
                                    draggableId={item.id.toString()}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        id = {`team_${item.id}_add`}
                                        key={"div" + item.id.toString()}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          backgroundColor: snapshot.isDragging
                                            ? "#5D83E0"
                                            : "#FFFFFF",
                                          ...provided.draggableProps.style,
                                        }}
                                        className={classes.draggableTeamItem}
                                      >
                                        <div
                                          className={
                                            classes.draggableTeamAvatar
                                          }
                                          style={{
                                            backgroundImage:
                                              "url(" + item.avatar + ")",
                                          }}
                                        ></div>{" "}
                                        <div
                                          className={classes.draggableTeamName}
                                        >
                                          {item.name}
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        <div>
                          <p>
                            <small>
                              {
                                translate.drag_teams_from_your_league_to_add_them_to_the_tournament
                              }
                            </small>
                          </p>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </DragDropContext>
            <GridContainer>
              <GridItem xs={12}>
                <div className={classes.formCategory}>
                  <small>*</small> {translate.required_fields}
                </div>
              </GridItem>
              <GridItem xs={12} container justify="center">
                <div className={classes.formButtonWithLoader}>
                  <LoadingIndicator
                    type="TailSpin"
                    color="#2e82ef"
                    height={30}
                    width={30}
                    area="save-button"
                  />
                  {editForm && (
                    <>
                      <Button
                        id = "btnBack"
                        type="submit"
                        onClick={handleCancel}
                        style={{ marginRight: "24px" }}
                      >
                        {translate.back}
                      </Button>
                      <Button
                        id = "btnSave"
                        type="submit"
                        color="success"
                        disabled={
                          columns.addTeams.teams !== undefined &&
                          columns.addTeams.teams.length <= 0
                        }
                        onClick={handleFormSubmit}
                        style={{ marginRight: "24px" }}
                      >
                        {translate.save}
                      </Button>
                      <Button
                        id = "btnNext"
                        type="button"
                        color="primary"
                        onClick={handleNext}
                      >
                        {translate.next}
                      </Button>
                    </>
                  )}
                  {!editForm && (
                    <Button
                      id = "btnNext"
                      type="submit"
                      color="primary"
                      disabled={
                        columns.addTeams.teams !== undefined &&
                        columns.addTeams.teams.length <= 0
                      }
                      onClick={handleFormSubmit}
                    >
                      {translate.next}
                    </Button>
                  )}
                </div>
              </GridItem>
            </GridContainer>
          </>
        )}
      </div>
    </>
  );
}

export default withRouter(TeamsForm);
