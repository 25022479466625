import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  en: {
    login: "Log in",
    logout: "Log out",
    register: "Register",
    first_name: "First name",
    last_name: "Last name",
    profile: "Profile",
    settings: "Settings",
    email: "E-mail",
    email_empty: "Email can't be empty.",
    email_error: "Enter a valid email address.",
    username: "Username",
    password: "Password",
    login_button_text: "Let´s go",
    incorrect_username_or_password: "Incorrect username or password.",
    incorrect_email: "Incorrect e-mail.",
    send_code: "Send code",
    forgot_your_password: "Forgot your password?",
    reset_your_password: "Reset your password",
    verification_code: "Verification code",
    new_password: "New password",
    repeat_password: "Repeat password",
    passwords_do_not_match: "Passwords do not match",
    change_password: "Change password",
    send: "Send",
    all_fields_required: "All fields required",
    list: "List",
    add: "Add",
    edit: "Edit",
    delete: "Delete",
    save: "Save",
    ok: "Ok",
    cancel: "Cancel",
    back: "Back",
    autogenerate_table_error: "The table could not be created.{0}Server error.",
    autogenerate_form_error: "The form could not be created.{0}Server error.",
    required_fields: "Required fields",
    upload_file: "Upload file",
    upload_image: "Upload image",
    select_file: "Select file",
    select_image: "Select image",
    yes: "Yes",
    no: "No",
    see_current_image: "See current image",
    loading: "Loading",
    confirm_delete_title: "Delete item",
    confirm_delete_text: 'Are you sure deleting item "{0}"?',
    empty_table: "Empty table",
    search_x_records: "Search {0} records",
    x_records: "{0} records",
    search: "Search",
    actions: "Actions",
    previous: "Previous",
    next: "Next",
    rows: "Rows",
    page: "Page",
    create_payment_link: "Create payment link",
    reset: "Reset",
    all_fields_are_required: "All fields are required",
    "Username cannot be empty": "Username cannot be empty",
    "Password cannot be empty": "Password cannot be empty",
    "Password did not conform with policy: Password not long enough":
      "Password did not conform with policy: Password not long enough",
    "Password did not conform with policy: Password must have uppercase characters":
      "Password did not conform with policy: Password must have uppercase characters",
    "Password did not conform with policy: Password must have lowercase characters":
      "Password did not conform with policy: Password must have lowercase characters",
    "Password did not conform with policy: Password must have numeric characters":
      "Password did not conform with policy: Password must have numeric characters",
    "Password did not conform with policy: Password must have symbol characters":
      "Password did not conform with policy: Password must have symbol characters",
    "User already exists": "User already exists",
    did_you_confirm_your_account: "Did you confirm your account?",
    title_confirm_code:
      "We have sent you the confirmation code to the email {0}.{1}If it did not get in your inbox, check the spam.{1}We can resend the code by pressing {2}",
    here: "here",
    category: "Category",
    category_name: "Category name",
    tournament: "Tournament",
    tournament_name: "Tournament name",
    active: "Active",
    friendly: "Friendly",
    allow_new_players: "Allow new players",
    league: "League",
    league_name: "League name",
    points_modality: "Points modality",
    number_of_groups: "No. of groups",
    group: "Group",
    groups: "Groups",
    team: "Team",
    teams: "Teams",
    the_match: "The match",
    match: "Match",
    matches: "Matches",
    number_teams_group: "No. of teams by group",
    all_steps_completed_you_and_apos_re_finished:
      "All steps completed - you&apos;re finished",
    number_of_teams: "Number of teams",
    teams_in_the_league: "Teams in the league",
    tournament_teams: "Category Teams",
    group_teams: "Group teams",
    group_name: "Group name",
    add_teams_to_the_tournament: "Add teams to the category",
    drag_teams_from_your_league_to_add_them_to_the_tournament:
      "Drag teams from your tournament to add them to the category",
    has_been_added_successfully: "has been added successfully",
    has_been_edited_successfully: "has been edited successfully",
    has_been_deleted_successfully: "has been deleted successfully",
    phases: "Phases",
    phase: "Phase",
    phase_team_1: "Phase of Team 1",
    phase_team_2: "Phase of Team 2",
    rounds: "Rounds",
    round: "Round",
    matches_round: "Matches round",
    match_round: "Round #",
    number_of_rounds: "No. of rounds",
    number_of_matches_by_rounds: "No. of matches by rounds",
    number_of_matches: "No. of matches",
    subleague: "Sub-league",
    round_of_64: "Round of 64",
    round_of_32: "Round of 32",
    round_of_16: "Round of 16",
    quarter_finals: "Quarter-finals",
    semifinals: "Semi-finals",
    final: "Final",
    third_and_fourth_place: "Third and fourth place",
    without_playoffs: "Without playoffs",
    finish: "Finish",
    date: "Date",
    time: "Time",
    hour: "Hour",
    minutes: "Minutes",
    minutes_abbr: "Min.",
    date_time: "Date / Time",
    football_field_number: "No. football field",
    football_field: "Location",
    home_and_away_matches: "Home and away matches",
    win_record_abbr: "W",
    draw_record_abbr: "D",
    loss_record_abbr: "L",
    add_match: "Add match",
    edit_match: "Edit match",
    back_to_sign_in_button_text: "Back to sign In",
    confirm_sign_up: "Confirm sign up",
    confirm_button_text: "Confirm",
    confirmation_code: "Confirmation code",
    download: "Download",
    interzone: "Interzone",
  },
  es: {
    login: "Iniciar sesión",
    logout: "Cerrar sesión",
    register: "Crear cuenta",
    first_name: "Nombre",
    last_name: "Apellido",
    profile: "Perfil",
    settings: "Configuraciones",
    email: "E-mail",
    email_empty: "El campo E-mail no puede estar vacío.",
    email_error: "Ingrese un E-mail válido.",
    username: "Nombre de usuario",
    password: "Contraseña",
    login_button_text: "Ingresar",
    incorrect_username_or_password:
      "Nombre de usuario o contraseña incorrecta.",
    incorrect_email: "El e-mail es incorrecto.",
    send_code: "Enviar código",
    forgot_your_password: "¿Olvidó su contraseña?",
    reset_your_password: "Resetear la contraseña",
    verification_code: "Código de verificación",
    new_password: "Nueva contraseña",
    repeat_password: "Repita contraseña",
    passwords_do_not_match: "Las contaseñas no coinciden",
    change_password: "Cambiar contraseña",
    send: "Enviar",
    all_fields_required: "Todos los campos son requeridos",
    list: "Listado",
    add: "Agregar",
    edit: "Editar",
    delete: "Borrar",
    save: "Guardar",
    ok: "Aceptar",
    cancel: "Cancelar",
    back: "Volver",
    autogenerate_table_error:
      "No se puede generar la tabla.{0}Error en el servidor.",
    autogenerate_form_error:
      "No se puede generar el formulario.{0}Error en el servidor.",
    required_fields: "Datos requeridos",
    upload_file: "Cargar archivo",
    upload_image: "Cargar imagen",
    select_file: "Selecciona un archivo",
    select_image: "Selecciona una imagen",
    yes: "Si",
    no: "No",
    see_current_image: "Ver imagen actual",
    loading: "Cargando",
    confirm_delete_title: "Borrar registro",
    confirm_delete_text: '¿Seguro que quieres de borrar el registro "{0}"?',
    empty_table: "No hay registros",
    search_x_records: "Buscar {0} registros",
    x_records: "{0} registros",
    search: "Buscar",
    actions: "Acciones",
    previous: "Anterior",
    next: "Siguiente",
    rows: "Filas",
    page: "Página",
    close: "Cerrar",
    create_payment_link: "Crear link de pago",
    reset: "Resetear",
    all_fields_are_required: "Todos los campos del formulario son requeridos",
    "Username cannot be empty": "El Nombre de usuario no puede estar vacío",
    "Password cannot be empty": "La Contraseña no puede estar vacía",
    "Password did not conform with policy: Password not long enough":
      "La Contraseña debe debe tener al menos 8 caracteres",
    "Password did not conform with policy: Password must have uppercase characters":
      "La Contraseña debe tener caracteres en mayúsculas",
    "Password did not conform with policy: Password must have lowercase characters":
      "La Contraseña debe tener caracteres en minúsculas",
    "Password did not conform with policy: Password must have numeric characters":
      "La Contraseña debe tener caracteres numéricos",
    "Password did not conform with policy: Password must have symbol characters":
      "La Contraseña debe tener caracteres símbolos",
    "User already exists": "El email ya fue registrado.",
    did_you_confirm_your_account: "¿Confirmó su cuenta?",
    title_confirm_code:
      "Te enviamos el código de confirmación al email {0}.{1}Si no llegó a tu bandeja de entrada, revisa los correos no deseados.{1}Podemos reenviarte el código presionando {2}",
    here: "aquí",
    category: "Categoría",
    category_name: "Nombre de categoría",
    tournament: "Torneo",
    tournament_name: "Nombre del torneo",
    active: "Activo",
    friendly: "Amistoso",
    allow_new_players: "Adicionar participantes",
    league: "Liga",
    league_name: "Nombre de liga",
    points_modality: "Sistema de puntos",
    number_of_groups: "Nº de grupos",
    group: "Grupo",
    groups: "Grupos",
    team: "Equipo",
    teams: "Equipos",
    the_match: "El partido",
    match: "Partido",
    matches: "Partidos",
    number_teams_group: "Cant. Equipos Zona",
    all_steps_completed_you_and_apos_re_finished:
      "Se completaron todos los pasos - finalizaste",
    number_of_teams: "Cantidad de equipos",
    teams_in_the_league: "Equipos del torneo",
    tournament_teams: "Equipos de la categoría",
    group_teams: "Equipos zona",
    group_name: "Nombre zona",
    add_teams_to_the_tournament: "Agregar equipos a la categoría",
    drag_teams_from_your_league_to_add_them_to_the_tournament:
      "Arrastra equipos de tu torneo para agregarlos a la categoría",
    has_been_added_successfully: "ha sido agregado exitosamente",
    has_been_edited_successfully: "ha sido editado exitosamente",
    has_been_deleted_successfully: "ha sido borrado exitosamente",
    phases: "Fases",
    phase: "Fase",
    phase_team_1: "Fase del Equipo 1",
    phase_team_2: "Fase del Equipo 2",
    rounds: "Fechas",
    round: "Fecha",
    matches_round: "Partidos de la fecha",
    match_round: "Fecha Nº",
    number_of_rounds: "Cant. de fechas",
    number_of_matches_by_rounds: "Partidos por fecha",
    number_of_matches: "Cant. de partidos",
    subleague: "Liguilla",
    round_of_64: "Treintaidosavos de final",
    round_of_32: "Dieciseisavos de final",
    round_of_16: "Octavos de final",
    quarter_finals: "Cuartos de final",
    semifinals: "Semifinal",
    final: "Final",
    third_and_fourth_place: "3er y 4to puesto",
    without_playoffs: "Sin fase definitoria",
    finish: "Finalizar",
    date: "Fecha",
    time: "Hora",
    hour: "Hora",
    minutes: "Minutos",
    minutes_abbr: "Min.",
    date_time: "Fecha / Hora",
    football_field_number: "Nº de cancha",
    football_field: "Cancha",
    home_and_away_matches: "Partidos ida y vuelta",
    win_record_abbr: "PG",
    draw_record_abbr: "PE",
    loss_record_abbr: "PP",
    add_match: "Agregar partido",
    edit_match: "Editar partido",
    back_to_sign_in_button_text: "Iniciar sesión",
    confirm_sign_up: "Confirmar registro",
    confirm_button_text: "Confirmar",
    confirmation_code: "Código de confirmación",
    download: "Descargar",
    interzone: "Interzonal",
  },
});

const getBrowserLanguage = () => {
  let lang = null;
  if (navigator.languages && navigator.languages.length) {
    lang = navigator.languages[0];
  } else {
    lang =
      navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      "en";
  }
  return lang.match(/(\w+)/)[0];
};

const get_language = strings.getLanguage();
const set_language = (lang) => {
  strings.setLanguage(lang);
};
const interface_lang = strings.getInterfaceLanguage();
const browser_language = getBrowserLanguage();
const set_browser_language = () => {
  set_language(browser_language);
};

set_language(browser_language);
export {
  strings,
  get_language,
  set_language,
  interface_lang,
  browser_language,
  set_browser_language,
};
