import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import { strings as translate } from "locale";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import ClearIcon from "@material-ui/icons/Clear";

import styles from "assets/jss/material-dashboard-pro-react/components/fileUploadStyle.js";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles(styles);

function FileUpload(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    buttonText,
    id,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
    accept,
    onChange,
    onBlur,
  } = props;
  const inputFile = React.useRef(null);
  const inputFileText = React.useRef(null);
  const [inputValue, setInputValue] = React.useState("");
  const [clearFile, setClearFile] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [preview, setPreview] = React.useState(false);
  const [isTouch, setIsTouch] = React.useState(false);
  const [isSelecting, setIsSelecting] = React.useState(false);
  const [isFocus, setIsFocus] = React.useState(false);

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const inputRootClasses = classNames({
    [classes.inputRoot]: true,
    [classes.cursorPointer]: true,
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.cursorPointer]: true,
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  let formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  const helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });

  React.useEffect(() => {
    if (
      inputProps.value &&
      typeof inputProps.value === "string" &&
      !inputProps.value.match(/base64/gi)
    ) {
      setPreview(true);
      setInputValue(inputProps.value);
      setClearFile(true);
    }
  }, [inputProps]);

  const onButtonClick = (e) => {
    e.preventDefault();
    inputFileText.current.focus();
    inputFile.current.click();
  };

  const onInputClick = (e) => {
    e.preventDefault();
    inputFile.current.click();
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        setLoading(false);
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleOnChange = async (e) => {
    const { name, value, files } = e.target;
    setPreview(false);
    setLoading(true);
    let newValue = value.split("\\").pop();
    if (isImage(value)) {
      newValue = await convertBase64(files[0]);
    }
    setInputValue(newValue);
    setClearFile(true);
    if (onChange) {
      let file = files[0];
      let fileBase64 = "";
      if (file) {
        fileBase64 = await convertBase64(file);
      }

      if (typeof onChange === "function") {
        onChange({ target: { name: name, value: fileBase64 } });
      }
    }
  };

  const handleOnFocus = (e) => {
    if (isTouch === false) {
      setIsTouch(true);
    }
    if (isSelecting === true) {
      setIsFocus(true);
    }
  };

  const handleOnBlur = (e) => {
    if (isSelecting === false) {
      setIsSelecting(true);
    }
    if (isFocus === true) {
      setIsTouch(false);
      setIsSelecting(false);
      setIsFocus(false);
      if (typeof onBlur === "function") {
        const { name, value } = inputFile.current;
        onBlur({ target: { name, value } });
      }
    }
  };

  const reset = () => {
    setPreview(false);
    setClearFile(false);
    setInputValue("");
    inputFile.current.value = "";
    onChange({ target: { name: props.name ? props.name : "file", value: "" } });
  };

  const handleClickClear = (event) => {
    event.preventDefault();
    reset();
  };

  const handleMouseDownClear = (event) => {
    event.preventDefault();
    reset();
  };

  const isImage = (value) => {
    let ext = null;
    if (value.match(/^data:image/)) {
      return true;
    }
    ext = value.match(/.+\.(jpeg|jpg|png|gif)(\?.+)?/);
    return ext !== null;
  };
  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      <InputLabel className={classes.labelRoot + " " + labelClasses} shrink>
        {labelText}
      </InputLabel>
      <input
        id={id}
        name={props.name ? props.name : "file"}
        type="file"
        style={{ display: "none" }}
        accept={accept}
        ref={inputFile}
        onChange={handleOnChange}
      />
      {isImage(inputValue) ? (
        <>
          <div style={{ position: "relative", marginRight: "58px" }}>
            <img src={inputValue} height="80" alt="Preview" />
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickClear}
              onMouseDown={handleMouseDownClear}
              className={classes.clearButton}
              style={{ position: "absolute" }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          <Input
            inputProps={{
              ...inputProps,
              value: inputValue,
              ref: inputFileText,
              type: "hidden",
            }}
          />
        </>
      ) : (
        <Input
          classes={{
            input: inputClasses,
            root: inputRootClasses,
            disabled: classes.disabled,
            underline: underlineClasses,
          }}
          inputProps={{
            ...inputProps,
            value: inputValue,
            ref: inputFileText,
          }}
          onClick={(e) => onInputClick(e)}
          onFocus={(e) => handleOnFocus(e)}
          onBlur={(e) => handleOnBlur(e)}
          endAdornment={
            clearFile ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickClear}
                  onMouseDown={handleMouseDownClear}
                  className={classes.clearButton}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : null
          }
        />
      )}
      <label htmlFor={id} className={classes.label}>
        <Button
          id={props.id}
          variant="contained"
          color="primary"
          component="span"
          type="button"
          onClick={(e) => onButtonClick(e)}
          disabled={loading}
        >
          {buttonText ? buttonText : translate.upload_file}
        </Button>
      </label>

      {preview && (
        <>
          <div className={classes.flexBreak}></div>
          <FormHelperText id={"preview-link"} className={helpTextClasses}>
            {!isImage(inputValue) && (
              <a href={inputValue} target="_blank" rel="noopener noreferrer">
                {translate.see_current_image}
              </a>
            )}
          </FormHelperText>
        </>
      )}

      {helperText !== undefined ? (
        <>
          <div className={classes.flexBreak}></div>
          <FormHelperText id={id + "-text"} className={helpTextClasses}>
            {helperText}
          </FormHelperText>
        </>
      ) : null}
    </FormControl>
  );
}

FileUpload.propTypes = {
  labelText: PropTypes.node,
  buttonText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  accept: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default FileUpload;
