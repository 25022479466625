import React, { useContext, useState, useEffect } from "react";
import { useParams, withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { strings as translate } from "../../locale";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CardBody from "../../components/Card/CardBody";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Form from "../../components/CustomForms/Form";
import Button from "../../components/CustomButtons/Button";
import useMainNotification from "../../hooks/useMainNotification";
import { FormControlLabel } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Formik, setIn } from "formik";
import { get_language } from "locale";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputForm from "components/CustomForms/InputForm";
import { getYupSchemaFromMetaData } from "../../components/CustomForms/yupSchemaCreator";

import moment from "moment";

// services
import MainServices from "../../services/MainServices";

// contexts
import { GenericPageContext } from "../../providers/GenericPageProvider";

// styles
import styles from "./styles";
import TournamentServices from "services/TournamentServices";

const useStyles = makeStyles(styles);

function ZonesForm(props) {
  const { onSave, onSaveError, onCancel, editForm } = props;
  const config = useContext(GenericPageContext);
  const params = useParams();
  const classes = useStyles();
  const [data, setData] = useState(() => []);
  const [loaded, setLoaded] = useState(false);
  const [fields, setFields] = useState([]);
  const [requiredFields, setRequiredFields] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [numberOfZones, setNumberOfZones] = useState(1);
  const [hasLocalData, setHasLocalData] = useState(false);
  const { addSuccess, addError } = useMainNotification();
  const [tournamentId, setTournamentId] = useState(null);

  const [defaultZonesNames, setDefaultZonesNames] = useState("ABCDEFGHIJKLMN");

  const [columns, setColumns] = useState({
    allTeams: {
      alias: "allTeams",
      teams: [],
    },
    teams1: {
      alias: "Teams1",
      teams: [],
    },
  });

  useEffect(() => {
    const localValues = JSON.parse(localStorage.getItem("values"));
    if (
      localValues !== null &&
      localValues.zones !== undefined &&
      localValues.zones.number_of_zones
    ) {
      setTournamentId(localValues.tournament.id);
      setHasLocalData(true);
    }
  }, []);

  useEffect(() => {
    const localValues = JSON.parse(localStorage.getItem("values"));
    async function loadMetaData() {
      try {
        /* let teams = [...res.data.teams];
        console.log("teams", teams); */
        let ids = [];
        if (
          localValues !== null &&
          localValues.zones !== undefined &&
          localValues.zones.number_of_zones
        ) {
          console.log("useEffect 1");
          setNumberOfZones(localValues.zones.number_of_zones);
          setColumns(localValues.zones.columns);
          Object.keys(localValues.zones.columns).forEach((k) => {
            const col = localValues.zones.columns[k];

            if (k !== "allTeams") {
              col.teams.forEach((item) => {
                ids.push(item.id);
              });
            }
          });
        } else if (
          localValues !== null &&
          localValues.teams &&
          localValues.teams.teams.length &&
          props.data === undefined
        ) {
          console.log("useEffect 2");
          setColumns({
            allTeams: {
              alias: "allTeams",
              teams: localValues.teams.teams,
            },
            teams1: {
              alias: "Teams1",
              teams: [],
            },
          });
        } else if (
          props.data !== null &&
          props.data !== undefined &&
          props.data.id_tournament !== undefined
        ) {
          console.log("useEffect 4");
          setTournamentId(props.data.id_tournament);
          const res = await TournamentServices.getTournamentTeams(
            props.data.id_tournament
          );
          let ids = [];
          let teams = res.data.teams.filter((i) => {
            if (!ids.includes(i.id_team)) {
              ids.push(i.id_team);
              return true;
            }
            return false;
          });
          teams = teams.map((i) => ({
            id: i.id_team,
            name: i.name,
            avatar: i.avatar,
          }));

          setNumberOfZones(props.data.values.zones.number_of_zones);
          setColumns(props.data.values.zones.columns);
        }
        setData({
          metadata: [
            {
              accessor: "id",
              header: "Id",
              editable: false,
              type: "number",
            },
            {
              accessor: "number_of_zones",
              header: "Cantidad de zonas",
              editable: true,
              required: true,
              type: "number",
              validationType: "number",
              validations: [
                {
                  type: "required",
                  params: ["Campo requerido"],
                },
                {
                  type: "min",
                  params: [1, "El número no puede ser menor a 1"],
                },
                {
                  type: "max",
                  params: [10, "El número no puede ser mayor a 10"],
                },
              ],
            },
          ],
        });
      } catch (e) {
        if (e.response && e.response.data && e.response.data.error) {
          addError("ZoneForm error: " + e.response.data.error_msg, null, {
            messageLength: null,
          });
        } else if (e.message) {
          addError("ZoneForm error: " + e.message, null, {
            messageLength: null,
          });
        } else {
          console.log(e);
        }
      } finally {
        setLoaded(true);
      }
    }
    trackPromise(loadMetaData());

    return () => {
      MainServices.cancel();
    };
  }, [addError, props.data]);

  useEffect(() => {
    if (!data.metadata) return;

    // filtarmos los campos utiles
    const fields = data.metadata.filter(
      (item) =>
        ((!editForm && item.accessor !== "id") || editForm) &&
        item.accessor !== "actions"
    );
    setFields(fields);

    // establecemos los valores iniciales, para la edición hay que obtenerlos del data.data
    const getInitialValues = () => {
      const values = {};
      const fData =
        data && data[0]
          ? data[0]
          : data && data.global_action && data.global_action.data
          ? data.global_action.data
          : data;

      let defaultValues = {
        number_of_zones: 1,
        zone_name_1: "Grupo " + defaultZonesNames[0],
      };
      const localValues = JSON.parse(localStorage.getItem("values"));
      let ids = [];
      if (
        localValues !== null &&
        localValues.zones !== undefined &&
        localValues.zones.number_of_zones
      ) {
        let _zones = { ...localValues.zones };
        delete _zones.columns;
        defaultValues = { ...defaultValues, ..._zones };
      }

      if (editForm) {
        fields.forEach((item) => {
          const selectValue =
            fData[item.accessor] && fData[item.accessor].id
              ? fData[item.accessor].id
              : fData[item.accessor];
          Object.assign(values, {
            [item.accessor]:
              item.type === "select" ? selectValue : fData[item.accessor],
          });
        });
      }
      Object.assign(values, defaultValues);
      return values;
    };

    setInitialValues(getInitialValues());

    // establecemos las validaciones
    setValidationSchema(getYupSchemaFromMetaData(data.metadata, [], []));

    // buscamos campos requeridos
    const hasRequiredFields = () => {
      let required = false;
      fields.forEach((item) => {
        if (!item.validations) {
          return;
        }
        item.validations.forEach((v) => {
          if (v.type === "required") {
            required = true;
          }
        });
      });
      return required;
    };
    setRequiredFields(hasRequiredFields());
  }, [editForm, data]);

  useEffect(() => {
    console.log("props.data", props.data);
    if (
      props.data !== null &&
      props.data !== undefined &&
      props.data.teams !== undefined
    ) {
      console.log("columns", props.data.values.zones);
      setNumberOfZones(props.data.values.zones.number_of_zones);
      setColumns(props.data.values.zones.columns);
    }
  }, [props.data]);

  const handleFormSubmit = async (values, actions) => {
    try {
      let res = null;

      const localValues = JSON.parse(localStorage.getItem("values"));
      const newCols = { ...columns };

      if (!editForm && localValues.zones?.status === undefined) {
        const _phases = [];
        Object.keys(values).map((i) => {
          if (i !== "number_of_zones") {
            _phases.push({ name: values[i], key: "phase" });
          }
        });

        const data = {
          tournament_id: localValues.tournament.id_tournament,
          phases: _phases,
          type: "phase",
          third: null,
        };

        res = await TournamentServices.addPhasesByTournament(data);
        let newPhases = [];
        _phases.map((i, k) => {
          // buscamos en el resultado la fase mediante el nombre
          const phase = res.data.phases.filter((j) => j.name === i.name).pop();
          // buscamos en las columnas los equipos
          const ids = columns["teams" + (k + 1)].teams.map((l) => l.id);
          const newPhase = { id_phase: phase.id, teams: ids };
          newPhases.push(newPhase);
        });
        const dataTeams = {
          phases: newPhases,
        };
        res = await TournamentServices.addTeamsByPhases(dataTeams);
        res.data.phases.map((i, k) => {
          newCols["teams" + (k + 1)] = {
            ...newCols["teams" + (k + 1)],
            id_phase: i.id_phase,
          };
          newCols["teams" + (k + 1)].teams = i.teams;
        });
        setColumns(newCols);
      } else {
        const localValues = JSON.parse(localStorage.getItem("values"));

        const _phases = [];
        const _newPhases = [];
        Object.keys(values).map((i) => {
          if (i !== "number_of_zones" && i !== "status" && i !== "id") {
            if (localValues.zones.columns["teams" + i.substring(10)]) {
              const phaseId =
                localValues.zones.columns["teams" + i.substring(10)].id_phase;
              _phases.push({ id: phaseId, name: values[i], key: "phase" });
            } else {
              _newPhases.push({ name: values[i], key: "phase" });
            }
          }
        });

        const data = {
          tournament_id: tournamentId,
          phases: _phases,
          type: "phase", // ["phase"|"subleague"|"playoff"]
          third: null,
        };

        res = await TournamentServices.updatePhasesByTournament(data);
        let newPhases = [];
        _phases.map((phase, k) => {
          // buscamos en las columnas los equipos
          const ids = columns["teams" + (k + 1)].teams.map((l) => ({
            id: l.id,
            id_ptt: l.id_ptt,
          }));
          const newPhase = { id_phase: phase.id, teams: ids };
          newPhases.push(newPhase);
        });
        const dataTeams = {
          phases: newPhases,
          remainingTeams: {
            teams: columns.allTeams.teams,
          },
        };
        res = await TournamentServices.updateTeamsByPhases(dataTeams);
        res.data.phases.map((i, k) => {
          console.log("phases", k, i);
          newCols["teams" + (k + 1)] = {
            ...newCols["teams" + (k + 1)],
            id_phase: i.id_phase,
          };
          newCols["teams" + (k + 1)].teams = i.teams;
        });
        console.log(newCols);
        setColumns(newCols);
      }

      if (!res.error || !res.data.error) {
        addSuccess(
          !editForm
            ? `${translate.groups} ${translate.has_been_added_successfully}`
            : `${translate.groups} ${translate.has_been_edited_successfully}`
        );
      }

      if (onSave) {
        onSave({
          ...values,
          columns: newCols,
          status: !editForm ? "add" : "edit",
        });
      }
    } catch (e) {
      const data = e.response ? e.response.data : {};
      let errors = {};

      Object.keys(data).forEach((item) => {
        errors = setIn(errors, item, data[item][0]);
      });

      actions.setStatus(errors);

      handleSentError(e.response);
      if (e.response && e.response.data && e.response.data.error) {
        addError(e.response.data.error_msg, null, { messageLength: null });
      } else if (e.message) {
        addError(e.message, null, { messageLength: null });
      }
      MainServices.cancel();
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleSentError = (values) => {
    if (onSaveError) {
      onSaveError(values);
    }
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleChangeNumberOfZones = (formik, e) => {
    const { value } = e.target;
    if (value === "" && value > 0) {
      return;
    }
    setColumns((v) => {
      let cols = v;
      if (numberOfZones > value) {
        const teams = [
          ...cols.allTeams.teams,
          ...cols[`teams${numberOfZones}`].teams,
        ];
        cols.allTeams.teams = teams;
        delete cols[`teams${numberOfZones}`];

        return cols;
      } else {
        return {
          ...cols,
          [`teams${value}`]: { alias: `Teams${value}`, teams: [] },
        };
      }
    });

    if (numberOfZones > value) {
      delete formik[`zone_name_${value}`];
    } else {
      formik.setFieldValue(
        `zone_name_${value}`,
        `Grupo ${defaultZonesNames[value - 1]}`
      );
    }

    setNumberOfZones(value);
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.teams];
      const destItems = [...destColumn.teams];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      console.log("sourceItems", sourceItems);
      console.log("destItems", destItems);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          teams: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          teams: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.teams];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          teams: copiedItems,
        },
      });
    }
  };

  const handleNext = () => {
    let r = {};
    let newCols = {};
    Object.keys(props.data.values.zones).forEach((i) => {
      if (i !== "columns") {
        r = { ...r, [i]: props.data.values.zones[i] };
      }
    });
    Object.keys(props.data.values.zones.columns).forEach((i) => {
      if (i !== "allTeams") {
        newCols = { ...newCols, [i]: props.data.values.zones.columns[i] };
      }
    });
    if (onSave) {
      onSave({
        ...r,
        columns: newCols,
        status: !editForm ? "add" : "edit",
      });
    }
  };

  return (
    <>
      <LoadingIndicator
        type="TailSpin"
        color="#2e82ef"
        height={50}
        width={50}
      />

      <div className={classes.root}>
        {loaded && (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              trackPromise(handleFormSubmit(values, actions), "save-button");
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <DragDropContext
                  onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                >
                  <GridContainer alignItems="stretch">
                    <GridItem xs={12} sm={4}>
                      <InputForm
                        data={{
                          accessor: "number_of_zones",
                          header: "Cantidad de zonas",
                          editable: true,
                          type: "number",
                        }}
                        editForm={editForm}
                        fullData={{}}
                        idField={"id"}
                        onChange={(e) => handleChangeNumberOfZones(formik, e)}
                        inputProps={{
                          min: 1,
                          step: 1,
                        }}
                      />
                      <p>Equipos del torneo</p>
                      <div>
                        <Droppable droppableId="allTeams" key="allTeams">
                          {(provided, snapshot) => (
                            <div
                              id="allTeams"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver
                                  ? "lightblue"
                                  : "#DDDDDD",
                              }}
                              className={classes.droppableContainer}
                            >
                              {columns.allTeams &&
                                columns.allTeams.teams &&
                                columns.allTeams.teams.map((item, index) => (
                                  <Draggable
                                    key={item.id.toString()}
                                    draggableId={item.id.toString()}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        id={`team${item.id.toString()}_all`}
                                        key={"div" + item.id.toString()}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          backgroundColor: snapshot.isDragging
                                            ? "#5D83E0"
                                            : "#FFFFFF",
                                          ...provided.draggableProps.style,
                                        }}
                                        className={classes.draggableTeamItem}
                                      >
                                        <div
                                          className={
                                            classes.draggableTeamAvatar
                                          }
                                          style={{
                                            backgroundImage:
                                              "url(" + item.avatar + ")",
                                          }}
                                        ></div>{" "}
                                        <div
                                          className={classes.draggableTeamName}
                                        >
                                          {item.name}
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={8}>
                      <GridContainer>
                        {Array.from(
                          { length: numberOfZones },
                          (_, i) => i + 1
                        ).map((i, k) => {
                          return (
                            <GridItem key={k} xs={6}>
                              <InputForm
                                data={{
                                  accessor: "zone_name_" + i,
                                  header: "Nombre zona " + i,
                                  editable: true,
                                  type: "text",
                                }}
                                required={true}
                                editForm={editForm}
                                fullData={{}}
                                idField={"id"}
                              />
                              <p>Equipos zona {i}</p>
                              <div>
                                <Droppable
                                  droppableId={`teams${k + 1}`}
                                  key={`teams${k + 1}`}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      id={`teams${k + 1}`}
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                      style={{
                                        background: snapshot.isDraggingOver
                                          ? "lightblue"
                                          : "#DDDDDD",
                                        minHeight: 100,
                                      }}
                                      className={classes.droppableContainer}
                                    >
                                      {columns[`teams${k + 1}`] &&
                                        columns[`teams${k + 1}`].teams &&
                                        columns[`teams${k + 1}`].teams.map(
                                          (item, index) => (
                                            <Draggable
                                              key={item.id.toString()}
                                              draggableId={item.id.toString()}
                                              index={index}
                                            >
                                              {(provided, snapshot) => (
                                                <div
                                                  id={`team${item.id.toString()}_${
                                                    k + 1
                                                  }`}
                                                  key={
                                                    "div" + item.id.toString()
                                                  }
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={{
                                                    backgroundColor: snapshot.isDragging
                                                      ? "#5D83E0"
                                                      : "#FFFFFF",
                                                    ...provided.draggableProps
                                                      .style,
                                                  }}
                                                  className={
                                                    classes.draggableTeamItem
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      classes.draggableTeamAvatar
                                                    }
                                                    style={{
                                                      backgroundImage:
                                                        "url(" +
                                                        item.avatar +
                                                        ")",
                                                    }}
                                                  ></div>{" "}
                                                  <div
                                                    className={
                                                      classes.draggableTeamName
                                                    }
                                                  >
                                                    {item.name}
                                                  </div>
                                                </div>
                                              )}
                                            </Draggable>
                                          )
                                        )}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </div>
                            </GridItem>
                          );
                        })}
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </DragDropContext>
                <GridContainer>
                  {requiredFields && (
                    <GridItem xs={12}>
                      <div className={classes.formCategory}>
                        <small>*</small> {translate.required_fields}
                      </div>
                    </GridItem>
                  )}
                  <GridItem xs={12} container justify="center">
                    {data.metadata ? (
                      <>
                        <div className={classes.formButtonWithLoader}>
                          <LoadingIndicator
                            type="TailSpin"
                            color="#2e82ef"
                            height={30}
                            width={30}
                            area="save-button"
                          />
                          {editForm && (
                            <>
                              <Button
                                id="btnBack"
                                type="button"
                                onClick={handleCancel}
                                style={{ marginRight: "24px" }}
                              >
                                {translate.back}
                              </Button>
                              <Button
                                id="btnSave"
                                type="submit"
                                color="success"
                                disabled={
                                  (!formik.isValid ||
                                    columns.teams1.teams.length === 0 ||
                                    formik.isSubmitting) &&
                                  !hasLocalData
                                }
                                style={{ marginRight: "24px" }}
                              >
                                {translate.save}
                              </Button>
                            </>
                          )}
                          <Button
                            id="btnNext"
                            type="submit"
                            color="primary"
                            disabled={
                              (!formik.isValid ||
                                (columns.teams1 !== undefined &&
                                  columns.teams1.teams.length === 0) ||
                                formik.isSubmitting) &&
                              !hasLocalData
                            }
                          >
                            {translate.next}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <FormHelperText
                        className={
                          classes.labelRootError + " " + classes.textCenter
                        }
                      >
                        {translate.formatString(
                          translate.autogenerate_form_error,
                          <br />
                        )}
                      </FormHelperText>
                    )}
                  </GridItem>
                </GridContainer>
              </form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
}

export default withRouter(ZonesForm);
