import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { strings as translate } from "../../locale";

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CardBody from "../../components/Card/CardBody";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Button from "../../components/CustomButtons/Button";

// services
import TournamentServices from "../../services/TournamentServices";

// styles
import { Step, StepLabel, Stepper } from "@material-ui/core";
import TournamentForm from "./TournamentForm";
import TeamsForm from "./TeamsForm";
import ZonesForm from "./ZonesForm";
import MatchesForm from "./MatchesForm";
import PhasesForm from "./PhasesForm";

function Add(props) {
  const [activeStep, setActiveStep] = useState(0);
  const [localValues, setLocalValues] = useState(null);
  const [leagueTeams, setLeagueTeams] = useState(null);
  const [steps, setSteps] = useState([
    translate.category,
    translate.teams,
    translate.groups,
    translate.matches,
    translate.phases,
  ]);

  useEffect(() => {
    const step = props.location.pathname.substring(
      props.location.pathname.indexOf("step_") + 5
    );
    if (props.location.pathname.indexOf("step_") !== -1) {
      setActiveStep(parseInt(step));
    }
    trackPromise(loadLeagueTeams());
    setLocalValues(JSON.parse(localStorage.getItem("values")));
  }, []);

  const loadLeagueTeams = async () => {
    try {
      const res = await TournamentServices.getTeams();
      setLeagueTeams({
        allTeams: res.data.data.map((i) => ({
          id: i.id,
          name: i.name,
          avatar: i.avatar,
        })),
        addTeams: [],
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleNext = (_values) => {
    props.history.push({
      pathname: `/admin/tournament-steps/add/step_${activeStep + 1}`,
    });
    let newValues = null;
    if (activeStep === 0) {
      localStorage.removeItem("values");
      newValues = { tournament: _values };
    } else if (activeStep === 1) {
      newValues = { teams: _values };
    } else if (activeStep === 2) {
      newValues = { zones: _values };
    } else if (activeStep === 3) {
      newValues = { matches: _values };
    } else if (activeStep === 4) {
      newValues = { fases: _values };
    }
    const localVariables = JSON.parse(localStorage.getItem("values"));
    const savedValues = JSON.stringify({ ...localVariables, ...newValues });
    localStorage.setItem("values", savedValues);
    setLocalValues(savedValues);
    if (activeStep < 4) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      localStorage.removeItem("values");
      props.history.push(`/admin/tournament-steps`);
    }
  };

  const handleBack = () => {
    props.history.push({
      pathname:
        activeStep === 1
          ? `/admin/tournament-steps/add`
          : `/admin/tournament-steps/add/step_${activeStep - 1}`,
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <TournamentForm onSave={handleNext} />;
      case 1:
        return (
          <TeamsForm
            onSave={handleNext}
            onCancel={handleBack}
            data={leagueTeams}
          />
        );
      case 2:
        return <ZonesForm onSave={handleNext} onCancel={handleBack} />;
      case 3:
        return <MatchesForm onSave={handleNext} onCancel={handleBack} />;
      case 4:
        return <PhasesForm onSave={handleNext} onCancel={handleBack} />;
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <LoadingIndicator
        type="TailSpin"
        color="#2e82ef"
        height={50}
        width={50}
      />

      <GridContainer>
        <GridItem xs={12}>
          <Button  id = "back" color="info" onClick={() => props.history.goBack()}>
            &lt; {translate.back}
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">{translate.add}</CardIcon>
            </CardHeader>
            <CardBody>
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label} id = {label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                {activeStep < steps.length && (
                  <div>
                    {/* START Contenido */}
                    {getStepContent(activeStep)}
                    {/* END Contenido */}
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default withRouter(Add);
