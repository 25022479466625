import API from "./api.js";
import axios from "axios";
import _ from "lodash";
let cancelToken;

const TournamentServices = {
  getList: () => {
    cancelToken = axios.CancelToken.source();

    return API({
      method: "GET",
      url: `/tournament`,
      cancelToken: cancelToken.token,
    });
  },
  saveConfig: (data) => {
    const newData = Object.assign({}, data);
    delete newData.cognito;
    localStorage.setItem("config", JSON.stringify(newData));
  },
  getConfig: () => {
    const config = localStorage.getItem("config");
    return JSON.parse(config);
  },
  getMetadata: (name) => {
    // siempre apunta al endpoint viejo
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "GET",
      url: `/metadata/`,
      params: {
        model: name,
      },
      cancelToken: cancelToken.token,
    };

    return API(data);
  },
  getItem: (name, id) => {
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "GET",
      url: `/${name}/`,
      params: {
        id: id,
      },
      cancelToken: cancelToken.token,
    };

    /* let api_url = localStorage.getItem("api_url");

        if(api_url !== "") {
            data = {...data, baseURL: api_url};
        } */

    return API(data);
  },
  list: (name, args = null) => {
    if (!name) return null;
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "GET",
      url: `/${name}/`,
      cancelToken: cancelToken.token,
      params: args,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  filteredList: (name, queryString = "") => {
    if (!name) return null;
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "GET",
      url: `/${name}/${queryString}`,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  add: (name, _data) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    Object.keys(_data).forEach((item) => {
      formData.append(item, _data[item]);
    });

    let data = {
      method: "POST",
      url: `/${name}/`,
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  edit: (name, _data) => {
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "PUT",
      url: `/${name}/`,
      params: {
        id: _data.id,
      },
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  delete: (name, id) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    formData.append("id", id);

    let data = {
      method: "DELETE",
      url: `${name}/`,
      params: {
        id: id,
      },
      data: formData,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  update: (name, _data) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    Object.keys(_data).forEach((item) => {
      formData.append(item, _data[item]);
    });

    let data = {
      method: "PATCH",
      url: `${name}/`,
      params: {
        id: _data.id,
      },
      data: formData,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  getTeams: (id) => {
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "GET",
      url: `/team`,
      cancelToken: cancelToken.token,
    };

    return API(data);
  },
  addTournamentTeams: (_data) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    Object.keys(_data).forEach((item) => {
      formData.append(item, _data[item]);
    });

    let data = {
      method: "POST",
      url: `/tournament-teams`,
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
    }
    data = { ...data, baseURL: api_url };

    return API(data);
  },
  updateTournamentTeams: (_data) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    Object.keys(_data).forEach((item) => {
      formData.append(item, _data[item]);
    });

    let data = {
      method: "PUT",
      url: `/tournament-teams`,
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
    }
    data = { ...data, baseURL: api_url };

    return API(data);
  },
  getTournamentTeams: (id) => {
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "GET",
      url: `/tournament-teams?id_tournament=${id}`,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  getPhasesByTournament: (tournament_id, playered = null, key = null) => {
    cancelToken = axios.CancelToken.source();

    let args = "";
    if (playered !== null) {
      args += `&playered=${playered}`;
    }
    if (key !== null) {
      args += `&key=${key}`;
    }

    let data = {
      method: "GET",
      url: `/fase-tournament?tournament_id=${tournament_id}${args}`,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  addPhasesByTournament: (_data) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    Object.keys(_data).forEach((item) => {
      formData.append(item, _data[item]);
    });

    let data = {
      method: "POST",
      url: `/fase-tournament`,
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
    }
    data = { ...data, baseURL: api_url };

    return API(data);
  },
  updatePhasesByTournament: (_data) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    Object.keys(_data).forEach((item) => {
      formData.append(item, _data[item]);
    });

    let data = {
      method: "PUT",
      url: `/fase-tournament`,
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
    }
    data = { ...data, baseURL: api_url };

    return API(data);
  },
  getTeamsByPhases: (id) => {
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "GET",
      url: `/fase-team-tournament?phases=(${id})`,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  addTeamsByPhases: (_data) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    Object.keys(_data).forEach((item) => {
      formData.append(item, _data[item]);
    });

    let data = {
      method: "POST",
      url: `/fase-team-tournament`,
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  updateTeamsByPhases: (_data) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    Object.keys(_data).forEach((item) => {
      formData.append(item, _data[item]);
    });

    let data = {
      method: "PUT",
      url: `/fase-team-tournament`,
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  getFixtureMatches: (
    tournament_id,
    ft = null,
    match_number = null,
    key = null
  ) => {
    cancelToken = axios.CancelToken.source();

    let args = "";
    if (ft !== null) {
      args += `&ft=${ft}`;
    }
    if (match_number !== null) {
      args += `&match_number=${match_number}`;
    }
    if (key !== null) {
      args += `&key=${key}`;
    }

    let data = {
      method: "GET",
      url: `/fixture-matches?id_tournament=${tournament_id}${args}`,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  addFixtureMatches: (_data) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    Object.keys(_data).forEach((item) => {
      formData.append(item, _data[item]);
    });

    let data = {
      method: "POST",
      url: `/fixture-matches`,
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  updateFixtureMatches: (_data) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    Object.keys(_data).forEach((item) => {
      formData.append(item, _data[item]);
    });

    let data = {
      method: "PUT",
      url: `/fixture-matches`,
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  deleteFixtureMatches: (_data) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    Object.keys(_data).forEach((item) => {
      formData.append(item, _data[item]);
    });

    let data = {
      method: "DELETE",
      url: `/fixture-matches`,
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  edit: (name, _data) => {
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "PUT",
      url: `/${name}/`,
      params: {
        id: _data.id,
      },
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");

    if (api_url !== "") {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  cancel: () => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Request canceled.");
    }
  },
};
export default TournamentServices;
