import ErrorPage from "views/Pages/ErrorPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import * as Pages from 'views/GenericPage';
import * as PagesTournament from 'views/TournamentPage';

// @material-ui/icons
import * as Icons from '@material-ui/icons';

const buildRoutes = (items) => {
  let configRoutes = items.map(item=>{
    const DynamicIcon = item.icon ? Icons[item.icon.replace("Icon", "")] : "ArrowDropDownCircle";
    if(item.collapse) {
      const views = buildRoutes(item.views);
      return {...item, icon: DynamicIcon, views}
    } else if(item.component === 'TournamentPage'){
      const DynamicComp = PagesTournament[item.component];
      return {...item, component: DynamicComp, icon: DynamicIcon}
    } else {
      const DynamicComp = Pages[item.component];
      return {...item, component: DynamicComp, icon: DynamicIcon}
    }
  });
  dashRoutes.forEach(item=>{
    configRoutes.push(item);
  })
  return configRoutes;
}

const getFirstRoute = (items) => {
  const item = items[0];
  if(item.views) {
    return getFirstRoute(item.views);
  } else {
    return item;
  }
}

const dashRoutes = [
  {
    path: "/login",
    name: "Login Page",
    component: LoginPage,
    layout: "/auth",
    redirect: true
  },
  {
    path: "/register/:hash?",
    name: "Register Page",
    component: RegisterPage,
    layout: "/auth",
    redirect: true
  },
  {
    path: "/error",
    name: "Error Page",
    component: ErrorPage,
    layout: "/auth",
    redirect: true
  }
];

export default dashRoutes;

export {
  buildRoutes,
  getFirstRoute
};