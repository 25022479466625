import React, { useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SignUpForm from "components/AuthForms/SignUpForm";
import ConfirmSignUpForm from "components/AuthForms/ConfirmSignUpForm";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function RegisterPage() {
  const [action, setAction] = useState("register");
  const [user, setUser] = useState({});

  const classes = useStyles();

  const handleConfirmSignUp = (user) => {
    setUser(user);
    setAction("confirm_sign_up");
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          {action === "register" && (
            <SignUpForm onConfirmSignUp={handleConfirmSignUp} />
          )}
          {action === "confirm_sign_up" && <ConfirmSignUpForm data={user} />}
        </GridItem>
      </GridContainer>
    </div>
  );
}
