import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { strings as translate, get_language } from "../../locale";

import _ from "lodash";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Button from "../../components/CustomButtons/Button";
import useMainNotification from "../../hooks/useMainNotification";

import { Formik, setIn } from "formik";
import InputForm from "components/CustomForms/InputForm";
import moment from "moment";
import { getHoursValue } from "helpers/TounamentUtils";

// services
import MainServices from "../../services/MainServices";
import TournamentServices from "../../services/TournamentServices";

// contexts
import * as yup from "yup";
// styles
import styles from "./styles";
import InputTime from "components/CustomInput/InputTime";

const useStyles = makeStyles(styles);

function PhasesForm(props) {
  const { onSave, onSaveError, onCancel, editForm } = props;
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [hasThird, setHasThird] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [dataJson, setDataJson] = useState([]);
  const { addSuccess, addError } = useMainNotification();
  const [tournamentId, setTournamentId] = useState(null);
  const [teams, setTeams] = useState([{ id: "", name: "Selecciona equipo" }]);

  const [phaseKey, setPhaseKey] = useState(null);
  const [numberOfMatches, setNumberOfMatches] = useState(1);
  const [currentPhases, setCurrentPhases] = useState(null);
  const [originPhases, setOriginPhases] = useState(null);
  const [thirdPhase, setThirdPhase] = useState(null);
  const [phasesData, setPhasesData] = useState(null);

  const phasesOptions = [
    { key: "subleague", name: translate.subleague },
    { key: "32", name: translate.round_of_64 },
    { key: "16", name: translate.round_of_32 },
    { key: "8", name: translate.round_of_16 },
    { key: "4", name: translate.quarter_finals },
    { key: "2", name: translate.semifinals },
    { key: "1", name: translate.final },
  ];

  const phasesRounds = {
    subleague: {
      phases: [{ key: "subleague", name: translate.subleague }],
      matches: [],
    },
    32: { phases: [...phasesOptions.slice(1, 7)], matches: [] },
    16: { phases: [...phasesOptions.slice(2, 7)], matches: [] },
    8: { phases: [...phasesOptions.slice(3, 7)], matches: [] },
    4: { phases: [...phasesOptions.slice(4, 7)], matches: [] },
    2: { phases: [...phasesOptions.slice(5, 7)], matches: [] },
    1: { phases: [...phasesOptions.slice(6, 7)], matches: [] },
  };

  useEffect(() => {
    console.log("props.data", props.data);
    if (
      props.data !== undefined &&
      props.data !== null &&
      //   props.data.phases.length > 0 &&
      props.data.teams !== undefined
    ) {
      const phasesData = props.data.phases.filter((i) => i.key !== "third");
      const selectedKey = phasesData[0];
      const third = props.data.phases.filter((i) => i.key === "third");
      const selectedHasThird = third.length > 0;

      if (
        selectedKey &&
        selectedKey.key === "subleague" &&
        props.data.fixture[0] &&
        props.data.fixture[0].round !== undefined
      ) {
        setNumberOfMatches(props.data.fixture[0].round.matches.length);
      }

      const newData = {
        data: [
          {
            key: selectedKey
              ? { id: selectedKey.key, name: selectedKey.name }
              : null,
            third: selectedHasThird,
          },
        ],
        metadata: [
          {
            accessor: "key",
            header: "Id",
            editable: false,
            type: "select",
          },
          {
            accessor: "third",
            header: "3er y 4to puesto",
            editable: true,
            type: "checkbox",
          },
        ],
      };

      // filtarmos los campos utiles
      const fields = newData.metadata;

      // establecemos los valores iniciales, para la edición hay que obtenerlos del data.data
      const getInitialValues = () => {
        const values = {};
        const fData = newData.data[0];
        console.log("fields", fields);
        console.log("fData", fData);
        fields.forEach((item) => {
          const selectValue =
            fData[item.accessor] && fData[item.accessor].id
              ? fData[item.accessor].id
              : fData[item.accessor];
          Object.assign(values, {
            [item.accessor]:
              item.type === "select" ? selectValue : fData[item.accessor],
          });
        });

        Object.assign(values, props.data.fieldValues);
        //   console.log("values", values);
        return values;
      };

      setInitialValues(getInitialValues());

      // establecemos las validaciones
      setValidationSchema(
        yup.object().shape({
          key: yup.string().required("Required"),
        })
      );

      setCurrentPhases([...phasesData]);
      setOriginPhases([...props.data.phases]);
      setThirdPhase(third.pop());
      if (selectedKey !== undefined) {
        console.log("selectedKey.key", selectedKey.key);
        setPhaseKey(selectedKey.key);
      }
      setHasThird(selectedHasThird);
      setTeams(props.data.teams);
      setTournamentId(props.data.id_tournament);
      setDataJson(props.data.fixture);

      loadPhaseData(selectedKey ? selectedKey.key : null, [...phasesData]);
      setLoaded(true);
      console.log("fixture", props.data.fixture);
    }
  }, [props.data]);

  useEffect(() => {
    if (phaseKey !== null && phaseKey !== "none") {
      console.log("phaseKey", phaseKey, "currentPhases", currentPhases);
      loadPhaseData(phaseKey, currentPhases, numberOfMatches);
    }
  }, [hasThird, phaseKey, numberOfMatches]);

  const loadPhaseData = (_phaseKey, _currentPhases, _numberOfMatches) => {
    console.log("_phaseKey", _phaseKey);
    if (_phaseKey === null) {
      return false;
    }

    const newPhases = JSON.parse(JSON.stringify(phasesRounds[_phaseKey]));

    console.log(newPhases);

    newPhases.phases.forEach((phase, k) => {
      console.log("phase", phase);
      const matchedPhase =
        _currentPhases.length > 0
          ? _currentPhases.filter((i) => i.key === phase.key)[0]
          : null;
      if (matchedPhase) {
        newPhases.phases[k] = matchedPhase;
      }

      const matches =
        _phaseKey === "subleague"
          ? Array.from(
              { length: parseInt(numberOfMatches) },
              (_, i) => i + 1
            ).map((kk) => {
              return {
                id: null,
                datetime: null,
                team_1: null,
                team_2: null,
                tournament: null,
                footballfield: "",
              };
            })
          : Array.from({ length: parseInt(phase.key) }, (_, i) => i + 1).map(
              (kk) => {
                return {
                  id: null,
                  datetime: null,
                  team_1: null,
                  team_2: null,
                  tournament: null,
                  footballfield: "",
                };
              }
            );

      newPhases.matches.push(matches);
    });

    if (hasThird && parseInt(_phaseKey) > 0) {
      newPhases.phases.push(
        thirdPhase
          ? thirdPhase
          : {
              key: "third",
              name: translate.third_and_fourth_place,
              playered: false,
            }
      );
      newPhases.matches.push([
        {
          id: null,
          datetime: null,
          team_1: null,
          team_2: null,
          tournament: null,
        },
      ]);
    }
    setPhasesData(newPhases);
  };

  const formikValuesToFixtureMatches = (values) => {
    const phaseMatch = [];
    const fixture = [];

    Object.keys(values).map((k) => {
      if (k.match(/^phase/) && !k.match(/min_obj|team_1_obj|team_2_obj/)) {
        console.log(k, values[k]);
        const parts = k.split("_");
        const phase = parts[1];
        const match = parts[3];
        const fieldname = k.substring(k.lastIndexOf(parts[4]));
        if (!phaseMatch[phase - 1]) {
          phaseMatch[phase - 1] = [];
        }
        if (!phaseMatch[phase - 1][match - 1]) {
          phaseMatch[phase - 1][match - 1] = {};
        }
        phaseMatch[phase - 1][match - 1] = {
          ...phaseMatch[phase - 1][match - 1],
          [fieldname]: values[k],
        };
      }
    });
    console.log("phaseMatch", phaseMatch);
    phaseMatch.forEach((phase) => {
      const pMatches = phase.map((match) => ({
        ...(match.id && { id: match.id }),
        datetime: `${moment(match.date, "DD/MM/YYYY").format("YYYY-MM-DD")} ${
          match.hour
        }:${match.min}:00`,
        team_1: match.team_1,
        team_2: match.team_2,
        tournament: match.tournament,
        footballfield: match.footballfield,
      }));
      fixture.push({
        round: {
          id_ft: phase[0].idft,
          number: 1,
          matches: pMatches,
        },
      });
    });
    console.log("fixture", fixture);
    return { fixture };
  };

  const handleFormSubmit = async (values, actions) => {
    console.log("values", values);
    console.log("phasesData", phasesData);
    const dataJson2 = formikValuesToFixtureMatches(values);
    console.log("formikValuesToFixtureMatches", dataJson2);
    try {
      let res = null;
      if (phaseKey !== "") {
        let data = null;

        const _phases = phasesData.phases;

        const _remainingPhases = _.differenceWith(
          originPhases,
          phasesData.phases,
          _.isEqual
        );
        console.log("originPhases", originPhases, "_phases", _phases);

        data = {
          tournament_id: tournamentId,
          phases: _phases,
          type: parseInt(values.key) >= 0 ? "playoff" : values.key,
          third: hasThird ? thirdPhase : null,
          remainingPhases: _remainingPhases,
        };
        console.log("data", data);
        res = await TournamentServices.updatePhasesByTournament(data);

        let postMatches = 0;
        let putMatches = 0;
        const fixturePost = {
          fixture: dataJson2.fixture
            .map((i, index) => {
              let roundMatches = [];
              console.log("res.data.phases", res.data.phases);
              console.log(
                "res.data.phases[" + index + "]",
                res.data.phases[index]
              );
              if (i.round !== undefined && i.round.matches !== undefined) {
                roundMatches = i.round.matches.filter((i) => {
                  const notId = i.id === undefined;
                  if (notId) {
                    postMatches++;
                  }
                  return notId;
                });
              }
              return {
                ...i,
                round: {
                  ...i.round,
                  id_ft:
                    res.data.phases[index] && res.data.phases[index].id
                      ? res.data.phases[index].id
                      : null,
                  matches: roundMatches.map((i) => ({
                    ...i,
                    tournament: tournamentId,
                  })),
                },
              };
            })
            .filter((i) => i.round.matches.length > 0),
        };
        const fixturePut = {
          fixture: dataJson2.fixture
            .map((i, index) => {
              let roundMatches = [];
              if (i.round !== undefined && i.round.matches !== undefined) {
                roundMatches = i.round.matches.filter((i) => {
                  const hasId = i.id !== undefined;
                  if (hasId) {
                    putMatches++;
                  }
                  return hasId;
                });
              }
              return {
                ...i,
                round: {
                  ...i.round,
                  tournament: tournamentId,
                  id_ft:
                    res.data.phases[index] && res.data.phases[index].id
                      ? res.data.phases[index].id
                      : null,
                  matches: roundMatches.map((i) => ({
                    ...i,
                    tournament: tournamentId,
                  })),
                },
              };
            })
            .filter((i) => i.round.matches.length > 0),
        };
        console.log("fixturePost", fixturePost);
        console.log("fixturePut", fixturePut);
        let resMatches = null;
        if (postMatches !== 0) {
          resMatches = await TournamentServices.addFixtureMatches(fixturePost);
        }
        if (putMatches !== 0) {
          resMatches = await TournamentServices.updateFixtureMatches(
            fixturePut
          );
        }

        if (!res.error || !res.data.error) {
          addSuccess(
            !editForm
              ? `${translate.tournament} ${translate.has_been_added_successfully}`
              : `${translate.tournament} ${translate.has_been_edited_successfully}`
          );
        }
      }

      if (onSave) {
        onSave({ ...values, id_tournament: res.data.id_tournament });
      }
    } catch (e) {
      const data = e.response ? e.response.data : {};
      let errors = {};

      Object.keys(data).forEach((item) => {
        errors = setIn(errors, item, data[item][0]);
      });

      actions.setStatus(errors);

      handleSentError(e.response);
      if (e.response && e.response.data && e.response.data.error) {
        addError(e.response.data.error_msg, null, { messageLength: null });
      } else if (e.message) {
        addError(e.message, null, { messageLength: null });
      }
      MainServices.cancel();
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleSentError = (values) => {
    if (onSaveError) {
      onSaveError(values);
    }
  };

  const refillInputs = (prevPhaseKey, nextPhaseKey) => {
    if (
      parseInt(nextPhaseKey) > 0 &&
      parseInt(nextPhaseKey) > parseInt(prevPhaseKey)
    ) {
      console.log(
        "prevPhaseKey: ",
        prevPhaseKey,
        "nextPhaseKey: ",
        nextPhaseKey
      );
      const fieldValues = {};
      phasesRounds[nextPhaseKey].phases.map((i) => {
        /* const label = `phase_${k + 1}_match_${kk + 1}`;
        Object.assign(formvalues, {
          [`${label}_footballfield`]: h.footballfield,
          [`${label}_date`]: moment(h.datetime).format(
            get_language === "es" ? "DD/MM/YYYY" : "MM/DD/YYY"
          ),
          [`${label}_time`]: moment(h.datetime).format("HH:mm"),
          [`${label}_team_1`]: h.team_1,
          [`${label}_team_2`]: h.team_2,
        }); */
      });
    }
  };

  const handleChangePhase = (e) => {
    refillInputs(phaseKey, e.target.value);
    console.log("e.target.value", e.target.value);
    setPhaseKey(e.target.value);
  };

  const handleClickAddMatch = () => {
    setNumberOfMatches((v) => v + 1);
  };

  const getPlayoffContent = (formik) => {
    if (phaseKey === null) {
      return null;
    }
    if (phaseKey === "none") {
      return (
        <div className={classes.playOffColumn}>
          <div className={classes.playOffColumnTitle}>
            {translate.without_playoffs}
          </div>
        </div>
      );
    }
    console.log("phasesData", phasesData);
    return (
      phasesData !== null &&
      phasesData.phases.map((phase, k) => (
        <div key={k} className={classes.playOffColumn}>
          <div className={classes.playOffColumnTitle}>{phase.name}</div>
          <div className={classes.playOffColumnMatches}>
            {phasesData.matches[k].map((match, kk) => (
              <GridContainer key={k + "_" + kk}>
                <GridItem xs={2}>
                  <InputForm
                    data={{
                      accessor: `phase_${k + 1}_match_${kk + 1}_id`,
                      type: "hidden",
                    }}
                  />
                  <InputForm
                    data={{
                      accessor: `phase_${k + 1}_match_${kk + 1}_idft`,
                      type: "hidden",
                    }}
                  />
                  <InputForm
                    data={{
                      accessor: `phase_${k + 1}_match_${kk + 1}_tournament`,
                      type: "hidden",
                    }}
                  />
                  <InputForm
                    data={{
                      accessor: `phase_${k + 1}_match_${kk + 1}_footballfield`,
                      header: translate.football_field_number,
                      editable: true,
                      type: "number",
                    }}
                    inputProps={{
                      min: 1,
                    }}
                    required={true}
                    editForm={true}
                  />
                </GridItem>
                <GridItem xs={2}>
                  <InputForm
                    data={{
                      accessor: `phase_${k + 1}_match_${kk + 1}_date`,
                      header: translate.date,
                      editable: true,
                      type: "date",
                    }}
                    required={true}
                    editForm={false}
                  />
                </GridItem>
                <GridItem xs={2}>
                  <InputTime
                    data={{
                      phase: phase,
                      phaseNumber: k + 1,
                      roundNumber: null,
                      matchNumber: kk + 1,
                    }}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <InputForm
                    data={{
                      accessor: `phase_${k + 1}_match_${kk + 1}_team_1`,
                      header: translate.team + " 1",
                      editable: true,
                      type: "select",
                      options: teams,
                    }}
                    required={true}
                    editForm={true}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <InputForm
                    data={{
                      accessor: `phase_${k + 1}_match_${kk + 1}_team_2`,
                      header: translate.team + " 2",
                      editable: true,
                      type: "select",
                      options: teams,
                    }}
                    required={true}
                    editForm={true}
                  />
                </GridItem>
              </GridContainer>
            ))}
          </div>
        </div>
      ))
    );
  };

  const _getPlayoffContent = (formik) => {
    if (phaseKey === null) {
      return null;
    }
    if (phaseKey === "none") {
      return (
        <div className={classes.playOffColumn}>
          <div className={classes.playOffColumnTitle}>
            {translate.without_playoffs}
          </div>
        </div>
      );
    }

    const newPhases = {};
    Object.assign(newPhases, phasesRounds[phaseKey]);

    newPhases.phases.forEach((phase, k) => {
      console.log("phase", phase);
      const matchedPhase = currentPhases.filter((i) => i.key === phase.key)[0];
      if (matchedPhase) {
        newPhases.phases[k] = matchedPhase;
      }

      const matches =
        phaseKey === "subleague"
          ? []
          : Array.from({ length: parseInt(phase.key) }, (_, i) => i + 1).map(
              (kk) => {
                return {
                  id: null,
                  datetime: null,
                  team_1: null,
                  team_2: null,
                  tournament: null,
                  footballfield: "",
                };
              }
            );

      newPhases.matches.push(matches);
    });

    if (hasThird && parseInt(phaseKey) > 0) {
      /* newPhases.phases.push(thirdPhase);
      newPhases.matches.push([
        {
          id: null,
          datetime: null,
          team_1: null,
          team_2: null,
          tournament: null,
        },
      ]); */
    }

    return newPhases.phases.map((phase, k) => (
      <div key={k} className={classes.playOffColumn}>
        <div className={classes.playOffColumnTitle}>{phase.name}</div>
        <div className={classes.playOffColumnMatches}>
          {newPhases.matches[k].map((match, kk) => (
            <GridContainer key={k + "_" + kk}>
              <GridItem xs={2}>
                <InputForm
                  data={{
                    accessor: `phase_${k + 1}_match_${kk + 1}_id`,
                    type: "hidden",
                  }}
                />
                <InputForm
                  data={{
                    accessor: `phase_${k + 1}_match_${kk + 1}_idft`,
                    type: "hidden",
                  }}
                />
                <InputForm
                  data={{
                    accessor: `phase_${k + 1}_match_${kk + 1}_tournament`,
                    type: "hidden",
                  }}
                />
                <InputForm
                  data={{
                    accessor: `phase_${k + 1}_match_${kk + 1}_footballfield`,
                    header: translate.football_field_number,
                    editable: true,
                    type: "number",
                  }}
                  inputProps={{
                    min: 1,
                  }}
                  required={true}
                  editForm={true}
                />
              </GridItem>
              <GridItem xs={2}>
                <InputForm
                  data={{
                    accessor: `phase_${k + 1}_match_${kk + 1}_date`,
                    header: "Fecha",
                    editable: true,
                    type: "date",
                  }}
                  required={true}
                  editForm={false}
                />
              </GridItem>
              <GridItem xs={2}>
                <InputTime
                  data={{
                    phase: phase,
                    phaseNumber: k + 1,
                    roundNumber: null,
                    matchNumber: kk + 1,
                  }}
                />
              </GridItem>
              <GridItem xs={3}>
                <InputForm
                  data={{
                    accessor: `phase_${k + 1}_match_${kk + 1}_team_1`,
                    header: translate.team + " 1",
                    editable: true,
                    type: "select",
                    options: teams,
                  }}
                  required={true}
                  editForm={true}
                />
              </GridItem>
              <GridItem xs={3}>
                <InputForm
                  data={{
                    accessor: `phase_${k + 1}_match_${kk + 1}_team_2`,
                    header: translate.team + " 2",
                    editable: true,
                    type: "select",
                    options: teams,
                  }}
                  required={true}
                  editForm={true}
                />
              </GridItem>
            </GridContainer>
          ))}
        </div>
      </div>
    ));
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleFinish = () => {
    if (onSave) {
      onSave();
    }
  };

  return (
    <>
      <LoadingIndicator
        type="TailSpin"
        color="#2e82ef"
        height={50}
        width={50}
      />

      <div className={classes.root}>
        {loaded && (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              trackPromise(handleFormSubmit(values, actions), "save-button");
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <GridContainer alignItems="stretch">
                  <GridItem xs={3}>
                    <InputForm
                      data={{
                        accessor: "key",
                        header: "Tipo de fase",
                        editable: true,
                        type: "select",
                        options: [
                          { id: "none", name: "Sin fase definitoria" },
                          ...phasesOptions.map((i) => ({
                            id: i.key,
                            name: i.name,
                          })),
                        ],
                      }}
                      onChange={handleChangePhase}
                      editForm={true}
                    />
                    {parseInt(phaseKey) > 0 && (
                      <InputForm
                        data={{
                          accessor: "third",
                          header: translate.third_and_fourth_place,
                          editable: true,
                          type: "checkbox",
                        }}
                        onChange={(e) => {
                          console.log("checkbos", e.target.checked);
                          setHasThird(e.target.checked);
                        }}
                        editForm={true}
                      />
                    )}
                  </GridItem>
                  <GridItem xs={12}>
                    <div style={{ marginBottom: "16px" }}>
                      {phaseKey === "subleague" && (
                        <Button type="button" onClick={handleClickAddMatch}>
                          {translate.add_match}
                        </Button>
                      )}
                    </div>
                    <div className={classes.playOffContainer}>
                      {getPlayoffContent(formik)}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ marginTop: "30px" }}>
                  <GridItem xs={12} container justify="center">
                    <div className={classes.formButtonWithLoader}>
                      <LoadingIndicator
                        type="TailSpin"
                        color="#2e82ef"
                        height={30}
                        width={30}
                        area="save-button"
                      />
                      <Button
                        id = "btnBack"
                        type="submit"
                        onClick={handleCancel}
                        style={{ marginRight: "24px" }}
                      >
                        {translate.back}
                      </Button>
                      {phaseKey !== "none" && (
                        <>
                          <Button
                            id = "btnSave"
                            type="submit"
                            color="success"
                            disabled={formik.isSubmitting}
                            style={{ marginRight: "24px" }}
                          >
                            {translate.save}
                          </Button>
                          <Button
                            id = "btnFinish"
                            type="submit"
                            color="primary"
                            onClick={handleFinish}
                          >
                            {translate.finish}
                          </Button>
                        </>
                      )}
                      {phaseKey === "none" && (
                        <Button
                          id = "btnFinish"
                          type="submit"
                          color="primary"
                          onClick={handleFinish}
                        >
                          {translate.finish}
                        </Button>
                      )}
                    </div>
                  </GridItem>
                </GridContainer>
              </form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
}

export default withRouter(PhasesForm);
