import React, { useState, useReducer, useEffect } from "react";
import { strings as translate } from "locale";
import { withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

// services
import { Auth } from "aws-amplify";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

const ChangePasswordForm = (props) => {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [inputValues, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { email: props.email, code: "", new_password: "", repeat_password: "" }
  );
  const [apiError, setApiError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const setInput = (e) => {
    const { name, value } = e.target;
    setInputValues({ [name]: value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const handleMouseDownRepeatPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = async (e) => {
    e.preventDefault();

    if (
      !inputValues.code.trim() &&
      !inputValues.new_password.trim() &&
      !inputValues.repeat_password.trim()
    ) {
      setApiError(translate.all_fields_required);
      return;
    }

    if (
      inputValues.new_password.trim() !== inputValues.repeat_password.trim()
    ) {
      setApiError(translate.passwords_do_not_match);
      return;
    }

    setApiError("");
    try {
      const res = await Auth.forgotPasswordSubmit(
        inputValues.email,
        inputValues.code,
        inputValues.new_password
      );

      if (props.onSend) {
        props.onSend();
      }
    } catch (error) {
      console.log(error);
      // {code: "LimitExceededException", name: "LimitExceededException", message: "Attempt limit exceeded, please try after some time."}
      setApiError(error.message);
    }
  };

  return (
    <form onSubmit={changePassword}>
      <Card login className={classes[cardAnimaton]}>
        <CardHeader
          className={`${classes.cardHeader} ${classes.textCenter}`}
          color="primary"
        >
          <h4 className={classes.cardTitle}>{translate.change_password}</h4>
        </CardHeader>
        <CardBody>
          <input type="hidden" value={inputValues.email || ""} name="email" />
          <CustomInput
            labelText={translate.verification_code}
            id="code"
            formControlProps={{
              fullWidth: true,
            }}
            value={inputValues.code}
            inputProps={{
              onChange: setInput,
              name: "code",
            }}
            endAdornment={
              <InputAdornment position="end">
                <VerifiedUserIcon className={classes.inputAdornmentIcon} />
              </InputAdornment>
            }
          />
          <CustomInput
            labelText={translate.new_password}
            id="new_password"
            formControlProps={{
              fullWidth: true,
            }}
            value={inputValues.new_password}
            inputProps={{
              onChange: setInput,
              name: "new_password",
              type: showPassword ? "text" : "password",
              autoComplete: "off",
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <CustomInput
            labelText={translate.repeat_password}
            id="repeat_password"
            formControlProps={{
              fullWidth: true,
            }}
            value={inputValues.repeat_password}
            inputProps={{
              onChange: setInput,
              name: "repeat_password",
              type: showRepeatPassword ? "text" : "password",
              autoComplete: "off",
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowRepeatPassword}
                  onMouseDown={handleMouseDownRepeatPassword}
                >
                  {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {apiError && <FormHelperText error>{apiError}</FormHelperText>}
        </CardBody>
        <CardFooter className={classes.justifyContentCenter}>
          <Button color="primary" size="lg" block type="submit">
            {translate.change_password}
          </Button>
        </CardFooter>
      </Card>
    </form>
  );
};

export default withRouter(ChangePasswordForm);
