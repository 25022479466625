import axios from "axios";
import { get_language } from "../locale";
import awsconfig from "../aws-exports";
import { Auth } from "aws-amplify";

const username = localStorage.getItem("username");
const cognitoStorageId = `CognitoIdentityServiceProvider.${awsconfig.userPoolWebClientId}`;
const cognitoStorageUser = `${cognitoStorageId}.${username}`;

const cancelToken = axios.CancelToken.source();
const refreshTokenUrl = process.env.REACT_APP_API_URL + "/refresh_token/";
const getAccessTokenKey = `${cognitoStorageUser}.accessToken`;
const getRefreshTokenKey = `${cognitoStorageUser}.refreshToken`;

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Accept-Language": get_language,
  },
});

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    try {
      if (
        config.url.includes("configuration") ||
        config.url.includes("register")
      ) {
        config.headers = {
          ...config.headers,
          "Content-Type": "application/json",
        };
      } else {
        const session = await Auth.currentSession();

        config.headers = {
          ...(session.idToken.jwtToken
            ? { Authorization: `Bearer ${session.accessToken.jwtToken}` }
            : null),
          "Content-Type": "application/json",
        };
      }
    } catch (error) {
      console.log("error", error);
      if (
        error.code === "NotAuthorizedException" &&
        error.message === "Refresh Token has expired"
      ) {
        console.log("redirect to login page");
        window.location.href = "/auth/login";
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.toString() === "Error: Request failed with status code 401") {
      window.location.href = "/auth/login";
    }
    return error;
  }
);

export default axiosApiInstance;

export {
  cancelToken,
  cognitoStorageId,
  cognitoStorageUser,
  getAccessTokenKey,
  getRefreshTokenKey,
  refreshTokenUrl,
};
