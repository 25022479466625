import React from 'react'
import { trackPromise } from 'react-promise-tracker';
import {strings as translate} from 'locale';
import queryString from 'query-string';
import _ from 'lodash';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@material-ui/core";
import CustomTable from "components/CustomTable/CustomTable";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import useMainNotification from '../../hooks/useMainNotification';

// services
import MainServices from "../../services/MainServices";

const useStyles = makeStyles(() => ({
  textError: {
    color: "#Ff0000",
    textAlign: "center",
    padding: "30px 0",
    fontSize: "0.75rem",
    lineHeight: "1.66",
    fontWeight: "400"
  }
}));

const CustomDialog = (props) => {
    const {service: model, queryParams, id, linkTitle, linkButton} = props.data;
    const [data, setData] = React.useState(null);
    const [loaded, setLoaded] = React.useState(false);
    const [error, setError] = React.useState('');
    const { addError, addSuccess } = useMainNotification();
    const classes = useStyles();

    async function loadData() {
      const qString = queryString.parse(queryParams);

      Object.keys(qString).map(k=>{
        const newKey = qString[k].substr(1);
        qString[k] = _.at(props.data.row, [newKey])[0];
      })

      try {
        const res = await MainServices.list(model, qString);
        if(!res.data.error) {
          if(qString) {
            res.data.queryParams = qString;
          }
          setData(res.data);
        }
      } catch (e) {
        if(e.response && e.response.data && e.response.data.error) {
          setError(e.response.data.error_msg);
          addError(e.response.data.error_msg, null, {messageLength:null});
        } else  if ( e.message ) {
          setError(e.message);
          addError(e.message, null, {messageLength:null});
        } else {
          console.log(e)
        }
      }
    }

    React.useEffect(() => {
      if(model)  {
        trackPromise(loadData())
          .then(()=>{
            setLoaded(true);
          });
      }

      return () => { MainServices.cancel(); }
    }, [addError, model, queryParams, id]);

    const crearLinkDePago = async () => {
      try {
        const res = await MainServices.getItem('create_payment_link', id);
        if(!res.data.error) {
          addSuccess('El link de pago fué creado exitosamente');
        }
      } catch (e) {
        if(e.response && e.response.data && e.response.data.error) {
          setError(e.response.data.error_msg);
          addError(e.response.data.error_msg, null, {messageLength:null});
        } else if( e.message ) {
          setError(e.message);
          addError(e.message, null, {messageLength:null});
        } else {
          console.log(e)
        }
      }
    }

    const onClickActionButton = () => {
      if(linkButton.action === 'crear_link_de_pago') {
        crearLinkDePago();
      }
    }

    const handleUpdateTableRow = (newRow) => {
      trackPromise(loadData())
          .then(()=>{
              setLoaded(true);
          });
    }

    return (
        <Dialog
          {...props}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">
            {linkTitle ? linkTitle : 'Title'}
          </DialogTitle>
          <DialogContent>
            <LoadingIndicator
              type="TailSpin"
              color="#2e82ef"
              height={50}
              width={50}
            />
            {
              (loaded && !error && data) ? 
              <CustomTable 
                data={data}
                paginationBottom={true}
                updateTableRow={handleUpdateTableRow}
              />
              : (loaded && error) ? 
              <div className={classes.textError}>
                {translate.formatString(translate.autogenerate_table_error, <br />)}
              </div>
              :
              null
            }
          </DialogContent>
          <DialogActions>
            {
              linkButton && 
              <Button 
                onClick={onClickActionButton} 
                color="success"
              >
                {linkButton.title}
              </Button>
            }
            <Button 
              onClick={props.onClose} 
              color="primary"
            >
              {translate.close}
            </Button>
          </DialogActions>
        </Dialog>
    )
}

export default CustomDialog
