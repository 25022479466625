import API from "./api.js";
import axios from "axios";
import _ from "lodash";
let cancelToken;

const MainServices = {
  config: (action) => {
    cancelToken = axios.CancelToken.source();

    const found = Object.keys(localStorage).filter((item) =>
      item.match(/accessToken$/)
    );
    const access_token = found ? localStorage.getItem(found[0]) : "";
    const headers = access_token
      ? {
          Authorization: `Bearer ${access_token}`,
        }
      : {};

    return API({
      method: "GET",
      url: `/configuration/`,
      cancelToken: cancelToken.token,
      headers,
    });
  },
  saveConfig: (data) => {
    const newData = Object.assign({}, data);
    delete newData.cognito;
    localStorage.setItem("config", JSON.stringify(newData));
  },
  getConfig: () => {
    const config = localStorage.getItem("config");
    return JSON.parse(config);
  },
  getMetadata: (name) => {
    // siempre apunta al endpoint viejo
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "GET",
      url: `/metadata/`,
      params: {
        model: name,
      },
      cancelToken: cancelToken.token,
    };

    return API(data);
  },
  getItem: (name, id) => {
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "GET",
      url: `/${name}/`,
      params: {
        id: id,
      },
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");
    let api_url_model = localStorage.getItem("api_url_model");

    if (api_url !== "" && api_url_model === name) {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  list: (name, args = null, url_api = null) => {
    if (!name) return null;
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "GET",
      url: `/${name}/`,
      cancelToken: cancelToken.token,
      params: args,
    };

    let api_url = localStorage.getItem("api_url");
    let api_url_model = localStorage.getItem("api_url_model");

    if (api_url !== "" && api_url_model === name) {
      data = { ...data, baseURL: api_url };
    }
    if (url_api !== null) {
      data = { ...data, baseURL: url_api };
    }

    return API(data);
  },
  filteredList: (name, queryString = "") => {
    if (!name) return null;
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "GET",
      url: `/${name}/${queryString}`,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");
    let api_url_model = localStorage.getItem("api_url_model");

    if (api_url !== "" && api_url_model === name) {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  add: (name, _data) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    Object.keys(_data).forEach((item) => {
      formData.append(item, _data[item]);
    });

    let data = {
      method: "POST",
      url: `/${name}/`,
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");
    let api_url_model = localStorage.getItem("api_url_model");

    if (api_url !== "" && api_url_model === name) {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  edit: (name, _data) => {
    cancelToken = axios.CancelToken.source();

    let data = {
      method: "PUT",
      url: `/${name}/`,
      params: {
        id: _data.id,
      },
      data: _data,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");
    let api_url_model = localStorage.getItem("api_url_model");

    if (api_url !== "" && api_url_model === name) {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  delete: (name, id) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    formData.append("id", id);

    let data = {
      method: "DELETE",
      url: `${name}/`,
      params: {
        id: id,
      },
      data: formData,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");
    let api_url_model = localStorage.getItem("api_url_model");

    if (api_url !== "" && api_url_model === name) {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  update: (name, _data) => {
    const formData = new FormData();
    cancelToken = axios.CancelToken.source();

    Object.keys(_data).forEach((item) => {
      formData.append(item, _data[item]);
    });

    let data = {
      method: "PATCH",
      url: `${name}/`,
      params: {
        id: _data.id,
      },
      data: formData,
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");
    let api_url_model = localStorage.getItem("api_url_model");

    if (api_url !== "" && api_url_model === name) {
      data = { ...data, baseURL: api_url };
    }

    return API(data);
  },
  addPopup: (name, _data, global_action) => {
    cancelToken = axios.CancelToken.source();
    delete _data.metadata;
    delete _data.global_action;

    const body = Object.assign({}, global_action.body);
    let newData = Object.assign(body, { row: {}, form: _data, filter: {} });
    if (global_action.filter) {
      newData.filter = global_action.filter;
    }

    let url_api = null;
    if (global_action !== undefined && global_action.url_api !== undefined) {
      url_api = global_action.url_api;
    }

    // cada valor string del body que empiece con # seguido del nombre de campo
    // tiene que ser reemplazado por una variable del mismo nombre del queryParams
    _.forIn(newData, (v, k) => {
      if (typeof v === "string") {
        if (v.match(/#(.+)/)) {
          // obtenemos el nombre del campo
          const field_name = v.match(/#(.+)/)[1];
          // reemplazamos el valor por el del queryParams
          newData[k] = global_action.queryParams[field_name];
        }
      }
    });

    let data = {
      method: "POST",
      url: `/${name}/`,
      data: JSON.stringify(newData),
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");
    let api_url_model = localStorage.getItem("api_url_model");

    if (api_url !== "" && api_url_model === name) {
      data = { ...data, baseURL: api_url };
    }

    if (url_api !== null && url_api !== "") {
      data = { ...data, baseURL: url_api };
    }

    return API(data);
  },
  editPopup: (name, _data, global_action) => {
    cancelToken = axios.CancelToken.source();
    delete _data.metadata;

    const body = Object.assign({}, global_action.body);
    const newData = Object.assign(body, { row: _data, form: {} });

    let url_api = null;
    if (global_action !== undefined && global_action.url_api !== undefined) {
      url_api = global_action.url_api;
    }

    let data = {
      method: "POST",
      url: `/${name}/`,
      data: JSON.stringify(newData),
      cancelToken: cancelToken.token,
    };

    let api_url = localStorage.getItem("api_url");
    let api_url_model = localStorage.getItem("api_url_model");

    if (api_url !== "" && api_url_model === name) {
      data = { ...data, baseURL: api_url };
    }

    if (url_api !== null && url_api !== "") {
      data = { ...data, baseURL: url_api };
    }

    return API(data);
  },
  cancel: () => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Request canceled.");
    }
  },
};
export default MainServices;
