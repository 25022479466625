import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { strings as translate, get_language } from "../../locale";

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CardBody from "../../components/Card/CardBody";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Button from "../../components/CustomButtons/Button";
import useMainNotification from "../../hooks/useMainNotification";

// styles
import { Step, StepButton, StepLabel, Stepper } from "@material-ui/core";
import TournamentForm from "./TournamentForm";
import TeamsForm from "./TeamsForm";
import ZonesFormEdit from "./ZonesFormEdit";
import MatchesFormEdit from "./MatchesFormEdit";
import TournamentServices from "services/TournamentServices";
import PhasesFormEdit from "./PhasesFormEdit";
import moment from "moment";
import _ from "lodash";

function Edit(props) {
  const { addError } = useMainNotification();
  const step = props.location.pathname.substring(
    props.location.pathname.indexOf("step_") + 5
  );
  const [activeStep, setActiveStep] = useState(
    props.location.pathname.indexOf("step_") !== -1 ? parseInt(step) : 0
  );
  const [formValues, setFormValues] = useState(null);
  const [steps, setSteps] = useState([
    translate.category,
    translate.teams,
    translate.groups,
    translate.matches,
    translate.phases,
  ]);
  const [tournamentData, setTournamentData] = useState(null);
  const [zoneData, setZoneData] = useState(null);
  const [teamData, setTeamData] = useState([]);
  const [matchesData, setMatchesData] = useState(null);
  const [playoffData, setPlayoffData] = useState(null);
  const [leagueTeams, setLeagueTeams] = useState(null);
  const tournamentId = props.id.split("/")[0];

  useEffect(() => {
    trackPromise(loadTournament());
    if (parseInt(activeStep) === 1) {
      trackPromise(loadLeagueTeams());
    }
    if (parseInt(activeStep) === 2) {
      trackPromise(loadZones());
    }
    if (parseInt(activeStep) === 3) {
      trackPromise(loadFixture());
    }
    if (parseInt(activeStep) === 4) {
      trackPromise(loadPlayoff());
    }
  }, [activeStep]);

  const loadTournament = async () => {
    try {
      const res = await TournamentServices.getItem("tournament", tournamentId);
      const dataValues = {
        id: res.data.data[0].id,
        name: res.data.data[0].name,
        avatar: res.data.data[0].avatar,
        active: res.data.data[0].active,
        friendly: res.data.data[0].friendly,
        add_player: res.data.data[0].add_player,
        scoresystem: res.data.data[0].scoresystem,
        league: res.data.data[0].league.id,
        league_obj: res.data.data[0].league,
      };
      const jsonValues = JSON.parse(localStorage.getItem("values"));
      const localValues = jsonValues ? jsonValues.tournament : {};
      const newValues = {
        tournament: { ...dataValues, ...localValues },
      };
      const newValuesAlt = {
        ...jsonValues,
        ...{ tournament: dataValues },
      };

      setFormValues(newValuesAlt);

      setTournamentData(res.data);
    } catch (error) {
      if (error !== "Request canceled.") {
        console.log(error);
      }
    }
  };

  const loadLeagueTeams = async () => {
    try {
      const res = await TournamentServices.getTeams();
      const res1 = await TournamentServices.getTournamentTeams(tournamentId);
      const tt_ids = res1.data.teams.map((i) => i.id_team);
      setLeagueTeams({
        allTeams: res.data.data
          .filter((i) => !tt_ids.includes(i.id))
          .map((i) => ({ id: i.id, name: i.name, avatar: i.avatar })),
        addTeams: res1.data.teams.map((i) => ({
          id: i.id_team,
          id_tt: i.id_tt,
          name: i.name,
          avatar: i.avatar,
        })),
      });
      const data = {
        id_tournament: tournamentId,
        allTeams: res.data.data
          .filter((i) => !tt_ids.includes(i.id))
          .map((i) => ({ id: i.id, name: i.name, avatar: i.avatar })),
        addTeams: res1.data.teams.map((i) => ({
          id: i.id_team,
          id_tt: i.id_tt,
          name: i.name,
          avatar: i.avatar,
        })),
      };
      setTeamData(data);
      return data;
    } catch (error) {
      if (error !== "Request canceled.") {
        console.log(error);
      }
      return null;
    }
  };

  const loadZones = async () => {
    try {
      const resLeague = await loadLeagueTeams(true);

      const res = await TournamentServices.getPhasesByTournament(
        tournamentId,
        null,
        "phase"
      );
      const _phases = _.orderBy(res.data.phases, "id", "asc");
      const ids = _phases.map((i) => i.id);

      let newColumns = [];
      let zoneNames = { zone_name_1: "Grupo A" };
      let teamIds = [];
      let resTournament = null;

      _phases.forEach((i, k) => {
        zoneNames = { ...zoneNames, ["zone_name_" + (k + 1)]: i.name };
      });

      if (ids.length > 0) {
        resTournament = await TournamentServices.getTeamsByPhases(ids);

        resTournament.data.phases.forEach((i, k) => {
          newColumns = {
            ...newColumns,
            ["teams" + (k + 1)]: { ...i, alias: "Teams" + (k + 1) },
          };
          i.teams.forEach((j) => {
            teamIds.push(j.id);
          });
        });
      }

      newColumns = {
        ...newColumns,
        allTeams: {
          alias: "allTeams",
          teams: resLeague.addTeams.filter((i) => !teamIds.includes(i.id)),
        },
      };

      const data = {
        id_tournament: tournamentId,
        teams: resLeague.addTeams,
        values: {
          zones: {
            columns: newColumns,
            number_of_zones:
              resTournament !== null ? resTournament.data.phases.length : 1,
            ...zoneNames,
          },
        },
        phases_tournament: res.data,
      };
      setZoneData(data);
      return data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        addError("Edit Error: " + e.response.data.error_msg, null, {
          messageLength: null,
        });
      } else if (e.message) {
        addError("Edit Error: " + e.message, null, { messageLength: null });
      } else {
        if (e !== "Request canceled.") {
          console.log(e);
        }
      }
      return null;
    }
  };

  const loadFixture = async () => {
    try {
      const resZone = await loadZones();
      const res = await TournamentServices.getFixtureMatches(
        tournamentId,
        null,
        null,
        "phase"
      );

      const data = {
        ...resZone,
        values: { ...resZone.values, fixture: res.data.phase },
      };

      setMatchesData(data);

      return data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        addError("Edit error: " + e.response.data.error_msg, null, {
          messageLength: null,
        });
      } else if (e.message) {
        addError("Edit error: " + e.message, null, { messageLength: null });
      } else {
        if (e !== "Request canceled.") {
          console.log(e);
        }
      }
      return null;
    }
  };

  const loadPlayoff = async () => {
    try {
      const resTournamentTeams = await loadFixture();

      const res = await TournamentServices.getPhasesByTournament(
        tournamentId,
        null,
        null
      );
      const formvalues = {};
      let fixture = [];
      const playoffPhases = res.data.phases.filter((i) => i.key !== "phase");

      for (var k = 0; k < playoffPhases.length; k++) {
        const phase = playoffPhases[k];

        const resMatches = await TournamentServices.getFixtureMatches(
          tournamentId,
          null,
          null,
          phase.key
        );

        fixture[k] = {
          ...resMatches.data.fixture[0],
        };

        if (resMatches.data.fixture.length > 0) {
          resMatches.data.fixture.forEach((j) => {
            j.round.matches.forEach((h, kk) => {
              const label = `phase_${k + 1}_match_${kk + 1}`;
              Object.assign(formvalues, {
                [`${label}_footballfield`]: h.footballfield,
                [`${label}_date`]: moment(h.datetime).format(
                  get_language === "es" ? "DD/MM/YYYY" : "MM/DD/YYY"
                ),
                [`${label}_hour`]: moment(h.datetime).format("HH"),
                [`${label}_min`]: moment(h.datetime).format("mm"),
                [`${label}_team_1`]: h.team_1,
                [`${label}_team_2`]: h.team_2,
                [`${label}_idft`]: phase.id,
                [`${label}_tournament`]: tournamentId,
                [`${label}_id`]: h.id,
              });
            });
          });
        }
      }
      const data = {
        phases: playoffPhases,
        fieldValues: formvalues,
        fixture: fixture,
        ...resTournamentTeams,
      };
      setPlayoffData(data);
    } catch (error) {
      if (error !== "Request canceled.") {
        console.log(error);
      }
    }
  };

  const handleNext = (_values) => {
    let newValues = null;
    if (activeStep === 0) {
      newValues = { tournament: _values };
    } else if (activeStep === 1) {
      newValues = { teams: _values };
    } else if (activeStep === 2) {
      newValues = { zones: _values };
    } else if (activeStep === 3) {
      newValues = { matches: _values };
    } else if (activeStep === 4) {
      newValues = { fases: _values };
    }
    const ssv = JSON.parse(localStorage.getItem("values"));
    const savedValues = JSON.stringify({ ...ssv, ...newValues });
    localStorage.setItem("values", savedValues);
    setFormValues(savedValues);
    if (activeStep < 4) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      props.history.push(
        `/admin/tournament-steps/edit/${tournamentId}/step_${activeStep + 1}`
      );
    } else {
      localStorage.removeItem("values");
      props.history.push(`/admin/tournament-steps`);
    }
  };

  const handleBack = () => {
    props.history.replace(
      activeStep === 1
        ? `/admin/tournament-steps/edit/${tournamentId}`
        : `/admin/tournament-steps/edit/${tournamentId}/step_${activeStep - 1}`
    );
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStep = (step) => {
    props.history.replace(
      step === 0
        ? `/admin/tournament-steps/edit/${tournamentId}`
        : `/admin/tournament-steps/edit/${tournamentId}/step_${step}`
    );
    setActiveStep(step);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <TournamentForm
            onSave={handleNext}
            data={tournamentData}
            editForm={true}
          />
        );
      case 1:
        return (
          <TeamsForm
            onSave={handleNext}
            onCancel={handleBack}
            editForm={true}
            data={teamData}
          />
        );
      case 2:
        return (
          <ZonesFormEdit
            onSave={handleNext}
            onCancel={handleBack}
            editForm={true}
            data={zoneData}
          />
        );
      case 3:
        return (
          matchesData && (
            <MatchesFormEdit
              onSave={handleNext}
              onReload={loadFixture}
              onCancel={handleBack}
              editForm={true}
              data={matchesData}
            />
          )
        );
      case 4:
        return (
          playoffData && (
            <PhasesFormEdit
              onSave={handleNext}
              onCancel={handleBack}
              editForm={true}
              data={playoffData}
            />
          )
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <LoadingIndicator
        type="TailSpin"
        color="#2e82ef"
        height={50}
        width={50}
      />

      <GridContainer>
        <GridItem xs={12}>
          <Button
            id="btnBack"
            color="info"
            onClick={() => props.history.goBack()}
          >
            &lt; {translate.back}
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">{translate.edit}</CardIcon>
            </CardHeader>
            <CardBody>
              <Stepper alternativeLabel activeStep={activeStep} nonLinear>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton
                      color="inherit"
                      onClick={() => handleStep(index)}
                    >
                      <StepLabel>{label}</StepLabel>
                    </StepButton>
                  </Step>
                ))}
              </Stepper>

              <div>
                {activeStep < steps.length && (
                  <div>
                    {/* START Contenido */}
                    {getStepContent(activeStep)}
                    {/* END Contenido */}
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default withRouter(Edit);
