import React, { useEffect, useState } from "react";
import InputForm from "components/CustomForms/InputForm";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { strings as translate } from "../../locale";
import { useField as useFieldFormik, useFormikContext, getIn } from "formik";

const hoursOptions = Array.from(
  {
    length: 24,
  },
  (_, i) => i
).map((i) => (i < 10 ? { id: "0" + i, name: "0" + i } : { id: i, name: i }));

const minutesOptions = Array.from(
  {
    length: 60,
  },
  (_, i) => i
).map((i) => (i < 10 ? { id: "0" + i, name: "0" + i } : { id: i, name: i }));

const InputTime = ({ data, onChange, single }) => {
  const formikContext = useFormikContext();
  const [fieldHour, setFieldHour] = useState(null);
  const [fieldMin, setFieldMin] = useState(null);
  useEffect(() => {
    if (data !== null) {
      if (!single) {
        setFieldHour(
          `phase_${data.phaseNumber}${
            data.roundNumber ? "_round_" + data.roundNumber : ""
          }_match_${data.matchNumber}_hour`
        );
        setFieldMin(
          `phase_${data.phaseNumber}${
            data.roundNumber ? "_round_" + data.roundNumber : ""
          }_match_${data.matchNumber}_min`
        );
      } else if (single) {
        setFieldHour("hour");
        setFieldMin("min");
      }
    }
  }, [data]);

  const handleChange = (e, fieldname) => {
    const { value, name } = e.target;
    let h = fieldname === "hour" ? value : formikContext.values[fieldHour];
    let m = fieldname === "min" ? value : formikContext.values[fieldMin];
    if (h === undefined) {
      h = "00";
    }
    if (m === undefined) {
      m = "00";
    }
    const time = h + ":" + m;
    if (onChange) {
      onChange(time);
    }
  };
  return (
    <GridContainer>
      <GridItem xs={6}>
        <InputForm
          data={{
            accessor: fieldHour,
            header: translate.hour,
            editable: true,
            type: "select",
            options: hoursOptions,
          }}
          onChange={(e) => handleChange(e, "hour")}
          required={true}
          editForm={false}
        />
      </GridItem>
      <GridItem xs={6}>
        <InputForm
          data={{
            accessor: fieldMin,
            header: translate.minutes_abbr,
            editable: true,
            type: "select",
            options: minutesOptions,
          }}
          onChange={(e) => handleChange(e, "min")}
          required={true}
          editForm={false}
        />
      </GridItem>
    </GridContainer>
  );
};

export default InputTime;
