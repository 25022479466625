import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { strings as translate, get_language } from "../../locale";

import _ from "lodash";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import Button from "../../components/CustomButtons/Button";
import useMainNotification from "../../hooks/useMainNotification";

import { Formik, setIn } from "formik";
import InputForm from "components/CustomForms/InputForm";
import moment from "moment";
import { getHoursValue } from "helpers/TounamentUtils";

// services
import MainServices from "../../services/MainServices";
import TournamentServices from "../../services/TournamentServices";

// contexts
import * as yup from "yup";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

function PhasesForm(props) {
  const { onSave, onSaveError, onCancel, editForm } = props;
  const classes = useStyles();
  const [data, setData] = useState(() => []);
  const [loaded, setLoaded] = useState(false);
  const [requiredFields, setRequiredFields] = useState(false);
  const [hasThird, setHasThird] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState({});
  const [dataJson, setDataJson] = useState([]);
  const { addSuccess, addError } = useMainNotification();
  const [tournamentId, setTournamentId] = useState(null);
  const [teams, setTeams] = useState([{ id: "", name: "Selecciona equipo" }]);

  const [phaseKey, setPhaseKey] = useState(null);
  const [currentPhases, setCurrentPhases] = useState(null);
  const [originPhases, setOriginPhases] = useState(null);
  const [thirdPhase, setThirdPhase] = useState(null);
  const [remainingPhases, setRemainingPhases] = useState(null);

  const phasesOptions = [
    { key: "subleague", name: translate.subleague },
    { key: "32", name: translate.round_of_64 },
    { key: "16", name: translate.round_of_32 },
    { key: "8", name: translate.round_of_16 },
    { key: "4", name: translate.quarter_finals },
    { key: "2", name: translate.semifinals },
    { key: "1", name: translate.final },
  ];

  useEffect(() => {
    setLoaded(true);
    if (!editForm) {
      setData({
        metadata: [
          {
            accessor: "key",
            header: "Tipo de fase",
            editable: false,
            type: "select",
          },
        ],
      });
    }
    const values = JSON.parse(localStorage.getItem("values"));
    if (values) {
      setTournamentId(values.tournament.id_tournament);
    }
  }, []);

  useEffect(() => {
    if (!data.metadata) return;
    console.log("data.metadata", data.metadata);
    const uniqueId = data.metadata.filter((item) => item.unique).pop();

    const idField = uniqueId ? uniqueId.accessor : "id";

    // filtarmos los campos utiles
    const fields = data.metadata.filter(
      (item) =>
        ((!editForm && item.accessor !== idField) || editForm) &&
        item.accessor !== "actions"
    );

    // establecemos los valores iniciales, para la edición hay que obtenerlos del data.data
    const getInitialValues = () => {
      const values = {};
      const fData =
        data && data[0]
          ? data[0]
          : data && data.data && data.data[0]
          ? data.data[0]
          : data;
      console.log("fields", fields);
      console.log("fData", fData);
      if (!editForm) {
        Object.assign(values, {
          key: "",
          third: false,
        });
      } else {
        fields.forEach((item) => {
          const selectValue =
            fData[item.accessor] && fData[item.accessor].id
              ? fData[item.accessor].id
              : fData[item.accessor];
          Object.assign(values, {
            [item.accessor]:
              item.type === "select" ? selectValue : fData[item.accessor],
          });
        });

        Object.assign(values, props.data.fieldValues);
      }
      //   console.log("values", values);
      return values;
    };

    setInitialValues(getInitialValues());

    // establecemos las validaciones
    setValidationSchema(
      yup.object().shape({
        key: yup.string().required("Required"),
      })
    );

    // buscamos campos requeridos
    const hasRequiredFields = () => {
      let required = false;
      fields.forEach((item) => {
        if (!item.validations) {
          return;
        }
        item.validations.forEach((v) => {
          if (v.type === "required") {
            required = true;
          }
        });
      });
      return required;
    };
    setRequiredFields(hasRequiredFields());
  }, [editForm, data]);

  const getPhases = (key) => {
    let phases = [];
    let initIndex = null;
    if (!editForm) {
      phasesOptions.forEach((i, k) => {
        if (parseInt(i.key) >= 0) {
          if (i.key === key) {
            initIndex = k;
          }
          if (initIndex !== null) {
            phases.push(i);
          }
        } else if (i.key === key) {
          phases.push(i);
        }
      });
    } else {
      currentPhases.forEach((i, k) => {
        console.log("currentPhases", i, k);
        if (parseInt(i.key) >= 0) {
          console.log("parseInt(i.key) >= 0", parseInt(i.key) >= 0);
          if (i.key === key) {
            console.log("i.key === key", i.key === key);
            initIndex = k;
          }
          if (initIndex !== null) {
            console.log("initIndex !== null", initIndex !== null);
            phases.push(i);
          }
        } else if (i.key === key) {
          console.log("i.key === key", i.key === key);
          phases.push(i);
        }
      });
    }
    return phases;
  };

  const handleFormSubmit = async (values, actions) => {
    try {
      let res = null;

      if (phaseKey !== "") {
        const data = {
          tournament_id: tournamentId,
          phases: getPhases(values.key, values.third),
          type: parseInt(values.key) >= 0 ? "playoff" : values.key,
          third:
            values.third === true
              ? { key: "third", name: translate.third_and_fourth_place }
              : null,
        };
        console.log("add", data);
        res = await TournamentServices.addPhasesByTournament(data);

        if (!res.error || !res.data.error) {
          addSuccess(
            !editForm
              ? `${translate.tournament} ${translate.has_been_added_successfully}`
              : `${translate.tournament} ${translate.has_been_edited_successfully}`
          );
        }
      }
      if (onSave) {
        onSave({ ...values, id_tournament: res.data.id_tournament });
      }
    } catch (e) {
      const data = e.response ? e.response.data : {};
      let errors = {};

      Object.keys(data).forEach((item) => {
        errors = setIn(errors, item, data[item][0]);
      });

      actions.setStatus(errors);

      handleSentError(e.response);
      if (e.response && e.response.data && e.response.data.error) {
        addError(e.response.data.error_msg, null, { messageLength: null });
      } else if (e.message) {
        addError(e.message, null, { messageLength: null });
      }
      MainServices.cancel();
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleSentError = (values) => {
    if (onSaveError) {
      onSaveError(values);
    }
  };

  const handleChangePhase = (e) => {
    setPhaseKey(e.target.value);
  };

  const handleChange = (
    phase_id,
    phase_key,
    phase,
    match,
    fieldname,
    value,
    round = 1
  ) => {
    let newValue = value;
    if (fieldname === "date") {
      newValue = typeof value === "object" ? value.format("YYYY-MM-DD") : value;
    }
    if (fieldname === "time") {
      newValue = typeof value === "object" ? value.format("HH:mm:ss") : value;
      if (!newValue.match(/\d{2}:\d{2}:\d{2}/)) {
        newValue = getHoursValue(newValue);
      }
    }

    let fixture = dataJson;
    const index = round - 1;

    const totalRounds = (teams.length + (teams.length % 2 ? 0 : -1)) * 2;
    const indexMatch = match - 1;
    const totalIndex = index + (phase - 1) * totalRounds;

    if (!fixture[totalIndex]) {
      fixture[totalIndex] = {};
    }
    if (!fixture[totalIndex].round) {
      fixture[totalIndex] = {
        ...fixture[totalIndex],
        ...{ round: { number: round, id_ft: phase_id, matches: [] } },
      };
    }
    if (!fixture[totalIndex].round.matches[indexMatch]) {
      fixture[totalIndex].round.matches[indexMatch] = {
        datetime: "",
        team_1: null,
        team_2: null,
        tournament: tournamentId,
      };
    }

    if (fieldname === "date" || fieldname === "time") {
      if (!fixture[totalIndex].round.matches[indexMatch].datetime) {
        newValue =
          fieldname === "date"
            ? newValue + " 00:00:00"
            : moment().format("YYYY-MM-DD ") + newValue;
        fixture[totalIndex].round.matches[indexMatch] = {
          ...fixture[totalIndex].round.matches[indexMatch],
          datetime: newValue,
        };
      } else {
        const datetime = fixture[totalIndex].round.matches[
          indexMatch
        ].datetime.split(" ");
        newValue =
          fieldname === "date"
            ? newValue + " " + datetime[1]
            : datetime[0] + " " + newValue;
        fixture[totalIndex].round.matches[indexMatch].datetime = newValue;
      }
    } else {
      if (!fixture[totalIndex].round.matches[indexMatch][fieldname]) {
        fixture[totalIndex].round.matches[indexMatch] = {
          ...fixture[totalIndex].round.matches[indexMatch],
          [fieldname]: newValue,
        };
      } else {
        fixture[totalIndex].round.matches[indexMatch][fieldname] = newValue;
      }
    }
    setDataJson(fixture);
  };

  const getPlayoffContent = (formik) => {
    if (phaseKey === null) {
      return null;
    }
    if (phaseKey === "none") {
      return (
        <div className={classes.playOffColumn}>
          <div className={classes.playOffColumnTitle}>
            {translate.without_playoffs}
          </div>
        </div>
      );
    }

    let newPhases = null;
    const phasesRounds = {
      subleague: {
        phases: [{ key: "subleague", name: translate.subleague }],
        matches: [],
      },
      32: { phases: [...phasesOptions.slice(1, 7)], matches: [] },
      16: { phases: [...phasesOptions.slice(2, 7)], matches: [] },
      8: { phases: [...phasesOptions.slice(3, 7)], matches: [] },
      4: { phases: [...phasesOptions.slice(4, 7)], matches: [] },
      2: { phases: [...phasesOptions.slice(5, 7)], matches: [] },
      1: { phases: [...phasesOptions.slice(6, 7)], matches: [] },
    };

    newPhases = phasesRounds[phaseKey];

    if (!editForm) {
      newPhases.phases.forEach((phase, k) => {
        const matches =
          phaseKey === "subleague"
            ? []
            : Array.from({ length: parseInt(phase.key) }, (_, i) => i + 1).map(
                (k) => ({
                  id: null,
                  datetime: null,
                  team_1: null,
                  team_2: null,
                  tournament: null,
                })
              );
        newPhases.matches.push(matches);
      });
    } else {
      newPhases.phases.forEach((phase, k) => {
        newPhases.phases[k] = currentPhases.filter(
          (i) => i.key === phase.key
        )[0];

        let fieldValues = {};
        const matches =
          phaseKey === "subleague"
            ? []
            : Array.from({ length: parseInt(phase.key) }, (_, i) => i + 1).map(
                (kk) => {
                  return {
                    id: null,
                    datetime: null,
                    team_1: null,
                    team_2: null,
                    tournament: null,
                    footballfield: "",
                  };
                }
              );
        console.log("fieldValues", fieldValues);
        newPhases.matches.push(matches);
      });
    }

    if (hasThird && parseInt(phaseKey) > 0) {
      if (!editForm) {
        newPhases.phases.push({
          key: "third",
          name: translate.third_and_fourth_place,
        });
        newPhases.matches.push([
          {
            id: null,
            datetime: null,
            team_1: null,
            team_2: null,
            tournament: null,
          },
        ]);
      } else {
        newPhases.phases.push(thirdPhase);
        newPhases.matches.push([
          {
            id: null,
            datetime: null,
            team_1: null,
            team_2: null,
            tournament: null,
          },
        ]);
      }
    }

    return newPhases.phases.map((phase, k) => (
      <div key={k} className={classes.playOffColumn}>
        <div className={classes.playOffColumnTitle}>{phase.name}</div>
        <div className={classes.playOffColumnMatches}>
          {newPhases.matches[k].map((match, kk) => (
            <GridContainer key={kk}>
              <GridItem xs={2}>
                <InputForm
                  data={{
                    accessor: `phase_${k + 1}_match_${kk + 1}_footballfield`,
                    header: translate.football_field_number,
                    editable: true,
                    type: "number",
                  }}
                  onChange={(e) => {
                    handleChange(
                      phase.id,
                      phase.key,
                      k + 1,
                      kk + 1,
                      "footballfield",
                      e.target.value
                    );
                  }}
                  inputProps={{
                    min: 1,
                  }}
                  required={true}
                  editForm={editForm}
                />
              </GridItem>
              <GridItem xs={2}>
                <InputForm
                  data={{
                    accessor: `phase_${k + 1}_match_${kk + 1}_date`,
                    header: translate.date,
                    editable: true,
                    type: "date",
                  }}
                  onChange={(e) => {
                    handleChange(phase.id, phase.key, k + 1, kk + 1, "date", e);
                  }}
                  required={true}
                  editForm={false}
                />
              </GridItem>
              <GridItem xs={2}>
                <InputForm
                  data={{
                    accessor: `phase_${k + 1}_match_${kk + 1}_time`,
                    header: "Hora",
                    editable: true,
                    type: "time",
                  }}
                  onChange={(e) => {
                    handleChange(phase.id, phase.key, k + 1, kk + 1, "time", e);
                  }}
                  required={true}
                  editForm={false}
                />
              </GridItem>
              <GridItem xs={3}>
                <InputForm
                  data={{
                    accessor: `phase_${k + 1}_match_${kk + 1}_team_1`,
                    header: translate.team + " 1",
                    editable: true,
                    type: "select",
                    options: teams,
                  }}
                  onChange={(e) => {
                    handleChange(
                      phase.id,
                      phase.key,
                      k + 1,
                      kk + 1,
                      "team_1",
                      e.target.value
                    );
                  }}
                  required={true}
                  editForm={editForm}
                />
              </GridItem>
              <GridItem xs={3}>
                <InputForm
                  data={{
                    accessor: `phase_${k + 1}_match_${kk + 1}_team_2`,
                    header: translate.team + " 2",
                    editable: true,
                    type: "select",
                    options: teams,
                  }}
                  onChange={(e) => {
                    handleChange(
                      phase.id,
                      phase.key,
                      k + 1,
                      kk + 1,
                      "team_2",
                      e.target.value
                    );
                  }}
                  required={true}
                  editForm={editForm}
                />
              </GridItem>
            </GridContainer>
          ))}
        </div>
      </div>
    ));
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      <LoadingIndicator
        type="TailSpin"
        color="#2e82ef"
        height={50}
        width={50}
      />

      <div className={classes.root}>
        {loaded && (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              trackPromise(handleFormSubmit(values, actions), "save-button");
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <GridContainer alignItems="stretch">
                  <GridItem xs={3}>
                    <InputForm
                      data={{
                        accessor: "key",
                        header: "Tipo de fase",
                        editable: true,
                        type: "select",
                        options: [
                          { id: "none", name: "Sin fase definitoria" },
                          ...phasesOptions.map((i) => ({
                            id: i.key,
                            name: i.name,
                          })),
                        ],
                      }}
                      onChange={handleChangePhase}
                      editForm={editForm}
                    />
                    {parseInt(phaseKey) > 0 && (
                      <InputForm
                        data={{
                          accessor: "third",
                          header: translate.third_and_fourth_place,
                          editable: true,
                          type: "checkbox",
                        }}
                        onChange={(e) => {
                          console.log("checkbos", e.target.checked);
                          setHasThird(e.target.checked);
                        }}
                        editForm={editForm}
                      />
                    )}
                  </GridItem>
                  <GridItem xs={12}>
                    <div className={classes.playOffContainer}>
                      {getPlayoffContent(formik)}
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  {requiredFields && (
                    <GridItem xs={12}>
                      <div className={classes.formCategory}>
                        <small>*</small> {translate.required_fields}
                      </div>
                    </GridItem>
                  )}
                  <GridItem xs={12} container justify="center">
                    <div className={classes.formButtonWithLoader}>
                      <LoadingIndicator
                        type="TailSpin"
                        color="#2e82ef"
                        height={30}
                        width={30}
                        area="save-button"
                      />
                      {editForm && (
                        <Button id ="btnBack" type="submit" onClick={handleCancel}>
                          {translate.back}
                        </Button>
                      )}
                      {phaseKey !== "none" &&
                        phaseKey !== "" &&
                        phaseKey !== null && (
                          <Button
                            id = "btnFinish"
                            type="submit"
                            color="primary"
                            disabled={formik.isSubmitting}
                          >
                            {translate.finish}
                          </Button>
                        )}

                      {(phaseKey === "none" ||
                        phaseKey === "" ||
                        phaseKey === null) && (
                        <Button
                          id = "btnFinish"
                          type="submit"
                          color="primary"
                          onClick={(e) => {
                            if (onSave) {
                              onSave({
                                phases: [],
                                id_tournament: tournamentId,
                              });
                            }
                          }}
                        >
                          {translate.finish}
                        </Button>
                      )}
                    </div>
                  </GridItem>
                </GridContainer>
              </form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
}

export default withRouter(PhasesForm);
