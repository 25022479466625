import React, { useState, useEffect } from "react";

import { strings as translate, get_language } from "../../locale";

import { makeStyles } from "@material-ui/core/styles";
import LoadingIndicator from "components/Loader/LoadingIndicator";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import useMainNotification from "hooks/useMainNotification";
import { trackPromise } from "react-promise-tracker";
import { withRouter } from "react-router-dom";
import MainServices from "services/MainServices";

// styles
import styles from "./styles";

import CustomTable from "components/CustomTable/CustomTable";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import MatchForm from "./MatchForm";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import TournamentServices from "services/TournamentServices";

const useStyles = makeStyles(styles);

const MatchesForm = (props) => {
  const { onSave, onSaveError, onCancel, editForm } = props;
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [matchData, setMatchData] = useState({
    idft: "",
    round: "",
    tournament: props.data.id_tournament,
    footballfield: "",
    date: "",
    hour: "",
    min: "",
    team_1: "",
    team_2: "",
    interzone: false,
  });
  const [confirmAlert, setConfirmAlert] = useState(false);
  const { addSuccess, addError } = useMainNotification();
  const [matchEditForm, setMatchEditForm] = useState(false);

  useEffect(() => {
    if (props.data !== undefined && props.data.id_tournament !== undefined) {
      // trackPromise(buildData());
      buildData();
    }
  }, [props]);

  const buildData = async () => {
    await new Promise((resolve, reject) => {
      const newData = [];
      props.data.values.fixture.forEach((f) => {
        const round = {
          id_ft: f.round.id_ft,
          name_phase: f.round.name_phase,
          number: f.round.number,
          type_phase: f.round.type_phase,
        };
        f.round.matches.forEach((m) => {
          newData.push({
            ...m,
            ...round,
            team1_name: m.team1.name,
            team2_name: m.team2.name,
            playered: m.playered === true ? "Si" : "No",
            actions: ["click_action", "click_action_1"],
            all_name_phase:
              m.team1.phase_name === m.team2.phase_name
                ? m.team1.phase_name
                : m.team1.phase_name + " /" + m.team2.phase_name,
            interzone_label: m.interzone === true ? "Si" : "No",
          });
        });
      });
      setTableData(newData);
      setLoaded(true);
    });
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleNext = () => {
    if (onSave) {
      onSave();
    }
  };

  const handleSave = () => {
    setOpenDialog(false);
    if (props.onReload) {
      props.onReload();
    }
  };

  const editMatch = (data) => {
    setMatchEditForm(true);
    setMatchData({
      id: data.id,
      idft: data.id_ft,
      round: data.number,
      tournament: data.tournament,
      footballfield: data.footballfield,
      date: moment(data.datetime).format(
        get_language === "es" ? "DD/MM/YYYY" : "MM/DD/YYYY"
      ),
      hour: moment(data.datetime).format("HH"),
      min: moment(data.datetime).format("mm"),
      team_1: data.team_1,
      team_2: data.team_2,
      idft_team_1: data.team1.idft_team_1,
      idft_team_2: data.team2.idft_team_2,
      interzone: data.interzone,
    });
    setOpenDialog(true);
  };

  const deleteMatch = (data, global_action) => {
    if (data.playered !== "No") {
      setOpenAlert(true);
    } else {
      setConfirmAlert(data.id);
    }
  };

  const handleOnConfirm = async (id) => {
    hideConfirmAlert();
    try {
      const res = TournamentServices.deleteFixtureMatches({ fixture: [id] });

      if (!res.error || !res.data.error) {
        addSuccess(
          `${translate.the_match} ${translate.has_been_deleted_successfully}`
        );
        if (props.onReload) {
          props.onReload();
        }
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.error) {
        addError(e.response.data.error_msg, null, { messageLength: null });
      } else if (e.message) {
        addError(e.message, null, { messageLength: null });
      }
      MainServices.cancel();
    }
  };

  const hideConfirmAlert = () => {
    setConfirmAlert(null);
  };

  const handleAddMatch = () => {
    setMatchEditForm(false);
    setOpenDialog(true);
    setMatchData({
      idft: "",
      round: "",
      tournament: props.data.id_tournament,
      footballfield: "",
      date: "",
      hour: "",
      min: "",
      team_1: "",
      team_2: "",
      interzone: false,
    });
  };

  return (
    <>
      <LoadingIndicator
        type="TailSpin"
        color="#2e82ef"
        height={50}
        width={50}
      />
      <div className={classes.root}>
        {loaded && (
          <>
            <GridContainer style={{ width: "100%" }}>
              <GridItem xs={12}>
                <Button
                  id="btnBack"
                  type="button"
                  onClick={handleAddMatch}
                  style={{ marginRight: "24px" }}
                >
                  {translate.add_match}
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ width: "100%" }}>
              <CustomTable
                data={{
                  actions: [
                    {
                      code: "click_action",
                      type: "row",
                      is_confirm: false,
                      clickAction: editMatch,
                      iconbutton: "Edit",
                      titlebutton: "Editar partido",
                      coloricon: "warning",
                    },
                    {
                      code: "click_action_1",
                      type: "row",
                      is_confirm: true,
                      clickAction: deleteMatch,
                      iconbutton: "Delete",
                      titlebutton: "Borrar partido",
                      coloricon: "danger",
                    },
                  ],
                  data: tableData,
                  metadata: [
                    {
                      accessor: "id",
                      header: "ID",
                      type: "number",
                      maxWidth: "60px",
                    },
                    {
                      accessor: "interzone_label",
                      header: "Interzonal",
                      type: "text",
                      maxWidth: "140px",
                    },
                    {
                      accessor: "all_name_phase",
                      header: "Grupo",
                      type: "text",
                      maxWidth: "140px",
                    },
                    {
                      accessor: "number",
                      header: "Fecha Num.",
                      type: "text",
                      maxWidth: "100px",
                    },
                    {
                      accessor: "footballfield",
                      header: "Cancha",
                      type: "text",
                      maxWidth: "100px",
                    },
                    {
                      accessor: "datetime",
                      header: "Fecha/Hora",
                      type: "datetime",
                      maxWidth: "160px",
                    },
                    {
                      accessor: "team1_name",
                      header: "Equipo 1",
                      type: "text",
                    },
                    {
                      accessor: "team2_name",
                      header: "Equipo 2",
                      type: "text",
                    },
                    {
                      accessor: "playered",
                      header: "Jugado",
                      type: "boolean",
                      maxWidth: "100px",
                    },
                  ],
                  config: {
                    button_add: true,
                  },
                  error: false,
                }}
                containerStyles={{ width: "100%" }}
                paginationBottom={true}
              />
            </GridContainer>

            <GridContainer style={{ marginTop: "60px" }}>
              <GridItem xs={12} container justify="center">
                <div className={classes.formButtonWithLoader}>
                  <LoadingIndicator
                    type="TailSpin"
                    color="#2e82ef"
                    height={30}
                    width={30}
                    area="save-button"
                  />
                  <Button
                    id="btnBack"
                    type="button"
                    onClick={handleCancel}
                    style={{ marginRight: "24px" }}
                  >
                    {translate.back}
                  </Button>
                  <Button
                    id="btnNext"
                    type="button"
                    color="primary"
                    onClick={handleNext}
                  >
                    {translate.next}
                  </Button>
                </div>
              </GridItem>
            </GridContainer>
          </>
        )}
      </div>

      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        onClose={() => setOpenDialog(false)}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          {matchData.id ? translate.edit_match : translate.add_match}
        </DialogTitle>
        <DialogContent>
          <MatchForm
            values={matchData}
            data={props.data}
            onSave={handleSave}
            onCancel={() => setOpenDialog(false)}
            editForm={matchEditForm}
          />
        </DialogContent>
      </Dialog>
      {confirmAlert && (
        <SweetAlert
          danger
          onConfirm={() => handleOnConfirm(confirmAlert)}
          confirmBtnCssClass={classes.button + " " + classes.danger}
          showCancel
          onCancel={hideConfirmAlert}
          cancelBtnCssClass={classes.button + " " + classes.success}
          customClass={classes.sweetAlertCustomClass}
          showCloseButton
          title={translate.confirm_delete_title}
        >
          {translate.formatString(translate.confirm_delete_text, confirmAlert)}
        </SweetAlert>
      )}
      <SweetAlert
        show={openAlert}
        danger
        onConfirm={() => setOpenAlert(false)}
        confirmBtnCssClass={classes.button + " " + classes.danger}
        customClass={classes.sweetAlertCustomClass}
        title={"Acción no permitida"}
      >
        No se puede borrar un partido jugado
      </SweetAlert>
    </>
  );
};

export default withRouter(MatchesForm);
