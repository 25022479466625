import React, { useState, useReducer, useEffect } from "react";
import { strings as translate } from "locale";
import { useParams, withRouter } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import Email from "@material-ui/icons/Email";
import PersonIcon from "@material-ui/icons/Person";
import FaceIcon from "@material-ui/icons/Face";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

// helpers
import emailValidator from "helpers/emailValidator.js";

// services
import AuthServices from "services/AuthServices";
import { Auth } from "aws-amplify";

import { UserContext } from "providers/UserProvider";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle.js";

import GridItem from "components/Grid/GridItem";

const useStyles = makeStyles(styles);

const SignUp = (props) => {
  const { onConfirmSignUp } = props;
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [inputValues, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { first_name: "", last_name: "", email: "", password: "" }
  );
  const [apiError, setApiError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailMessageError, setEmailMessageError] = useState("");
  const [showLinks, setShowLinks] = useState(false);
  const useUserContext = React.useContext(UserContext);
  const { hash } = useParams();

  const classes = useStyles();

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);

    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const setInput = (e) => {
    const { name, value } = e.target;
    setInputValues({ [name]: value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleConfirmSignUp = (user) => {
    if (onConfirmSignUp) {
      onConfirmSignUp(user);
    }
  };

  const handlerLogin = () => {
    props.history.push("/auth/login");
  };

  const register = async (e) => {
    e.preventDefault();

    setApiError("");
    setShowLinks(false);
    // handleConfirmSignUp({...inputValues})
    if (
      !inputValues.first_name.trim() ||
      !inputValues.last_name.trim() ||
      !inputValues.email.trim() ||
      !inputValues.password.trim()
    ) {
      setApiError(translate.all_fields_are_required);
      return;
    }

    const emailValid = emailValidator(inputValues.email.trim());
    if (emailValid !== true) {
      setEmailError(true);
      setEmailMessageError(emailValid);
      return;
    }
    try {
      const user = await Auth.signUp({
        username: inputValues.email,
        password: inputValues.password,
        attributes: {
          email: inputValues.email,
        },
      });
      console.log("user", user);
      if (user) {
        if (hash === undefined) {
          handleConfirmSignUp(user);
        } else {
          console.log(
            "registrar el usuario en amplify",
            inputValues.first_name,
            inputValues.last_name,
            inputValues.email,
            inputValues.confirmation_code,
            hash
          );
          AuthServices.register(inputValues, hash);
          handleConfirmSignUp(user);
        }
      } else {
        setApiError("Error de inicio de sesión");
      }

      // AuthServices.register(inputValues, hash)
    } catch (error) {
      console.log(error.message);
      if (error.response && error.response.data && error.response.data.error) {
        setApiError(error.response.data.msg_error);
      } else {
        if (error.message === "User already exists") {
          setShowLinks(true);
        }
        setApiError(translate[error.message]);
      }
    }
  };

  return (
    <>
      <form onSubmit={register}>
        <Card register className={classes[cardAnimaton]}>
          <CardHeader
            className={`${classes.cardHeader} ${classes.textCenter}`}
            color="primary"
          >
            <h4 className={classes.cardTitle}>{translate.register}</h4>
          </CardHeader>
          <CardBody>
            <CustomInput
              labelText={`${translate.first_name} *`}
              id="first_name"
              formControlProps={{
                fullWidth: true,
              }}
              value={inputValues.first_name}
              inputProps={{
                onChange: setInput,
                name: "first_name",
                autoComplete: "off",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <PersonIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              }
            />
            <CustomInput
              labelText={`${translate.last_name} *`}
              id="last_name"
              formControlProps={{
                fullWidth: true,
              }}
              value={inputValues.last_name}
              inputProps={{
                onChange: setInput,
                name: "last_name",
                autoComplete: "off",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <FaceIcon className={classes.inputAdornmentIcon} />
                </InputAdornment>
              }
            />
            <CustomInput
              labelText={`${translate.email} *`}
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              value={inputValues.email}
              inputProps={{
                onChange: setInput,
                name: "email",
                autoComplete: "off",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              }
              error={emailError}
              helperText={emailMessageError}
            />
            <CustomInput
              labelText={`${translate.password} *`}
              id="password"
              formControlProps={{
                fullWidth: true,
              }}
              value={inputValues.password}
              inputProps={{
                onChange: setInput,
                name: "password",
                type: showPassword ? "text" : "password",
                autoComplete: "off",
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {apiError && <FormHelperText error>{apiError}</FormHelperText>}
            {showLinks && (
              <div>
                {translate.did_you_confirm_your_account}
                <Button
                  onClick={() =>
                    handleConfirmSignUp({
                      user: { username: inputValues.email },
                    })
                  }
                >
                  {translate.register}
                </Button>
              </div>
            )}
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button color="primary" size="lg" block type="submit">
              {translate.register}
            </Button>
          </CardFooter>
        </Card>
      </form>

      <GridItem
        container
        xs={12}
        justify="center"
        alignItems="center"
        className={classes.initCardAnimation + " " + classes[cardAnimaton]}
      >
        {translate.have_an_account}
        <Button
          simple
          size="sm"
          type="button"
          color="info"
          onClick={handlerLogin}
        >
          {translate.login}
        </Button>
      </GridItem>
    </>
  );
};

export default withRouter(SignUp);
