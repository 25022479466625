import {
  defaultFont,
  dangerColor,
  cardTitle,
} from "../../assets/jss/material-dashboard-pro-react";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const styles = (theme) => ({
  ...sweetAlertStyle,
  sweetAlertCustomClass: {
    "& span.btn": {
      top: "1.25rem !important",
      right: "1.25rem !important",
      fontFamily: "Verdana !important",
      cursor: "pointer",
    },
  },
  ...defaultFont,
  root: {
    padding: theme.spacing(2),
  },
  cardTitle: {
    float: "left",
    padding: "10px 10px 10px 0px",
    lineHeight: "24px",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  textError: {
    color: dangerColor[0],
    textAlign: "center",
    padding: "30px 0",
    fontSize: "0.75rem",
    lineHeight: "1.66",
    fontWeight: "400",
  },
  droppableContainer: {
    padding: "3px 6px",
    minHeight: 100,
    borderRadius: "5px",
  },
  draggableTeamItem: {
    padding: 10,
    marginTop: 3,
    marginBottom: 3,
    display: "flex",
    flexDirection: "row",
    borderRadius: "3px",
    "&:hover": {
      backgroundColor: "#F6f6f6 !important",
    },
  },
  draggableTeamAvatar: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 32,
    width: 32,
    display: "inline-block",
  },
  draggableTeamName: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginLeft: "12px",
  },
  playOffContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  playOffColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    flex: "1 1 40%",
    minWidth: "400px",
    border: "1px solid #DDDDDD",
    marginBottom: "16px",
    padding: "8px",
  },
  playOffColumnTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: "24px",
    backgroundColor: "lightgray",
    padding: "10px",
  },
  playOffColumnMatches: {
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
  },
});

export default styles;
